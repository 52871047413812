import tokens from '@veneer/tokens'
import styled from 'styled-components'
const { gray9 } = tokens.color
const { size0, lineHeight1 } = tokens.typography
const { size2, size1, size6 } = tokens.layout
export const TextBox = styled.div`
  color: ${gray9};
  font-size: ${size0};
  line-height: ${lineHeight1};
  margin-top: ${size2};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: ${size1};
`

export const SubText = styled.p`
  margin-top: -${size2};
  margin-bottom: ${size6};
  color: ${gray9};
  font-size: ${size0};
  line-height: ${lineHeight1};
`
