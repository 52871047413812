import React from 'react'
import { IconInfo } from '@veneer/core/dist/scripts/icons'
import { PaymentIconInfo } from './styles'
import { PaymentMethodDetails } from '../../../../types'
import useGetText from '../../../../hooks/useGetText'
import { EDelinquencyNotification } from '@/types/pendingChanges'

interface ExpirationTextProps {
  creditCardDetails: PaymentMethodDetails
  expirationStatus: string
}

const ExpirationText = (props: ExpirationTextProps) => {
  const { creditCardDetails, expirationStatus } = props
  const getText = useGetText('billing')

  return expirationStatus === EDelinquencyNotification.ABOUT_TO_EXPIRE ? (
    <PaymentIconInfo expirationStatus={expirationStatus}>
      <IconInfo className="iconInfo" size={15} />
      {` ${getText('expiringSoon', { defaultValue: 'Expiring soon:' })} ${
        creditCardDetails?.expiration_date
      }`}
    </PaymentIconInfo>
  ) : expirationStatus === EDelinquencyNotification.EXPIRED ? (
    <PaymentIconInfo expirationStatus={expirationStatus}>
      <IconInfo className="iconInfo" size={15} />
      {` ${getText('expired', { defaultValue: 'Expired' })} `}
    </PaymentIconInfo>
  ) : (
    <>{`${getText('expires', { defaultValue: 'Expires:' })} ${
      creditCardDetails?.expiration_date
    }`}</>
  )
}

export default ExpirationText
