import { JarvisAuthProvider } from '@jarvis/web-http'
import axios, { AxiosInstance } from 'axios'
import sendOpenTelemetryEvent from '../../helpers/sendOpenTelemetryEvent'
import { CriticalScopes } from '../../types/TaxpayerDetails'

export default class CriticalScopesClient {
  httpClient: AxiosInstance
  baseURL: string
  mfeAuthProvider: JarvisAuthProvider

  constructor(authProvider: JarvisAuthProvider) {
    this.httpClient = axios
    this.mfeAuthProvider = authProvider
    this.baseURL = `${process.env.BILLING_BASE_URL_PROVIDER}/instantink/v1/commerce`
  }

  async getCriticalScopes(): Promise<CriticalScopes> {
    const stratusToken = await this.mfeAuthProvider.getAccessToken()
    const headers = {
      accept: 'application/json',
      authorization: `Bearer ${stratusToken}`
    }

    return await this.httpClient
      .get(`${this.baseURL}/critical_scopes`, {
        headers
      })
      .then(({ data }) => {
        return data
      })
      .catch((err) => {
        sendOpenTelemetryEvent(
          `Error while fetching  data from critical_scopes api : ` +
            JSON.stringify(err)
        )
        throw err
      })
  }
}
