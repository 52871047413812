import { ActionsType, Actions } from '../actions/Actions'
import ShippingAppState from '../types/ShippingAppState'

const getInitialState = (): ShippingAppState => ({
  assetsProvider: null,
  isFetching: false,
  addressItemData: null,
  allAddressItemData: null,
  selectedAddress: null,
  error: null,
  suggestedAddress: null,
  showSuggestAddressForm: false,
  showUnSupportedAddressForm: false
})

const appReducer = (
  state: ShippingAppState,
  action: Actions
): ShippingAppState => {
  switch (action.type) {
    case ActionsType.SET_ASSETS_PROVIDER: {
      return { ...state, assetsProvider: action.data }
    }
    case ActionsType.SET_RETRIEVE_ADDRESS_ITEM_SUCCESS: {
      return { ...state, addressItemData: action.data }
    }
    case ActionsType.SET_RETRIEVE_AllADDRESS_ITEMS_SUCCESS: {
      return { ...state, allAddressItemData: action.data }
    }
    case ActionsType.SET_IS_FETCHING_FLAG: {
      return { ...state, isFetching: action.data }
    }
    case ActionsType.SET_ERROR: {
      return { ...state, error: action.data }
    }
    case ActionsType.RESET_STATE: {
      return {
        ...state,
        isFetching: false,
        addressItemData: null,
        allAddressItemData: null,
        error: null
      }
    }
    case ActionsType.SET_SELECTED_ADDRESS: {
      return { ...state, selectedAddress: action.data }
    }
    case ActionsType.SET_SUGGESTED_ADDRESS: {
      return { ...state, suggestedAddress: action.data }
    }
    case ActionsType.SET_SHOW_SUGGEST_ADDRESS_FORM: {
      return { ...state, showSuggestAddressForm: action.data }
    }
    case ActionsType.SET_SHOW_UNSUPPORTED_ADDRESS_FORM: {
      return { ...state, showUnSupportedAddressForm: action.data }
    }
    default:
      return state
  }
}

export const initialState = getInitialState()

export default appReducer
