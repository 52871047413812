import React from 'react'
import HPAIPBilling from './HPAIPBilling'
import InstantInkBilling from './InstantInkBilling'
import { mblEntityType } from '../../types'
import { BillingContainer, SubTitle, Title } from './styles'
import useGetText from '../../hooks/useGetText'

interface BillingProps {
  commonProps: any
}

const Billing = (props: BillingProps) => {
  const { commonProps } = props
  const hpOneSubscriptionInfos = commonProps?.hpaip?.hpOneSubscriptionInfo
  const getText = useGetText('billing')

  const hpOneEntities = [
    mblEntityType.pc,
    mblEntityType.printer,
    mblEntityType.chromebook
  ]
  const hpOneCancellationStates = ['inactive']

  const isCancelling = (entry) => {
    return entry.entities?.every(
      (entity) =>
        isHpOneEntity(entity?.entityType) &&
        hpOneCancellationStates.includes(entity?.state)
    )
  }

  const isHpOneEntity = (entityType: mblEntityType) =>
    hpOneEntities.includes(entityType)
  const instantInkSubscriptionInfos =
    commonProps?.iInk?.iInkSubscriptionInfo?.instantInkSubscriptions

  const iInkIsFetching = commonProps?.iInk?.iInkIsFetching
  return (
    <>
      <Title>{getText('yourBilling', { defaultValue: 'Your billing' })}</Title>
      <SubTitle>
        {getText('paymentInfoOnFileMsg', {
          defaultValue:
            'View or edit your HP account billing details. Keep this information up to date to avoid service interruption.'
        })}
      </SubTitle>
      <BillingContainer>
        {commonProps?.isHPOneSubscriptionAvailable && (
          <>
            {hpOneSubscriptionInfos?.map((subscriptionInfo, index) => (
              <HPAIPBilling
                commonProps={props?.commonProps}
                isDisabled={isCancelling(subscriptionInfo)}
                subscriptionIndex={index}
                key={index}
              />
            ))}
          </>
        )}
        {!iInkIsFetching && instantInkSubscriptionInfos?.length > 0 && (
          <InstantInkBilling commonProps={props?.commonProps} />
        )}
      </BillingContainer>
    </>
  )
}

export default Billing
