import useAppContext from '@/hooks/useAppContext'

const useGetError = () => {
  const {
    state: { error: error }
  } = useAppContext()
  return error
}

export default useGetError
