export interface LinkedEntities {
  type?: string
  value?: string
}

export interface EntityCommerce {
  type?: string
  value?: {
    subscriptionId?: string
    friendlySubscriptionId?: string
    purchaseResourceId?: string
  }
}

export interface EntitySource {
  type?: string
  value?: {
    orderId?: string
    itemId?: string
    parentItemId?: string
  }
}

export interface EntityProduct {
  type?: string
  value?: {
    productSku?: string
    productType?: string
    parentProductSku?: string
  }
}

export interface EntityFulfillment {
  type?: string
  value?: {
    fulfillmentOrderId?: string
    orderNumber?: string
    shippedDate?: string
  }
}

export interface EntityDetails {
  accessoryId?: string | null
  pendingAssociationId?: string
  pendingAssociationState?: string
  serialNumber?: string
  onboardingSerialNumber?: string | null
  uniqueDeviceId?: string
  ledgerResourceId?: string | null
}

export interface SubscriptionEntity {
  entityId?: string
  subscriptionId?: string
  userId?: string
  shippingAddressId?: string
  state?: string
  entityType?: mblEntityType
  entityStartDate?: string
  linkedEntities?: Array<LinkedEntities>
  commerce?: EntityCommerce
  product?: EntityProduct
  source?: EntitySource
  fulfillment?: EntityFulfillment
  entityDetails?: EntityDetails
  createdAt?: string
  updatedAt?: string
}

export enum mblEntityType {
  chromebook = 'chromebook',
  pc = 'iot-pc',
  printer = 'iot-printer',
  keyboard = 'keyboard',
  mouse = 'mouse',
  pen = 'pen',
  paper = 'paper',
  software = 'software',
  carepack = 'carepack'
}
