import useStoreFrontClient from '../useStoreFrontClient'
import useDataFetcher from '../useDataFetcher'
import useIsInstantInkEnabled from '../useIsInstantInkEnabled'

const useInstantInkSubscription = () => {
  const storeFrontClient = useStoreFrontClient()
  return useDataFetcher(
    'instantInkSubscriptionInfo',
    useIsInstantInkEnabled() && storeFrontClient?.getInstantInkSubscriptionData
      ? storeFrontClient.getInstantInkSubscriptionData.bind(storeFrontClient)
      : null
  )
}

export default useInstantInkSubscription
