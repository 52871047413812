import styled from 'styled-components'
import tokens from '@veneer/tokens'

export const StyledDescContainer = styled.p`
  -webkit-text-size-adjust: 100%;
  font-size: ${tokens.typography.size2};
  color: ${tokens.color.gray12};
  line-height: ${tokens.typography.lineHeight3};
  margin-bottom: 40px;
`

export const BillingItemsContainer = styled.div`
  && {
    display: flex;
    flex-wrap: wrap;
    gap: ${tokens.layout.size4};
  }
`

export const BillingSectionContainer = styled.div`
  margin: ${tokens.layout.size4} ${tokens.layout.size4};
  font-family: ${tokens.typography.family0};
  font-weight: 400;
`

export const StyledTitle = styled.p`
  && {
    color: #737373;
    font-size: ${tokens.typography.size5};
    line-height: ${tokens.typography.lineHeight5};
    margin-bottom: ${tokens.layout.size1};
  }
`
export const StyledTextContainer = styled.div`
  && {
    font-size: ${tokens.typography.size2};
    font-weight: 400;
    font-family: ${tokens.typography.family0};
    color: ${tokens.color.gray9};
    line-height: ${tokens.typography.lineHeight3};
    padding: 12px 0;
    letter-spacing: 0.02em;
  }
`

export const BillingCardWrapper = styled.div<{ isDisabled?: boolean }>`
  width: calc(50% - 16px);
  pointer-events: ${(props) => (props.isDisabled ? 'none' : 'auto')};
  cursor: ${(props) => (props.isDisabled ? 'none' : 'default')};
  opacity: ${(props) => (props.isDisabled ? 0.5 : 1)};
  && > div {
    border-radius: ${tokens.layout.cornerRadius4};
    padding: ${tokens.layout.size4};
  }

  @media only screen and (max-width: ${tokens.layout.smMax}) {
    width: 100%;
  }
`

export const CCImageContainer = styled.div`
  && {
    img {
      width: 40px;
      height: ${tokens.layout.size7};
    }
  }
`
export const CCInfoText = styled.div`
  && {
    font-size: ${tokens.typography.size1};
    line-height: ${tokens.typography.lineHeight2};
    color: ${tokens.color.gray12};
    margin-bottom: ${tokens.layout.size1};
  }
`

export const BillingCard = styled.div`
  background-color: ${tokens.color.white};
  && > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 240px;
    height: 260px;
  }
`
