import React, { FC, useEffect, useState } from 'react'
import {
  useBillingFormState,
  useBillingInfo,
  useGetText,
  usePayPalButton
} from '../../hooks'
import * as Styled from './styles'
import { PayPalLogo } from './payPalLogo'
import { Status } from '../../types'
import { PayPalConfirmationModal } from './payPalConfirmationModal'

export type PayPalButtonProps = {
  onButtonReady?: () => void
  onShowError?: () => void
}

type ExernalPayPalButtonProps = PayPalButtonProps & {
  onShowConfirmationModal?: () => void
}

const ExternalPayPalButton: FC<ExernalPayPalButtonProps> = ({
  onButtonReady,
  onShowError,
  onShowConfirmationModal
}) => {
  const getText = useGetText()
  const { pay_pal_url } = useBillingInfo()?.payPal || {}
  const { accountIdentifier, billingService } = useBillingFormState()
  const [initialized, setInitialized] = useState(false)
  const [token, setToken] = useState(undefined)

  useEffect(() => {
    if (!initialized) {
      setInitialized(true)
      billingService.getPayPalToken(accountIdentifier).then(
        (response) => {
          const {
            data: { token }
          } = response
          setToken(token)
          onButtonReady?.()
        },
        () => {
          onShowError?.()
        }
      )
    }
  }, [
    accountIdentifier,
    billingService,
    initialized,
    onButtonReady,
    onShowError
  ])

  if (token) {
    return (
      <>
        <Styled.PayPalButtonArea>
          <a
            data-testid="pay-pal-link"
            href={`${pay_pal_url}checkoutnow?token=${token}`}
            target="_blank"
            rel="noreferrer"
            onClick={() => {
              onShowConfirmationModal?.()
            }}
          >
            <Styled.PayPalButton
              data-testid="pay-pal-button"
              aria-label="PayPal"
            >
              <PayPalLogo data-testid="pay-pal-button-icon" />
            </Styled.PayPalButton>
          </a>
          <Styled.PayPalTagline>
            {getText('billing_type_selector.pay_pal.pay_pal_button_tagline')}
          </Styled.PayPalTagline>
        </Styled.PayPalButtonArea>
      </>
    )
  }
  return null
}

const PayPalObjectsButton: FC<PayPalButtonProps> = ({
  onButtonReady,
  onShowError
}) => {
  const { accountIdentifier, billingService, onSave, enableAllPaymentLayout } =
    useBillingFormState()
  const { env, locale } = useBillingInfo()?.payPal || {}
  const { status: payPalButtonStatus, component: PayPalButton } =
    usePayPalButton()

  useEffect(() => {
    if (payPalButtonStatus === Status.ERROR) {
      onShowError?.()
    }
  }, [payPalButtonStatus, onShowError])

  useEffect(() => {
    if (PayPalButton) {
      onButtonReady?.()
    }
  }, [PayPalButton, onButtonReady])

  if (PayPalButton) {
    const onPayment = async () => {
      const {
        data: { token }
      } = await billingService.getPayPalToken(accountIdentifier)
      return token
    }
    const onAuthorize = async () => {
      await billingService.createPayPalAgreement(accountIdentifier)
      onSave?.()
    }
    const onError = () => onShowError?.()

    return (
      <Styled.PayPalButtonContainer
        $enableAllPaymentLayout={!!enableAllPaymentLayout}
      >
        <PayPalButton
          payment={onPayment}
          env={env || ''}
          commit={false}
          onAuthorize={onAuthorize}
          onError={onError}
          locale={locale || ''}
          style={{
            color: 'gold',
            shape: 'rect',
            label: 'paypal',
            height: enableAllPaymentLayout ? 48 : 40,
            tagline: !enableAllPaymentLayout,
            disableMaxWidth: true,
            size: 'responsive'
          }}
        />
      </Styled.PayPalButtonContainer>
    )
  }

  return null
}

export const PayPalButton: FC<
  PayPalButtonProps & { ['data-testid']: string }
> = (props) => {
  const { nativeApp, enablePaypalInNativeApp } = useBillingFormState()
  const [buttonReady, setButtonReady] = useState(false)
  const [payPalButtonKey, setPayPalButtonKey] = useState(0)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const externalPayPal = nativeApp && enablePaypalInNativeApp

  const onButtonReady = () => {
    setButtonReady(true)
    props.onButtonReady?.()
  }

  const onShowError = () => {
    if (buttonReady) {
      setPayPalButtonKey(payPalButtonKey + 1)
    }
    props.onShowError?.()
  }

  const content = externalPayPal ? (
    <>
      <ExternalPayPalButton
        key={payPalButtonKey}
        onButtonReady={onButtonReady}
        onShowError={onShowError}
        onShowConfirmationModal={() => setShowConfirmationModal(true)}
      />
      <PayPalConfirmationModal
        show={showConfirmationModal}
        closeModal={() => setShowConfirmationModal(false)}
        showError={onShowError}
      />
    </>
  ) : (
    <PayPalObjectsButton
      key={payPalButtonKey}
      onButtonReady={onButtonReady}
      onShowError={onShowError}
    />
  )
  return <div data-testid={props['data-testid']}>{content}</div>
}
