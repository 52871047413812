import React, { FC, useState, useRef, useEffect } from 'react'
import useGetText from '../../../../hooks/useGetText'
import Modal from '@veneer/core/dist/scripts/modal'

import { TaxIdForm } from '@jarvis/react-billing'
import getAuthProvider from '../../../../helpers/getAuthProvider'
import SecuritySessionExpiredModal from '../../../shared/SecuritySessionExpiredModal'
import { LoadingHandler } from '@monetization/hpaip-ui-shared-components'
import {
  StyledTitle,
  StyledNoTax,
  StyledDescContainer,
  StyledEditButtonContainer,
  ChevronRightIcon,
  StyledLine,
  Wrapper,
  StyledPadding
} from './styles'
import useTaxDataFormActions from './useTaxDataFormActions'

interface HandleTaxInfoProps {
  taxId: string
  nonProfitTaxId: string
  allowEditingTaxId: boolean
  forceRefresh: () => void
  editTaxId: boolean
  instantInkSubscriptionInfo: any
  country: string
  language: string
  shell: any
  commonProps: any
  loadingTaxData: boolean
}
const HandleTaxInfo: FC<HandleTaxInfoProps> = ({
  taxId,
  nonProfitTaxId,
  allowEditingTaxId,
  forceRefresh,
  editTaxId,
  instantInkSubscriptionInfo,
  country,
  language,
  shell,
  commonProps,
  loadingTaxData
}) => {
  const getTaxData = useGetText('taxData')
  const authProvider = getAuthProvider()
  const {
    criticalScopeError,
    criticalScopeLoading,
    sessionModal,
    handleSessionModal,
    setLocalNotificationList
  } = commonProps

  const handleTaxOrVat = () => (country === 'US' ? 'Tax' : 'Vat')
  const taxOrVat = handleTaxOrVat()

  const [customErr, setCustomErr] = useState(false)
  const [openTaxIdModal, setOpenTaxIdModal] = useState(false)
  const handleTaxModal = () => setOpenTaxIdModal(!openTaxIdModal)

  const { refreshNotification } = commonProps
  const initialRender = useRef(true)

  const { handleOnSave } = useTaxDataFormActions({
    getTaxData,
    refreshNotification,
    setCustomErr,
    handleTaxModal,
    forceRefresh,
    setLocalNotificationList,
    taxOrVat
  })

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false
      return
    }

    if (criticalScopeError && !criticalScopeLoading) {
      handleTaxModal()
    }
  }, [criticalScopeError, criticalScopeLoading])

  if (loadingTaxData) {
    return (
      <Wrapper>
        <LoadingHandler loading={loadingTaxData} />
      </Wrapper>
    )
  }

  return (
    <>
      <StyledLine />
      <StyledPadding>
        {taxId || nonProfitTaxId ? (
          <StyledTitle>
            {`${getTaxData('taxIdTitle', {
              taxOrVat,
              defaultValue: `${taxOrVat} ID: `
            })}${taxId ? taxId : nonProfitTaxId}`}
          </StyledTitle>
        ) : (
          <StyledNoTax isDisabled={taxId}>
            {getTaxData('noTaxId', {
              taxOrVat,
              defaultValue: `No ${taxOrVat} ID has been added (Optional)`
            })}
          </StyledNoTax>
        )}
        <StyledDescContainer>
          {getTaxData('description', {
            taxOrVat,
            defaultValue: `Your ${taxOrVat} ID will be shown on your monthly billing invoices.`
          })}
        </StyledDescContainer>
        <StyledEditButtonContainer
          data-testid="styled-edit-button-container"
          aria-disabled={
            !((allowEditingTaxId || (!taxId && !nonProfitTaxId)) && editTaxId)
          }
          isDisabled={
            (allowEditingTaxId || (!taxId && !nonProfitTaxId)) && editTaxId
          }
        >
          <a role="button" data-testid="tax-id-btn" onClick={handleTaxModal}>
            {taxId || nonProfitTaxId
              ? getTaxData('updateTaxId', {
                  taxOrVat,
                  defaultValue: `Update ${taxOrVat} ID`
                })
              : getTaxData('addTaxId', {
                  taxOrVat,
                  defaultValue: `Add ${taxOrVat} ID`
                })}
            <ChevronRightIcon
              isDisabled={
                (allowEditingTaxId || (!taxId && !nonProfitTaxId)) && editTaxId
              }
            />
          </a>
        </StyledEditButtonContainer>
        {(customErr || (criticalScopeError && !criticalScopeLoading)) &&
        instantInkSubscriptionInfo?.length > 0 ? (
          <SecuritySessionExpiredModal
            show={sessionModal}
            onClose={handleSessionModal}
          />
        ) : (
          openTaxIdModal && (
            <Modal
              align="start"
              closeButton
              id="modal-id"
              show={openTaxIdModal}
              data-testid="tax-id-modal"
              onClose={handleTaxModal}
              closeOnBlur={false}
            >
              <TaxIdForm
                country={country}
                language={language}
                authProvider={authProvider}
                stack={shell?.stack}
                onSave={(err: string) => handleOnSave(err)}
                onCancel={handleTaxModal}
              />
            </Modal>
          )
        )}
      </StyledPadding>
    </>
  )
}

export default HandleTaxInfo
