import React from 'react'
import Images from '@/assets/images'
import { StyledPayPalLogo, CCInfoText } from './styles'

const PayPal = ({ userName, billingInfo }) => {
  return (
    <>
      <StyledPayPalLogo>
        <img src={Images.paypalTextWithLogo} alt={`Pay Pal`} />
      </StyledPayPalLogo>
      <CCInfoText>{userName}</CCInfoText>
      <CCInfoText>{billingInfo?.payPal?.email}</CCInfoText>
    </>
  )
}

export default PayPal
