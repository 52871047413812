import styled from 'styled-components'
import tokens from '@veneer/tokens'
import Modal from '@veneer/core/dist/esm/scripts/modal'
import Button from '@veneer/core/dist/scripts/button'
const { color, layout, typography } = tokens

export const StyledModal = styled(Modal)`
  && {
    .vn-modal--footer {
      padding-top: 0;
      display: block;
    }

    .vn-modal__close > span > svg {
      color: ${color.gray12};
    }

    .vn-modal__close:focus {
      cursor: pointer;
      border: 2px solid ${color.hpBlue7};
      border-radius: 5px;
    }

    .vn-modal--content {
      opacity: 1;
      left: 0;
      padding: 0;
      .vn-modal__close > span > svg {
        color: #ffffff;
        background-color: ${color.gray6};
        opacity: 0.85;
        border-radius: 50%;
      }
      &.transitioning {
        animation: slide ${(props) => `${props.transition * 2}ms`} forwards;
        animation-timing-function: ease-in-out;

        @keyframes slide {
          0% {
            left: 0;
            opacity: 1;
          }
          49% {
            left: -100%;
            opacity: 0;
          }
          50% {
            left: 100%;
            opacity: 0;
          }
          100% {
            left: 0;
            opacity: 1;
          }
        }
      }

      min-width: 270px;
      @media (min-width: ${layout.lgMin}) {
        max-width: 648px;
        min-height: 628px;
      }
    }
  }
`

export const ModalContainer = styled.div`
  display: flex;
  width: max-content;
  flex-direction: column;
  border-radius: 0 0 16px 16px;
  align-items: flex-start;
  min-height: 628px;
  top: 496px;
  left: 258px;
  margin-bottom: 10px;
`

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: auto;
`

export const Header = styled.div`
  border-radius: 0 0 ${layout.cornerRadius4} ${layout.cornerRadius4};
  max-width: 648px;
  height: 200px;
  > img {
    border-radius: ${layout.cornerRadius4} ${layout.cornerRadius4} 0 0;
    width: 100%;
  }
`

export const ModalDescription = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 48px 80px 48px;
  height: 100%;
`

export const Title = styled.div`
  font-family: ${tokens.typography.family0};
  font-size: ${typography.size7};
  font-style: normal;
  font-weight: 400;
  line-height: ${typography.lineHeight7};
  margin-bottom: ${layout.size6};
`

export const Desc1 = styled.div`
  font-family: ${tokens.typography.family0};
  font-size: ${typography.size2};
  font-style: normal;
  font-weight: 400;
  line-height: ${typography.lineHeight3};
  margin-bottom: ${layout.size6};
  letter-spacing: 0.02em;
`

export const Desc2 = styled.p`
  font-family: ${tokens.typography.family0};
  font-size: ${typography.size3};
  font-weight: 700;
  line-height: ${typography.lineHeight3};
  margin-bottom: ${layout.size6};
`

export const ButtonBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: ${layout.size4};

  @media (min-width: ${layout.smMin}) {
    position: absolute;
    bottom: 40px;
    right: 48px;
  }

  @media (max-width: ${layout.smMin}) {
    flex-direction: column-reverse;
    margin-top: auto;
    > button {
      width: 100%;
      margin-top: ${layout.size4};
      white-space: initial;
    }
  }
`

export const StyledButton = styled(Button)`
  padding: 12px 16px;
  height: auto;
`

export const BackToAddress = styled.div`
  display: flex;
  min-width: 112px;
  height: 48px;
  padding: 14px ${layout.size6};
  justify-content: center;
  align-items: center;
  gap: ${layout.size2};
  border-color: ${color.hpBlue7};
  color: white;
  border-radius: ${layout.cornerRadius4};
  cursor: pointer;
`
