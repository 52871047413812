import { EDelinquencyNotification } from '@/types/pendingChanges'

const getCardBorderColor = (expirationStatus): string => {
  if (!expirationStatus) return ''
  switch (expirationStatus) {
    case EDelinquencyNotification.ABOUT_TO_EXPIRE:
      return '#FFA500'
    case EDelinquencyNotification.EXPIRED:
      return '#FF0000'
    case EDelinquencyNotification.OVERDUE:
      return '#FF8900'
    default:
      return ''
  }
}
export default getCardBorderColor
