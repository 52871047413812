import styled from 'styled-components'
import tokens from '@veneer/tokens'

export const ShippingContent = styled.div`
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  margin: auto;
`

export const StyledTitle = styled.div`
  && {
    font-family: ${tokens.typography.family0};
    color: #000000;
    font-size: ${tokens.typography.size7};
    line-height: 40px;
    font-weight: 600;
    padding: 24px 24px 0 0;
    margin: 12px 0 8px 0;
  }
`
