import { useFlags } from 'launchdarkly-react-client-sdk'
import useAccount from '../useAccount'

const useIsInstantInkEnabled = () => {
  const { loadInstantInkShippingBilling } = useFlags()

  const { info } = useAccount()

  return loadInstantInkShippingBilling?.countries?.find(
    (country: string) =>
      info.data?.countrySet.includes(country) || country === '*'
  )
}

export default useIsInstantInkEnabled
