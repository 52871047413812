import React from 'react'
import Images from '../../../assets/images'
import Button from '@veneer/core/dist/esm/scripts/button'
import useGetText from '../../../components/hooks/useGetText'
import {
  Banner,
  PaperBannerImage,
  BannerContent,
  PaperTitle,
  PaperDescription,
  IconText,
  NewTag
} from './styles'

interface PaperInfo {
  paperInformation: string
}

interface PaperProps {
  paper: PaperInfo
  handleButtonClick?: () => void
}

export const PaperDetails: React.FunctionComponent<PaperProps> = ({
  paper,
  handleButtonClick
}) => {
  const getText = useGetText('paper-info')

  return (
    <Banner>
      <PaperBannerImage>
        <NewTag>
          <span>{getText(`NewTag`)}</span>
        </NewTag>
      </PaperBannerImage>
      <BannerContent>
        <PaperTitle>{getText(`title`)}</PaperTitle>
        <PaperDescription>{getText(`description`)}</PaperDescription>
        <IconText>
          <img
            src={Images.PaperReamOpen}
            className="icons"
            alt="Paper Banner"
          />
          <p>{paper.paperInformation}</p>
        </IconText>
        <Button
          appearance="primary"
          colorScheme="default"
          size="default"
          onClick={handleButtonClick}
        >
          {getText(`button`)}
        </Button>
      </BannerContent>
    </Banner>
  )
}
