import SubscriptionItem from '@/components/SubscriptionItem'
import getAuthProvider from '@/helpers/getAuthProvider'
import getLocalization from '@/helpers/getLocalization'
import useGetText from '@/hooks/useGetText'
import useShellContext from '@/hooks/useShellContext'
import { SubscriptionType } from '@/types/SubscriptionType'
import getSubscriptionTitleTextId from '@/helpers/getSubscriptionTitleTextId'
import { BillingForm } from '@jarvis/react-paas-billing'
import Card from '@veneer/core/dist/scripts/card'
import React, { useState } from 'react'
import { ModalContentContainer, StyledEditButtonContainer } from './styles'
import HpOneCreditCard from '../HpOneCreditCard'
import { useAppContext } from '@/context/AppContext'
import useCookie from '@/hooks/useCookie/useCookies'
import {
  BillingModalWindowCancelBtnClicked,
  BillingModalWindowContinueBtnClicked,
  BillingModalWindowDisplayed,
  EditPaymentLinkClicked,
  publishEvent
} from '@/utils/analytics'
import getBorderStyle from '@/helpers/getBorderStyle'
import { setBillingForm, setInlineNotification } from '@/actions/Actions'
import usePaymentDetails from '@/hooks/usePaymentDetails/usePaymentDetails'
import { ErrorNotification } from '@/utils/ErrorNotification'
import { BillingCard, BillingCardWrapper } from '../styles'
import { StyledModal } from '../PaymentMethod/styles'

export type SubscriptionItemProps = {
  isDisabled?: boolean
}
const HpOnePaymentMethod = ({ isDisabled }: SubscriptionItemProps) => {
  const { state, dispatch } = useAppContext()
  const getText = useGetText('billing')
  const getTextSub = useGetText('subscriptions')
  const title = getTextSub(getSubscriptionTitleTextId(SubscriptionType.HP_ONE))
  const shell = useShellContext()
  const authProvider = getAuthProvider()
  const { subscriptionInfo, account } = useAppContext().state
  const { paymentMethodDetails, forceRefresh } = usePaymentDetails()
  const { language } = getLocalization()
  const isAgentSession = useCookie('stratus-session-id')
  const country = account
    ? account?.data?.regionId
    : process.env.DEFAULT_COUNTRY
  const currLanguage = language ? language : process.env.DEFAULT_LANGUAGE

  const [currentBillingFormModalStep, setCurrentBillingFormModalStep] =
    useState<number>(1)

  const subscriptionFriendlyIds = subscriptionInfo?.data?.map(
    (subscription) => subscription.friendlySubscriptionId
  )
  const subscriptionId = subscriptionInfo?.data?.map(
    (subscription) => subscription.subscriptionId
  )

  const editBillingClick = () => {
    dispatch(setBillingForm(!state.billingForm.isModalOpen))
    publishEvent(
      { ...EditPaymentLinkClicked, screenMode: 'HpOne' },
      { actionParams: { subscriptionId: subscriptionFriendlyIds.join('+') } }
    )
    publishEvent({
      ...BillingModalWindowDisplayed,
      screenName: `Billing1`,
      screenMode: 'HpOne'
    })
  }

  const handleOnCloseModal = () => {
    dispatch(setBillingForm(false))
    publishEvent({
      ...BillingModalWindowCancelBtnClicked,
      screenName: `Billing${currentBillingFormModalStep}`,
      screenMode: 'HpOne'
    })
    setCurrentBillingFormModalStep(1)
  }

  const handleOnUpdate = () => {
    publishEvent({
      ...BillingModalWindowContinueBtnClicked,
      screenName: `Billing1`,
      screenMode: 'HpOne'
    })
    publishEvent({
      ...BillingModalWindowDisplayed,
      screenName: `Billing2`,
      screenMode: 'HpOne'
    })
    setCurrentBillingFormModalStep(2)
  }

  const handleOnSave = (err: string) => {
    dispatch(setBillingForm(false))
    publishEvent({
      ...BillingModalWindowContinueBtnClicked,
      screenName: `Billing2`,
      screenMode: 'HpOne'
    })
    const notification = err
      ? ErrorNotification({ getText })
      : {
          display: true,
          refreshNotification: true,
          title: getText('billingSavedSuccess.hpAllInPlanTitle'),
          status: 'positive' as const
        }
    dispatch(setInlineNotification(notification))

    forceRefresh()
  }
  const deliquencyStatus = state.deliquencyNotificationStatus.deliquencyStatus

  const cardBoxBorder = getBorderStyle(deliquencyStatus)

  const paymentMethodSubscriptionContent = subscriptionInfo &&
    subscriptionFriendlyIds && (
      <SubscriptionItem
        subscriptionType={SubscriptionType.HP_ONE}
        subscriptionIds={subscriptionFriendlyIds}
        title={title}
        isDisabled={isDisabled}
      />
    )
  const hpOnePaymentMethodDetailsContent = (
    <BillingCard>
      {subscriptionInfo?.data?.map((subscription) => (
        <div key={subscription?.id}>
          {paymentMethodSubscriptionContent}
          <div>
            <HpOneCreditCard
              billingInfo={paymentMethodDetails}
              t={getText}
              expirationStatus={deliquencyStatus}
            />
            <StyledEditButtonContainer
              data-testid="editPaymentBillingButton"
              isDisabled={isDisabled}
              aria-disabled={isDisabled}
            >
              <a
                role="button"
                data-testid="editbillingButton"
                onClick={editBillingClick}
                id="manage-payment-method"
              >
                {getText('editPayment')}
              </a>
            </StyledEditButtonContainer>
          </div>
        </div>
      ))}
    </BillingCard>
  )

  const hpOnePaymentMethodTileContent = (
    <React.Fragment data-testid="hpOnePaymentMethodTileContent">
      {hpOnePaymentMethodDetailsContent}

      <StyledModal
        data-testid="modal"
        closeButton
        show={state.billingForm.isModalOpen}
        onClose={handleOnCloseModal}
        closeOnBlur={false}
      >
        {state.billingForm.isModalOpen ? (
          <ModalContentContainer>
            <BillingForm
              subscriptionId={subscriptionId[0]}
              accountIdentifier={'testingId'}
              country={country}
              language={currLanguage}
              authProvider={authProvider}
              onSave={(err: string) => handleOnSave(err)}
              onCancel={handleOnCloseModal}
              onUpdate={handleOnUpdate}
              stack={shell?.stack}
              virtualKeyboard={isAgentSession}
              nativeApp={shell?.isNative}
              mockStratus={false}
            />
          </ModalContentContainer>
        ) : (
          <></>
        )}
      </StyledModal>
    </React.Fragment>
  )

  return (
    <BillingCardWrapper isDisabled={isDisabled}>
      <Card
        content={hpOnePaymentMethodTileContent}
        className="card-box"
        customStyle={{ border: `${cardBoxBorder}` }}
      />
    </BillingCardWrapper>
  )
}

export default HpOnePaymentMethod
