import React from 'react'
import { StyledDeviceImage } from './styles'
import PrinterPlaceholderImage from '../../../assets/images/printer-placeholder.png'
import PcPlaceholderImage from '../../../assets/images/pc-placeholder.png'

interface IDeviceImageProps {
  deviceImage?: string
  modelName?: string
  serialNumber?: string
  type?: string
}

export function getPlaceholderImage(type: string): string {
  return type === 'iot-pc' ? PcPlaceholderImage : PrinterPlaceholderImage
}

export const DeviceImage: React.FunctionComponent<IDeviceImageProps> = ({
  deviceImage,
  modelName,
  serialNumber,
  type
}) => {
  if (!deviceImage) {
    return null
  }

  return (
    <StyledDeviceImage
      alt={`${modelName} ${serialNumber}`}
      src={deviceImage}
      onError={({ currentTarget }) => {
        currentTarget.onerror = null
        currentTarget.src = getPlaceholderImage(type)
      }}
    />
  )
}
