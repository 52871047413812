import React from 'react'
import { MfePropsType } from '@/types/mfeProps'
import BillingAndShipping from '../BillingAndShipping'
import ShippingBillingRoot from '../ShippingBillingRoot/ShippingBillingRoot'
import { useFlags } from 'launchdarkly-react-client-sdk'

const ShippingBillingParent = (props: MfePropsType) => {
  const { enableNewShippingandBillingFlow } = useFlags()
  const [localNotificationList, setLocalNotificationList] = React.useState([])

  const modifiedProps = {
    ...props,
    localNotificationList,
    setLocalNotificationList
  }
  return (
    <>
      {enableNewShippingandBillingFlow ? (
        <ShippingBillingRoot {...modifiedProps} />
      ) : (
        <BillingAndShipping />
      )}
    </>
  )
}

export default ShippingBillingParent
