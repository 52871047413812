import styled from 'styled-components'

export const StyledSuccessMessageInline = styled.div`
  display: flex;
  margin-top: 8px;

  > svg {
    /*
      We are not using the veneer token here because it will
      not work on the current veneer version used by vulcan
    */
    color: #00834b;
  }

  > span {
    margin-left: 8px;
    margin-block: 0;
  }
`
