import alipay from './alipay.svg'
import amex from './amex.svg'
import blank from './blank.png'
import billing from './billing.svg'
import citizens_pay from './citizens_pay.png'
import diners from './diners.svg'
import discover from './discover.svg'
import elo from './elo.svg'
import generic from './generic.svg'
import hiper from './hiper.svg'
import hipercard from './hipercard.svg'
import hp_all_in_plan from './hp_all_in_plan.svg'
import hp_all_in_plan_disabled from './hp_all_in_plan_disabled.svg'
import instant_ink from './instant_ink.png'
import instant_ink_single_line from './instant_ink_single_line.svg'
import jcb from './jcb.svg'
import maestro from './maestro.svg'
import masterCard from './master_card.svg'
import mir from './mir.svg'
import paypal from './paypal.svg'
import paypalTextWithLogo from './paypalTextWithLogo.png'
import shipping from './shipping.svg'
import shipping_gray from './shipping_gray.svg'
import unionpay from './unionpay.svg'
import visa from './visa.svg'
import sepa from './sepa.svg'
import gPay from './gPay.svg'
const Images = {
  alipay,
  amex,
  blank,
  billing,
  citizens_pay,
  diners,
  discover,
  elo,
  generic,
  hiper,
  hipercard,
  hp_all_in_plan,
  hp_all_in_plan_disabled,
  instant_ink,
  instant_ink_single_line,
  jcb,
  maestro,
  masterCard,
  mir,
  paypal,
  paypalTextWithLogo,
  shipping,
  shipping_gray,
  unionpay,
  visa,
  sepa,
  gPay
}

export default Images
