import { useEffect, useState } from 'react'
import getAuthProvider from '../../helpers/getAuthProvider'
import TaxDataClient from '../../services/TaxData'
import {
  AccountType,
  Action,
  TaxpayerDetails
} from '../../types/TaxpayerDetails'
import sendOpenTelemetryEvent from '../../helpers/sendOpenTelemetryEvent'

const useTaxId = () => {
  const authProvider = getAuthProvider()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(false)
  const [data, setData] = useState<TaxpayerDetails | null>(null)
  const [config, setConfig] = useState({
    show: false,
    action: Action.None,
    enabled: false
  })

  const fetchData = async () => {
    setIsLoading(true)
    const client = new TaxDataClient(authProvider)

    try {
      const taxData = await client.getTaxIdData()

      if (
        taxData?.companyName &&
        taxData?.accountType === AccountType.Business
      ) {
        setData(taxData)
      } else {
        setConfig({ show: false, action: Action.None, enabled: false })
        setData(null)
      }
    } catch (error) {
      sendOpenTelemetryEvent(
        'Error while fetching getTaxIdData: ' + JSON.stringify(error)
      )
      setError(true)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authProvider])

  useEffect(() => {
    if (data) {
      const hasTaxId = !!data.taxId
      const hasNonProfitTaxId = !!data.nonProfitTaxId
      const allowEditingTaxId = !!data.allowEditingTaxId

      let newConfig = { show: false, action: Action.None, enabled: false }

      if (hasTaxId && hasNonProfitTaxId) {
        newConfig = { show: false, action: Action.None, enabled: false }
      } else if ((hasTaxId || hasNonProfitTaxId) && !allowEditingTaxId) {
        newConfig = { show: true, action: Action.Update, enabled: false }
      } else if ((hasTaxId || hasNonProfitTaxId) && allowEditingTaxId) {
        newConfig = { show: true, action: Action.Update, enabled: true }
      } else if (!hasNonProfitTaxId) {
        newConfig = { show: true, action: Action.Add, enabled: true }
      }

      setConfig(newConfig)
    }
  }, [data])

  return {
    data,
    error,
    isLoading,
    config,
    forceRefresh: fetchData
  }
}

export default useTaxId
