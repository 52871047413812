export enum instantInkStates {
  SUBSCRIBED_NO_PENS = 'subscribed_no_pens',
  SUBSCRIBED_NO_PRINTER = 'subscribed_no_printer',
  SUBSCRIBED = 'subscribed',
  INITIATED_UNSUBSCRIBE = 'initiated_unsubscribe',
  UNSUBSCRIBED = 'unsubscribed',
  OBSOLETE = 'obsolete'
}
export const instantInkState = [
  instantInkStates.SUBSCRIBED_NO_PENS,
  instantInkStates.SUBSCRIBED_NO_PRINTER,
  instantInkStates.SUBSCRIBED,
  instantInkStates.INITIATED_UNSUBSCRIBE,
  instantInkStates.UNSUBSCRIBED
]
export const IISubsEntityState = {
  INACTIVE: 'inactive',
  DEACTIVATING: 'deactivating',
  PAUSED: 'paused',
  PENDING: 'pending',
  UPDATING: 'updating',
  ACTIVE: 'active',
  ERROR: 'error',
  SUSPENDED: 'suspended'
}

export enum EDelinquencyNotification {
  OVERDUE = 'payment_overdue',
  SUSPENDED = 'suspended',
  INACTIVE = 'no_longer_active',
  EXPIRED = 'expired',
  ABOUT_TO_EXPIRE = 'about_to_expire',
  NONE = 'none'
}
