import styled from 'styled-components'
import Modal from '@veneer/core/dist/scripts/modal/modal'

export const StyledModal = styled(Modal)`
  div.vn-modal--content {
    @media (min-width: 991px) {
      width: calc(100% - 13px - 13px);
      max-width: 900px;
    }
  }
  .vn-modal--footer {
    text-align: right;
  }
`
export const ModalContentContainer = styled.div`
  & .loading {
    width: auto;
    padding-top: 0;
    padding-bottom: 30px;
  }

  & .shipping-content .shipping-fields {
    width: auto;
  }
`
