const useTaxDataFormActions = ({
  getTaxData,
  setCustomErr,
  handleTaxModal,
  forceRefresh,
  refreshNotification,
  setLocalNotificationList,
  taxOrVat
}: any) => {
  const handleOnSave = (err: string) => {
    if (err === 'expired_critical_scope') {
      handleTaxModal()
      setCustomErr(true)
      return
    }
    forceRefresh()
    const successNotification = {
      title: getTaxData('onSaveNotification', {
        taxOrVat,
        defaultValue: `Your ${taxOrVat} ID was successfully saved`
      }),
      notificationType: 'positive',
      notificationID: 'IItaxDataSuccess'
    }

    const errorNotification = {
      title: getTaxData('failedApi', {
        defaultValue: 'Something went wrong. Try again later.'
      }),
      notificationType: 'warning',
      notificationID: 'IItaxDataError'
    }

    const notification = err ? errorNotification : successNotification
    handleTaxModal()
    setLocalNotificationList([])
    refreshNotification(notification)
  }

  return {
    handleOnSave
  }
}

export default useTaxDataFormActions
