import styled from 'styled-components'
import tokens from '@veneer/tokens'

const { color, layout, typography } = tokens

interface HeaderProps {
  isDisabled: boolean
}

export const ImageContainer = styled.div`
  margin-bottom: ${layout.size2};
`

export const Title = styled.p<HeaderProps>`
  font-size: ${typography.size0};
  color: ${(props) =>
    props.isDisabled ? color.highContrastGray : color.gray7};
  line-height: ${typography.size2};
  margin-bottom: ${layout.size1};
`

export const Subtitle = styled.p<HeaderProps>`
  font-size: ${typography.size0};
  color: ${(props) =>
    props.isDisabled ? color.highContrastGray : color.gray12};
  line-height: ${typography.size2};
`
