export const ErrorNotification = ({ getText }) => {
  return {
    display: true,
    refreshNotification: false,
    title: getText('failedApi', {
      defaultValue: 'Something went wrong. Try again later.'
    }),
    status: 'warning' as const
  }
}
