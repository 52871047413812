import React, { FC, useState, useRef, useEffect } from 'react'
import useGetText from '@/hooks/useGetText'
import Modal from '@veneer/core/dist/scripts/modal'
import { useAppContext } from '@/context/AppContext'
import getLocalization from '@/helpers/getLocalization'
import { setInlineNotification } from '@/actions/Actions'
import { TaxIdForm } from '@jarvis/react-billing'
import useShellContext from '@/hooks/useShellContext'
import getAuthProvider from '@/helpers/getAuthProvider'
import useCriticalScopes from '@/hooks/useCriticalScopes/useCriticalScopes'
import SecuritySessionExpiredModal from '@/components/SecuritySessionExpiredModal'

import {
  StyledTitle,
  StyledNoTax,
  StyledDescContainer,
  StyledEditButtonContainer,
  ChevronRightIcon,
  StyledLine
} from './styles'
import { ErrorNotification } from '@/utils/ErrorNotification'
import useInstantInkSubscriptionInfo from '@/hooks/useInstantInkSubscriptionInfo'

interface HandleTaxInfoProps {
  taxId: string
  nonProfitTaxId: string
  allowEditingTaxId: boolean
  forceRefresh: () => void
  editTaxId: boolean
}
const HandleTaxInfo: FC<HandleTaxInfoProps> = ({
  taxId,
  nonProfitTaxId,
  allowEditingTaxId,
  forceRefresh,
  editTaxId
}) => {
  const getText = useGetText('billing')
  const getTaxData = useGetText('taxData')
  const authProvider = getAuthProvider()
  const { language } = getLocalization()
  const { state, dispatch } = useAppContext()
  const shell = useShellContext()
  const country = state.account
    ? state.account.data.regionId
    : process.env.DEFAULT_COUNTRY
  const currLanguage = language || process.env.DEFAULT_LANGUAGE
  const handleTaxOrVat = () => (country === 'US' ? 'Tax' : 'Vat')
  const taxOrVat = handleTaxOrVat()
  const [openTaxIdModal, setOpenTaxIdModal] = useState<boolean>(false)
  const [customErr, setCustomErr] = useState(false)
  const handleTaxIdModal = () => {
    setOpenTaxIdModal(!openTaxIdModal)
  }
  const initialRender = useRef(true)
  const { error, isLoading } = useCriticalScopes()
  const {
    info: { data: instantInkSubscriptionInfo }
  } = useInstantInkSubscriptionInfo()

  const onSave = async (err: string) => {
    if (err === 'expired_critical_scope') {
      setOpenTaxIdModal(true)
      setCustomErr(true)
      return
    }
    handleTaxIdModal()
    const notification = err
      ? ErrorNotification({ getText })
      : {
          display: true,
          refreshNotification: true,
          title: getTaxData('onSaveNotification', {
            taxOrVat,
            defaultValue: `Your ${taxOrVat} ID was successfully saved`
          }),
          status: 'positive' as const
        }
    dispatch(setInlineNotification(notification))
    forceRefresh()
  }

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false
      return
    }

    if (error && !isLoading) {
      setOpenTaxIdModal(true)
    }
  }, [error, isLoading])

  return (
    <>
      <StyledLine />
      {taxId || nonProfitTaxId ? (
        <StyledTitle>
          {`${getTaxData('taxIdTitle', {
            taxOrVat,
            defaultValue: `${taxOrVat} ID: `
          })}${taxId ? taxId : nonProfitTaxId}`}
        </StyledTitle>
      ) : (
        <StyledNoTax isDisabled={taxId}>
          {getTaxData('noTaxId', {
            taxOrVat,
            defaultValue: `No ${taxOrVat} ID has been added (Optional)`
          })}
        </StyledNoTax>
      )}
      <StyledDescContainer>
        {getTaxData('description', {
          taxOrVat,
          defaultValue: `Your ${taxOrVat} ID will be shown on your monthly billing invoices.`
        })}
      </StyledDescContainer>
      <StyledEditButtonContainer
        data-testid="styled-edit-button-container"
        aria-disabled={
          !((allowEditingTaxId || (!taxId && !nonProfitTaxId)) && editTaxId)
        }
        isDisabled={
          (allowEditingTaxId || (!taxId && !nonProfitTaxId)) && editTaxId
        }
      >
        <a role="button" data-testid="tax-id-btn" onClick={handleTaxIdModal}>
          {taxId || nonProfitTaxId
            ? getTaxData('updateTaxId', {
                taxOrVat,
                defaultValue: `Update ${taxOrVat} ID`
              })
            : getTaxData('addTaxId', {
                taxOrVat,
                defaultValue: `Add ${taxOrVat} ID`
              })}
          <ChevronRightIcon
            isDisabled={
              (allowEditingTaxId || (!taxId && !nonProfitTaxId)) && editTaxId
            }
          />
        </a>
      </StyledEditButtonContainer>
      {(customErr || (error && !isLoading)) &&
      instantInkSubscriptionInfo?.instantInkSubscriptions?.length > 0 ? (
        <SecuritySessionExpiredModal
          show={openTaxIdModal}
          onClose={handleTaxIdModal}
        />
      ) : (
        openTaxIdModal && (
          <Modal
            align="start"
            closeButton
            id="modal-id"
            show={openTaxIdModal}
            data-testid="tax-id-modal"
            onClose={handleTaxIdModal}
          >
            <TaxIdForm
              country={country}
              language={currLanguage}
              authProvider={authProvider}
              stack={shell?.stack}
              onSave={(err: string) => onSave(err)}
              onCancel={handleTaxIdModal}
            />
          </Modal>
        )
      )}
    </>
  )
}

export default HandleTaxInfo
