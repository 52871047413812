import styled from 'styled-components'
import tokens from '@veneer/tokens'

export const StyledPayPalLogo = styled.div`
  margin-bottom: ${tokens.layout.size1};
  && {
    img {
      width: 80px;
      height: 20px;
    }
  }
`

export const CCInfoText = styled.div`
  && {
    font-size: ${tokens.typography.size1};
    line-height: ${tokens.typography.lineHeight2};
    color: ${tokens.color.gray12};
    margin-bottom: ${tokens.layout.size1};
  }
`
