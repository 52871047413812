import { createContext } from 'react'
import AppDispatch from '../types/AppDispatch'
import ShippingAppState from '../types/ShippingAppState'

const AppContext = createContext<{
  state?: ShippingAppState
  dispatch?: AppDispatch
}>(undefined)

export default AppContext
