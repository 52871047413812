export enum AddressErrorCode {
  INVALID_ADDRESS_CODE = 'SHP0026STF',
  UNAUTHORIZED_CODE = 'SHP0023ATZ',
  GENERIC_ERROR = 'genericError',
  NETWORK_ERROR = 'networkError',
  HTTP_STATUS_400 = 'SHP0026STF',
  HTTP_STATUS_401 = 'SHP0023ATZ'
}

export const HTTP_STATUS_PREFIX = 'HTTP_STATUS_'
