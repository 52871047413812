import Modal from '@veneer/core/dist/scripts/modal'
import styled from 'styled-components'
import tokens from '@veneer/tokens'

const { layout, color, typography } = tokens
export const StyledModal = styled(Modal)`
  && {
    .vn-modal__close > span > svg {
      color: ${color.gray12};
    }
    .vn-modal--content {
      padding: ${layout.size0};
      max-width: 750px;
    }
  }
  @media (max-width: ${layout.mdMax}) {
    .vn-modal--content {
      width: 75%;
    }
  }
  @media (max-width: ${layout.smMin}) {
    .vn-modal--content {
      width: 90%;
    }
  }
`
export const TCModelHeader = styled.div`
  display: flex;
  padding: ${layout.size8} 48px;
  flex-direction: column;
  align-items: flex-start;
  gap: ${layout.size0};
  align-self: stretch;
  border-radius: ${layout.size4} ${layout.size4} ${layout.size0} ${layout.size0};
  border-bottom: 1px solid ${color.gray12};
  background: #e6e6e6;
  @media (max-width: 576px) {
    padding: ${layout.size8} ${layout.size4};
    > img {
      width: 200px;
    }
  }
`
export const MainTitle = styled.div`
  color: ${color.gray12};
  font-family: 'Forma DJR UI';
  font-size: ${typography.size9};
  font-style: normal;
  font-weight: 400;
  margin-top: ${layout.size5};
  @media (max-width: 576px) {
    font-size: ${typography.size7};
  }
`
export const SubTitle = styled.div`
  color: ${color.gray12};
  font-family: 'Forma DJR UI';
  font-size: ${typography.size3};
  font-style: normal;
  font-weight: 400;
  line-height: ${typography.size5};
`
export const ModalBody = styled.div`
  padding: ${typography.size7} ${typography.size9} ${typography.size9}
    ${typography.size9};
  max-height: 400px;
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: var(--scroll-bar-color) var(--scroll-bar-bg-color);
  @media (max-width: 576px) {
    padding: ${layout.size8} ${layout.size4} ${layout.size0} ${layout.size4};
  }
`
export const Title = styled.p`
  color: var(--Light-Foreground-Light, #7d7d7d);
  font-family: 'Forma DJR UI';
  font-size: ${layout.size3};
  font-style: normal;
  font-weight: 400;
  line-height: ${layout.size4};
  margin-bottom: ${layout.size2};
`
