import Button from '@veneer/core/dist/scripts/button'
import React from 'react'
import { Container } from './styles'
import useGetText from '../../hooks/useGetText'

export default function ErrorCard(props) {
  const { hpOneSubtitle, refetchData } = props
  const getText = useGetText()
  const reloadPage = () => {
    if (refetchData) {
      refetchData()
    } else window.location.reload()
  }

  return (
    <Container data-testid="error-card">
      <p className="title">{getText('errorScreen.title')}</p>
      <p className="subtitle">{hpOneSubtitle}</p>
      <Button
        className="reload-button"
        appearance="secondary"
        onClick={reloadPage}
        data-testid="reload-button"
      >
        {getText('errorScreen.action')}
      </Button>
    </Container>
  )
}
