/* eslint-disable react/display-name */
import React, { useEffect } from 'react'
import {
  setDeliquencyNotification,
  setIIBillingForm,
  setInlineNotification
} from '@/actions/Actions'
import { useIICommonNotification } from '@monetization/hpaip-notification-rules-react'
import { useAppContext } from '@/context/AppContext'
import { defaultInlineNotification } from '@/context/appReducer'
import { MultipleNotification } from '@monetization/hpaip-ui-shared-components'
export interface NotificationPropData {
  updateCommonNotification?: () => void
}
export default function CommonIINotification({
  updateCommonNotification
}: Readonly<NotificationPropData>) {
  const { state, dispatch } = useAppContext()
  const { inlineNotification } = state

  const handleIIBillingForm = () => dispatch(setIIBillingForm(true))
  const handleIIInlineNotification = () =>
    dispatch(setInlineNotification(defaultInlineNotification))

  const commonIINotification = useIICommonNotification(
    'ShippingBilling',
    handleIIBillingForm
  )

  const notificationIIList = commonIINotification?.notificationsList
  if (inlineNotification && inlineNotification?.refreshNotification) {
    updateCommonNotification()
  } else if (
    inlineNotification &&
    !inlineNotification?.refreshNotification &&
    inlineNotification?.display
  ) {
    notificationIIList.push({
      title: inlineNotification.title,
      description: inlineNotification.description,
      notificationID: 'inlineNotification',
      callbackFunction: handleIIInlineNotification,
      showCloseButton: true,
      notificationType: inlineNotification.status
    })
  }

  useEffect(() => {
    const notificationData = {
      display: true,
      deliquencyStatus: commonIINotification?.deliquencyStatus,
      instantInkDeliquencyStatus:
        commonIINotification?.instantInkDeliquencyStatus
    }
    dispatch(setDeliquencyNotification(notificationData))
  }, [
    commonIINotification.deliquencyStatus,
    commonIINotification.instantInkDeliquencyStatus,
    dispatch
  ])
  return commonIINotification && notificationIIList.length > 0 ? (
    <MultipleNotification multiNotificationArray={notificationIIList} />
  ) : null
}
