import React from 'react'
import '@veneer/styles/typography/typography.css'
import { BaseURLProvider, Stack } from '@jarvis/web-stratus-client'
import { JarvisAuthProvider } from '@jarvis/web-http'
import { TaxIdFormContainer } from './taxIdFormContainer'
import { ErrorType } from '../../types'
import { GlobalStyle } from '../../styles/styles'
import { BillingFormProvider } from '../BillingForm/billingFormProvider'

export type TaxIdFormProps = {
  /** Country */
  country: string

  /** Language */
  language: string

  /**
   * authentication provider object that must include the getAccessToken function
   */
  authProvider: JarvisAuthProvider

  /**
   * enum of (dev,pie,stage,prod) used for stratus web calls
   */
  stack: Stack

  /**
   * function to get the API base URLs
   *
   * - apiName and apiVersion can be used to query Services Discovery API
   */
  baseURLProvider?: BaseURLProvider

  /**
   * xCorrelationId used for logging
   */
  xCorrelationId?: string

  /**
   * mock flag, if set to true then all Billing API calls will be mocked
   */
  mockStratus?: boolean

  /**
   * function called when Tax ID data is saved, if an error parameter is present then the save failed.
   *
   * Possible error values:
   *
   * - expired\_critical\_scope
   * - expired\_token
   */
  onSave?: (error?: ErrorType) => void

  /**
   * function called when save button is clicked. Used for SMB analytics, receives button click event
   */
  onSaveButtonClick?: (event?: unknown) => void

  /**
   * function called when user cancels
   */
  onCancel?: () => void

  /**
   * function called to track click events, used for analytics
   */
  trackClickEvent?: (linkId: string, linkPlacement: string) => void
}

/**
 * React component that renders the Tax ID form.
 */
export const TaxIdForm = (props: TaxIdFormProps) => {
  return (
    <>
      <GlobalStyle />
      <BillingFormProvider {...props}>
        <TaxIdFormContainer />
      </BillingFormProvider>
    </>
  )
}

TaxIdForm.defaultProps = {
  onSave: () => null,
  onSaveButtonClick: () => null,
  onCancel: () => null,
  trackClickEvent: () => null
}
