import React, { useCallback, useState } from 'react'
import Button from '@veneer/core/dist/scripts/button'

import { updateAddressItem } from '@/services/AddressService'
import useAppContext from '@/hooks/useAppContext'
import useRootProps from '@/hooks/useRootProps'

import AddressCard from './AddressCard'
import { AddressContainer, ButtonContainer, Subtitle, Title } from './styles'
import useGetText from '@/hooks/useGetText'

enum AddressType {
  NONE = 0,
  CURRENT = 1,
  SUGGESTED = 2
}

const SuggestAddressForm = () => {
  const { state, dispatch } = useAppContext()
  const {
    subscriptionType,
    authProvider,
    onSave,
    baseURLProvider,
    updateURLProvider,
    paperEligibility
  } = useRootProps()
  const getText = useGetText('suggestAddress')

  const [selectedAddress, setSelectedAddress] = useState<number>(
    AddressType.NONE
  )

  const handleSelectedAddress = (addressType: number) => {
    setSelectedAddress(addressType)
  }

  const handleSaveAddress = useCallback(async () => {
    const address =
      selectedAddress === AddressType.CURRENT
        ? state.addressItemData
        : state.suggestedAddress
    await updateAddressItem(
      address,
      state,
      subscriptionType,
      authProvider,
      dispatch,
      baseURLProvider,
      updateURLProvider,
      onSave,
      true,
      paperEligibility
    )
  }, [
    selectedAddress,
    state,
    subscriptionType,
    authProvider,
    dispatch,
    baseURLProvider,
    updateURLProvider,
    onSave,
    true,
    paperEligibility
  ])

  return (
    <>
      <Title>{getText('title')}</Title>
      <Subtitle>{getText('subTitle')}</Subtitle>
      <AddressContainer>
        <AddressCard
          title={getText('yourAddress')}
          address={state?.addressItemData}
          onClick={() => handleSelectedAddress(AddressType.CURRENT)}
          selectedAddress={selectedAddress === AddressType.CURRENT}
        />
        <AddressCard
          title={getText('recommendedAddress')}
          address={state?.suggestedAddress}
          onClick={() => handleSelectedAddress(AddressType.SUGGESTED)}
          selectedAddress={selectedAddress === AddressType.SUGGESTED}
        />
      </AddressContainer>
      <ButtonContainer disabled={Boolean(!selectedAddress)}>
        <Button
          data-testid="save-button"
          type="submit"
          disabled={!selectedAddress}
          onClick={handleSaveAddress}
        >
          {getText('saveBtnLabel')}
        </Button>
      </ButtonContainer>
    </>
  )
}

export default SuggestAddressForm
