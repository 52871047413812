import React from 'react'
import {
  useErrorFields,
  useGetText,
  useTaxpayerDetails,
  useTaxpayerDetailsError
} from '../../hooks'
import { StyledSuccessMessageInline } from './styles'
import IconCheckMarkCircle from '@veneer/core/dist/scripts/icons/icon_checkmark_circle'

export const SuccessMessageInline = () => {
  const getText = useGetText()
  const errorFields = useErrorFields()
  const taxpayerDetails = useTaxpayerDetails()
  const taxpayerDetailsError = useTaxpayerDetailsError()

  if (
    !taxpayerDetailsError &&
    errorFields.size === 0 &&
    taxpayerDetails?.taxId
  ) {
    return (
      <StyledSuccessMessageInline>
        <IconCheckMarkCircle size={24} filled />
        <span>
          {getText(
            'billing_tax_id_form.success_you_have_entered_a_valid_tax_id'
          )}
        </span>
      </StyledSuccessMessageInline>
    )
  }

  return null
}
