import { useState, useEffect } from 'react'
import StoreFrontClient from '../../services/StoreFrontClient'
import useShellContext from '../useShellContext'
import getAuthProvider from '../../helpers/getAuthProvider'

export default function useStoreFrontClient() {
  const [storeFrontClient, setStoreFrontClient] = useState(null)
  const shellContext = useShellContext()
  const authProvider = getAuthProvider()

  useEffect(() => {
    setStoreFrontClient(
      new StoreFrontClient(authProvider, shellContext?.xCorrelationId + '')
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return storeFrontClient
}
