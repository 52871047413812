import Modal from '@veneer/core/dist/scripts/modal/modal'
import tokens from '@veneer/tokens'
import styled from 'styled-components'

interface StyledAddressInfoProps {
  isDisabled?: boolean
}

export const StyledAddressItemContainer = styled.div`
  height: 260px;
  min-height: 260px;

  && {
    padding: ${tokens.layout.size4};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: ${tokens.layout.cornerRadius4};
    background-color: ${tokens.color.white};
  }
`
export const ModalContentContainer = styled.div`
  & .loading {
    width: auto;
    padding-top: 0;
    padding-bottom: 30px;
  }

  & .shipping-content .shipping-fields {
    width: auto;
  }
`
export const StyledModal = styled(Modal)`
  div.vn-modal--content {
    @media (min-width: 991px) {
      width: calc(100% - 13px - 13px);
      max-width: 900px;
    }
  }
  .vn-modal--footer {
    text-align: right;
  }
`

export const StyledButtonContainer = styled.div`
  && {
    font-weight: 400;
    padding: ${tokens.layout.size1} 0 0 0;
    font-family: ${tokens.typography.family0};
    line-height: ${tokens.typography.lineHeight2};
    font-size: ${tokens.typography.size1};
    a {
      cursor: pointer;
      color: ${tokens.color.hpBlue7};
    }
  }
`
export const StyledIconContainer = styled.div`
  && {
    display: flex;
    font-family: ${tokens.typography.family0};
    font-weight: 400;
    flex-direction: column;
    padding-bottom: ${tokens.layout.size1};
    img {
      width: ${tokens.layout.size6};
      height: ${tokens.layout.size6};
    }
  }
`

export const StyledTitle = styled.div<StyledAddressInfoProps>`
  && {
    font-family: ${tokens.typography.family0};
    line-height: ${tokens.typography.lineHeight3};
    font-weight: 400;
    word-wrap: break-word;
    padding-bottom: 2px;
    color: ${(props) =>
      props.isDisabled ? tokens.color.highContrastGray : tokens.color.gray9};
  }
`
export const StyledContent = styled.div<StyledAddressInfoProps>`
  && {
    font-family: ${tokens.typography.family0};
    font-size: ${tokens.typography.size1};
    line-height: ${tokens.typography.lineHeight2};
    font-weight: 400;
    padding-bottom: 2px;
    color: ${(props) =>
      props.isDisabled ? tokens.color.highContrastGray : tokens.color.gray9};
    span {
      word-break: break-word;
    }
  }
`

export const StyledDisabled = styled.span`
  && {
    font-family: ${tokens.typography.family0};
    font-size: ${tokens.typography.size1};
    line-height: ${tokens.typography.lineHeight2};
    font-weight: 400;
    color: ${(props) =>
      props.isDisabled ? tokens.color.highContrastGray : '#767676'};
  }
`
export const StyledAddButtonContainer = styled.div<{ isDisabled?: boolean }>`
  pointer-events: ${(props) => (props.isDisabled ? 'none' : 'auto')};
  cursor: ${(props) => (props.isDisabled ? 'none' : 'default')};
  opacity: ${(props) => (props.isDisabled ? 0.5 : 1)};
  font-size: ${tokens.typography.size1};
  line-height: ${tokens.typography.lineHeight2};
  font-weight: 400;
    && {
    a {
      cursor: pointer;
      color: ${(props) =>
        props.isDisabled
          ? tokens.color.highContrastGray
          : tokens.color.hpBlue7};
  }
    }
    }
`
