import styled from 'styled-components'
import { tokens } from '@veneer/tokens'
import Images from '../../../assets/images'

export const NewTag = styled.div`
  width: 86px;
  height: ${tokens.layout.size8};

  position: absolute;
  top: 31px;

  border-radius: ${tokens.layout.cornerRadius0} ${tokens.layout.cornerRadius2}
    ${tokens.layout.cornerRadius2} ${tokens.layout.cornerRadius0};
  background: #000000;
  color: #ffffff;
  > span {
    position: absolute;
    left: 22px;
    top: 5px;
    font-family: ${tokens.typography.family0};
    font-size: ${tokens.typography.size2};
    font-style: normal;
    font-weight: 400;
    line-height: ${tokens.typography.lineHeight3};
  }
`

export const PaperTitle = styled.div`
  align-self: stretch;
  color: ${tokens.color.gray12};
  font-family: ${tokens.typography.family0};
  font-size: ${tokens.typography.size5};
  font-style: normal;
  font-weight: 400;
  line-height: ${tokens.typography.lineHeight5};
`

export const PaperDescription = styled.div`
  align-self: stretch;
  color: ${tokens.color.gray9};
  font-family: ${tokens.typography.family0};
  font-size: ${tokens.typography.size0};
  font-style: normal;
  font-weight: 400;
  line-height: ${tokens.typography.lineHeight1};
`

export const IconText = styled.div`
  display: flex;
  align-items: center;
  gap: 7px;
  align-self: stretch;
  > p {
    flex: 1 0 0;
    color: ${tokens.color.gray9};
    font-family: ${tokens.typography.family0};
    font-size: ${tokens.typography.size0};
    font-style: normal;
    font-weight: 400;
    line-height: ${tokens.typography.lineHeight1};
  }
`

export const PaperBannerImage = styled.div`
  position: relative;
  width: 50%;
  align-self: stretch;
  background-image: url(${Images.PaperBannerDesktop});
  background-size: cover;
  background-position: center;

  @media (max-width: 800px) {
    width: 298px;
    height: 241px;
    background-image: url(${Images.PaperBannerTablet});
  }

  @media (max-width: 500px) {
    width: 338px;
    flex-shrink: 0;
    height: 114px;
    background-image: url(${Images.PaperBannerMobile});
  }
`

export const BannerContent = styled.div`
  display: flex;
  width: 50%;
  padding: ${tokens.layout.size6} ${tokens.typography.size9};
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  gap: ${tokens.layout.size1};
  align-self: stretch;

  @media (max-width: 800px) {
    // tablet
    width: 398px;
    padding: ${tokens.layout.size5} ${tokens.typography.size9};
  }

  @media (max-width: 500px) {
    // mobile
    display: flex;
    width: 338px;
    height: 257px;
    padding: ${tokens.layout.size4};
    flex-direction: column;
    gap: ${tokens.layout.size4};
    flex-shrink: 0;
    align-self: stretch;
  }
`

export const Banner = styled.div`
  display: flex;
  width: 940px;
  border-radius: 12px;
  background: #ffffff;
  align-items: center;

  @media (max-width: 800px) {
    // tablet
    width: 696px;
  }

  @media (max-width: 500px) {
    // mobile
    width: 330px;
    height: 386px;
    flex-direction: column;
    align-items: flex-start;
    gap: ${tokens.layout.size2};
    flex-shrink: 0;
  }
`
