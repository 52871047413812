import React, { useEffect, useState, useRef } from 'react'
import Tooltip from '@veneer/core/dist/scripts/tooltip'
import Button from '@veneer/core/dist/scripts/button'
import { AxiosError } from 'axios'
import {
  StyledButtons,
  StyledCancelButton,
  StyledErrorMessageContainer,
  StyledInfoIcon,
  StyledTextBox,
  StyledTitle,
  StyledTooltipText
} from './styles'
import {
  useDispatch,
  useErrorFields,
  useGetText,
  useOnSave,
  useOnCancel,
  useTaxpayerDetailsSaving,
  useTaxpayerDetails,
  useSettings,
  useTaxpayerDetailsError
} from '../../hooks'
import {
  fetchSettingsAction,
  fetchTaxpayerDetailsAction,
  saveTaxpayerDetailsAction,
  updateTaxpayerDetailAction
} from '../../actions'
import { ErrorType } from '../../types'
import { ErrorMessageInline } from '../ErrorMessageInline'
import { SuccessMessageInline } from '../SuccessMessageInline'
import { Spinner } from '../Spinner'
import { ThemeProvider as VeneerThemeProvider } from '@veneer/theme'

export const TaxIdFormContent = () => {
  const getText = useGetText()
  const dispatch = useDispatch()
  const errorFields = useErrorFields()
  const taxpayerDetailsSaving = useTaxpayerDetailsSaving()
  const onTaxIdFormSave = useOnSave()
  const onTaxIdFormCancel = useOnCancel()
  const taxpayerDetails = useTaxpayerDetails()
  const taxpayerDetailsError = useTaxpayerDetailsError()
  const settings = useSettings()
  const [taxId, setTaxId] = useState('')
  const [ready, setReady] = useState(false)
  const [renderComponent, setRenderComponent] = useState(false)
  const initialTaxId = useRef<string>()
  const hasTaxId = initialTaxId.current && initialTaxId.current !== ''

  const handleChangeInput = async (value: string) => {
    setTaxId(value)
    await dispatch(
      updateTaxpayerDetailAction({
        field: 'taxId',
        value
      })
    )
  }

  const handleSave = async () => {
    try {
      await dispatch(saveTaxpayerDetailsAction())
      onTaxIdFormSave()
    } catch (error) {
      const { response } = error as AxiosError
      if (response?.status === 401) {
        onTaxIdFormSave(ErrorType.expired_token)
      } else if (response?.status === 403) {
        onTaxIdFormSave(ErrorType.expired_critical_scope)
      }
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(fetchSettingsAction())
      await dispatch(fetchTaxpayerDetailsAction())
    }
    fetchData()
  }, [dispatch])

  useEffect(() => {
    const checkAccountTypeFields = () => {
      const { required = [], optional = [] } =
        settings?.billingAccountTypeFields[
          taxpayerDetails?.accountType || ''
        ] || {}
      const allFields = [...required, ...optional]

      if (allFields.includes('taxId')) {
        setRenderComponent(true)
      }
    }

    if (settings && taxpayerDetails && !ready) {
      initialTaxId.current = taxpayerDetails.taxId
      checkAccountTypeFields()
      setReady(true)
    }
  }, [settings, taxpayerDetails, ready])

  if (!ready) {
    return <Spinner />
  }

  if (!renderComponent) {
    return <div data-testid="tax-id-error" />
  }

  return (
    <>
      <StyledTitle>
        {hasTaxId
          ? getText('billing_tax_id_form.edit_tax_id')
          : getText('billing_tax_id_form.add_tax_id')}
      </StyledTitle>
      <VeneerThemeProvider shape="round">
        <StyledTextBox
          data-testid="tax-id-text-box"
          name={'tax-id'}
          label={getText(`billing_tax_id_form.enter_your_tax_id`)}
          value={taxId}
          onChange={handleChangeInput}
          error={errorFields.has('taxId') || !!taxpayerDetailsError}
          required
          trailingIcon={
            <Tooltip
              content={
                <StyledTooltipText
                  dangerouslySetInnerHTML={{
                    __html: getText(
                      `billing_account_type.${taxpayerDetails?.accountType}_tax_id_example_html`
                    )
                  }}
                />
              }
              placement="bottom"
              arrow
            >
              <StyledInfoIcon
                data-testid={`tax-id-form-tooltip`}
                size={32}
                color="gray6"
              />
            </Tooltip>
          }
        />
      </VeneerThemeProvider>

      <StyledErrorMessageContainer>
        <ErrorMessageInline />
      </StyledErrorMessageContainer>
      {taxId && <SuccessMessageInline />}
      <StyledButtons>
        <StyledCancelButton appearance="secondary" onClick={onTaxIdFormCancel}>
          {getText('billing_form.cancel')}
        </StyledCancelButton>
        <Button
          loading={taxpayerDetailsSaving}
          onClick={handleSave}
          disabled={errorFields.size > 0 || taxpayerDetailsSaving || !taxId}
        >
          {getText('billing_tax_id_form.save')}
        </Button>
      </StyledButtons>
    </>
  )
}
