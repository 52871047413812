import React from 'react'
import { ThemeProvider, ThemeContextInterface } from '@veneer/theme'
import TabContent from '../src/components/TabContent'
import { ShellProps } from '../src/types/shell'
import { MfePropsType } from '../src/types/mfeProps'
import projectNames from '../src/configs/projectNames'

declare global {
  interface Window {
    Shell: ShellProps
    JWeb?: {
      isNative?: boolean
    }
  }
}

/**
 * @function Root Main function
 * @param props
 * @returns
 */
export default function Root({ ...props }: MfePropsType) {
  // You can find the "stack" information by props
  const themeMode = props.mode
    ? (props.mode as ThemeContextInterface['mode'])
    : 'light'
  console.log('In root - props: ', props)
  return (
    <section
      className={`${projectNames.namespace}`}
      id={projectNames.packageJsonName}
    >
      <ThemeProvider mode={themeMode}>
        <TabContent {...props} />
      </ThemeProvider>
    </section>
  )
}
