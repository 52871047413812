import React, { useEffect, useMemo, useState } from 'react'
import { Container, StyledMainTitle } from './styles'
import Billing from '../Billing'
import Shipping from '../Shipping'
import useGetText from '@/hooks/useGetText'
import {
  publishEvent,
  ShippingAndBillingScreenDisplayed
} from '@/utils/analytics'

import useInstantInkSubscriptionInfo from '@/hooks/useInstantInkSubscriptionInfo'
import useSubscriptionInfo from '@/hooks/useSubscriptionInfo'
import getLocalization from '@/helpers/getLocalization'
import AssetsProviderFactory from '@/assets/AssetsProviderFactory'
import useAccount from '@/hooks/useAccount'
import { setAssetsProvider, setInlineNotification } from '@/actions/Actions'

import ErrorCard from '@/components/ErrorCard'
import { useAppContext } from '@/context/AppContext'
import EmptyState from '@/components/EmptyState'
import Skeleton from '../Skeleton'
import { WarningContext } from '@/context/WarningContext'
import { WarningType } from '@/types/Warning'
import HeaderNotification from '@/components/HeaderNotification'
import usePaymentDetails from '@/hooks/usePaymentDetails/usePaymentDetails'
import useCriticalScopes from '@/hooks/useCriticalScopes/useCriticalScopes'
import SecuritySessionExpiredModal from '../SecuritySessionExpiredModal'
import CommonNotification from '../CommonNotification/CommonNotification'
import CommonIINotification from '../CommonIINotification/CommonIINotification'

interface UnknownErrorResponse {
  message?: string
}
const BillingAndShipping = () => {
  const getText = useGetText()
  const { state, dispatch } = useAppContext()

  const [warningType, setWarningType] = useState('noWarning' as WarningType)
  const { language } = getLocalization()
  const {
    info: { data: Account, isFetching: accountDataIsFetching }
  } = useAccount()
  const {
    info: {
      data: iInkSubscriptionInfo,
      isFetching: iInkIsFetching,
      error: iInkError
    }
  } = useInstantInkSubscriptionInfo()
  const { isBillingError } = usePaymentDetails()
  const {
    info: {
      data: hpOneSubscriptionInfo,
      isFetching: hpOneIsFetching,
      error: hpOneError
    }
  } = useSubscriptionInfo()
  console.log(isBillingError)

  const apiError = (hpOneError as UnknownErrorResponse)?.message &&
    (iInkError as UnknownErrorResponse)?.message && (
      <ErrorCard getText={getText} />
    )

  useEffect(() => {
    publishEvent(ShippingAndBillingScreenDisplayed)
  }, [])

  const country = Account ? Account.regionId : process.env.DEFAULT_COUNTRY
  const currLanguage = language ? language : process.env.DEFAULT_LANGUAGE

  useEffect(() => {
    if (currLanguage && country) {
      const assetProvider = AssetsProviderFactory.create(currLanguage, country)
      dispatch(setAssetsProvider(assetProvider))
    }
    publishEvent(ShippingAndBillingScreenDisplayed)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const dataIsFetching =
    iInkIsFetching || accountDataIsFetching || hpOneIsFetching
  const hpOneSubscriptionInfolength = hpOneSubscriptionInfo?.length > 0
  const emptyStateContent = !apiError &&
    !iInkIsFetching &&
    !hpOneIsFetching &&
    ((iInkError && hpOneError) ||
      (!(iInkSubscriptionInfo?.instantInkSubscriptions?.length > 0) &&
        !hpOneSubscriptionInfolength)) && <EmptyState />

  const warningContextProviderValue = useMemo(
    () => ({ warningType, setWarningType }),
    [warningType, setWarningType]
  )
  const {
    error: criticalScopeError,
    isLoading: isCriticalScopeLoading,
    forceRefresh: getCriticalScopes
  } = useCriticalScopes()
  const [sessionModal, setSessionModal] = useState<boolean>(false)
  const handleSessionModal = () => {
    setSessionModal(false)
  }
  useEffect(() => {
    if (criticalScopeError && !isCriticalScopeLoading) {
      setSessionModal(true)
    }
  }, [criticalScopeError, isCriticalScopeLoading])
  useEffect(() => {
    if (iInkSubscriptionInfo?.instantInkSubscriptions?.length > 0)
      getCriticalScopes()
  }, [getCriticalScopes, iInkSubscriptionInfo?.instantInkSubscriptions?.length])
  const [subscriptionId, setSubscriptionId] = useState<string>('')
  useEffect(() => {
    if (hpOneSubscriptionInfo && hpOneSubscriptionInfo?.[0]?.subscriptionId) {
      setSubscriptionId(hpOneSubscriptionInfo?.[0]?.subscriptionId)
    }
  }, [hpOneSubscriptionInfo])
  const [refreshCounter, setRefreshCounter] = useState(0)
  const updateCommonNotification = () => {
    dispatch(
      setInlineNotification({
        ...state.inlineNotification,
        refreshNotification: false
      })
    )
    setRefreshCounter((prevCounter) => prevCounter + 1)
  }
  const showBillingandShipping =
    !apiError && !emptyStateContent && !iInkIsFetching && !hpOneIsFetching
  return (
    <WarningContext.Provider value={warningContextProviderValue}>
      {sessionModal &&
        iInkSubscriptionInfo?.instantInkSubscriptions?.length > 0 && (
          <SecuritySessionExpiredModal
            show={sessionModal}
            onClose={handleSessionModal}
          />
        )}
      {showBillingandShipping && (
        <>
          {subscriptionId ? (
            <CommonNotification
              key={refreshCounter}
              subscriptionId={subscriptionId}
              updateCommonNotification={updateCommonNotification}
            />
          ) : (
            <CommonIINotification
              key={refreshCounter}
              updateCommonNotification={updateCommonNotification}
            />
          )}
        </>
      )}
      <HeaderNotification />
      <Container>
        <StyledMainTitle role="heading" aria-level="1">
          {getText('mainTitle', {
            defaultValue: 'Shipping & Billing'
          })}
        </StyledMainTitle>
        {dataIsFetching && <Skeleton width="60vw" height="5vh" />}
        {apiError}
        {emptyStateContent}
        {showBillingandShipping && (
          <>
            <Shipping />
            <Billing />
          </>
        )}
      </Container>
    </WarningContext.Provider>
  )
}

export default BillingAndShipping
