import styled from 'styled-components'
import tokens from '@veneer/tokens'

const deviceSize = {
  mobile: tokens.layout.smMin,
  tablet: tokens.layout.mdMin,
  desktop: tokens.layout.lgMin,
  ultraWide: tokens.layout.lgMax
}

export const FormContainer = styled.div`
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: left;
  font-size: calc(10px + 2vmin);
`

export const StyledTitle = styled.div`
  && {
    text-algin: left;
    font-family: ${tokens.typography.family0};
    color: ${tokens.color.gray12};
    font-size: ${tokens.typography.size7};
    line-height: 40px;
    font-weight: 400;
    padding: 0 0;
    margin-bottom: ${tokens.layout.size8};
  }
`

export const StyledDesc = styled.div`
  && {
    text-algin: left;
    font-family: ${tokens.typography.family0};
    color: ${tokens.color.gray12};
    font-size: ${tokens.typography.size2};
    line-height: 24px;
    font-weight: 400;
    padding: 0 0 12px 0;
  }
`

export const StyledError = styled.div`
  && {
    display: flex;
    flex-direction: row;
    text-algin: center;
    font-family: ${tokens.typography.family0};
    color: ${tokens.color.gray12};
    font-size: ${tokens.typography.size2};
    line-height: 24px;
    font-weight: 400;
    margin: 0 auto 16px auto;
    color: red;
  }
  & div {
    margin: 8px 0 0 12px;
  }
`

export const StyledColumn = styled.div<{
  justifyContent?: string
  alignItems?: string
  marginBottom?: string
  marginTop?: string
  gap?: string
}>`
  display: flex;
  flex-direction: row;
  @media (max-width: ${deviceSize.tablet}) {
    flex-direction: column;
  }
  width: 100%;
  justify-content: ${(props) => props.justifyContent};
  align-items: ${(props) => props.alignItems};
  gap: ${(props) => props.gap};
  margin-bottom: ${(props) => props.marginBottom};
  margin-top: ${(props) => props.marginTop};
  font-size: 16px;
  font-family: ${tokens.typography.family0};
  && {
    & > div > div > label {
      color: #404040 !important;
    }
  }
`

export const FieldContainer = styled.div`
  && {
    width: 100%;
    flex: 0 1 auto;
  }
`

export const OptionsRow = styled.div`
  width: 100%;
  margin-bottom: 40px;
  && {
    & > label {
      width: 100%;
    }

    & > label > span {
      font-size: ${tokens.typography.size2};
      font-family: ${tokens.typography.family0};
    }
  }
`

export const ActionsRow = styled.div`
  && {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
    gap: ${tokens.layout.size4};
    align-self: stretch;
    font-size: ${tokens.typography.size2};
    font-family: ${tokens.typography.family0};
    margin: 0;
    width: 100%;
  }
`

export const ButtonContainer = styled.div`
  && {
    margin: 0px;
  }
`
export const StyledNotification = styled.div`
  && {
    display: flex;
    flex-direction: row;
    font-family: ${tokens.typography.family0};
    color: ${tokens.color.black};
    font-size: ${tokens.typography.size1};
    line-height: 20px;
    font-weight: 600;
    padding: 12px 20px 12px 10px;
    margin: 0 -47.5px 15px;
    @media (max-width: ${deviceSize.mobile}) {
      margin: 0 -22px 15px;
      line-height: 18px;
      margin: 0 -24px 15px;
    }
    background-color: #ebebeb;
    svg {
      margin: 5px 8px 6px 40px;
      @media (max-width: ${deviceSize.mobile}) {
        margin: -7px 10px 0 10px;
      }
    }
    div {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }
  }
`
