import { useCallback, useEffect, useState } from 'react'
import useStoreFrontClient from '../useStoreFrontClient'
import { CriticalScopes } from 'src/types/TaxpayerDetails'

type CriticalScopesResponse = {
  data: CriticalScopes
  error: boolean
  isLoading: boolean
  forceRefresh: () => void
}

const useCriticalScopes = (): CriticalScopesResponse => {
  const client = useStoreFrontClient()

  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(false)
  const [data, setData] = useState(undefined)

  const getCriticalScopes = useCallback(async () => {
    setIsLoading(true)
    try {
      const data = await client.getCriticalScopes()
      setData(data)
    } catch (err) {
      if (err?.response?.status === 403) {
        setError(true)
      }
    } finally {
      setIsLoading(false)
    }
  }, [client])

  useEffect(() => {
    getCriticalScopes()
  }, [])

  return {
    data,
    error,
    isLoading,
    forceRefresh: getCriticalScopes
  }
}

export default useCriticalScopes
