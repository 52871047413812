import React from 'react'
import Images from '../../../../../../../assets/images'
import { instantInkStates } from '../../../../../utils/instantInkStates'
import useGetText from '../../../../../hooks/useGetText'

import {
  StyledTitle,
  ImageContainer,
  StyledSubscriptionContainer
} from './styles'

interface HeaderProps {
  commonProps: any
}

const Header = (props: HeaderProps) => {
  const { commonProps } = props
  const { iInk } = commonProps
  const getText = useGetText('subscriptions')

  const instantInkSubData =
    iInk?.iInkSubscriptionInfo.instantInkSubscriptions?.filter(
      (subitem) => subitem.state !== instantInkStates.OBSOLETE
    )
  const subscriptionIdList = instantInkSubData
    ?.map((subitem) => subitem.accountIdentifier)
    ?.join(', ')

  return (
    <div>
      <ImageContainer>
        <img src={Images.instant_ink_single_line} alt="Instant Ink Plan" />
      </ImageContainer>
      {subscriptionIdList && (
        <>
          <StyledTitle>{getText('subscriptionId')}</StyledTitle>
          <StyledSubscriptionContainer data-testid="subscriptionIdsTestId">
            {subscriptionIdList}
          </StyledSubscriptionContainer>
        </>
      )}
    </div>
  )
}

export default Header
