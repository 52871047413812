import styled from 'styled-components'
import tokens from '@veneer/tokens'
import Modal from '@veneer/core/dist/scripts/modal/modal'

export const ModalContentContainer = styled.div`
  & .shipping-content .shipping-fields {
    width: auto;
  }
`

export const BillingItemContainer = styled.div`
  && {
    width: 48%;
    font-family: ${tokens.typography.family0};
    font-size: ${tokens.typography.size2};
    font-weight: 400;
    margin: 24px 16px 0 0;

    && {
      @media only screen and (max-width: ${tokens.layout.smMax}) {
        width: 100%;
      }
    }
  }
`

export const StyledBillingTileContainer = styled.div`
  && {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 210px;
  }
`
export const StyledModal = styled(Modal)`
  div.vn-modal--content {
    @media (min-width: 991px) {
      width: calc(100% - 13px - 13px);
      max-width: 648px !important;
    }
  }
`
export const StyledBillingDetailsContainer = styled.div`
  && {
    font-family: ${tokens.typography.family0};
    font-size: ${tokens.typography.size1};
    font-weight: 400;
    line-height: 16px;
    color: ${tokens.color.gray9};
    padding: 4px 0 0 0;
    letter-spacing: 0.02em;
    margin-top: ${tokens.layout.size5};
  }
`

export const StyledEditButtonContainer = styled.div`
  pointer-events: ${(props) => (props.isDisabled ? 'none' : 'auto')};
  cursor: ${(props) => (props.isDisabled ? 'none' : 'default')};
  opacity: ${(props) => (props.isDisabled ? 0.5 : 1)};
  && {
    padding: 0;
    font-size: ${tokens.typography.size1};
    line-height: ${tokens.typography.lineHeight2};
    display: flex;
    align-items: center;
    a {
      cursor: pointer;
      color: ${(props) =>
        props.isDisabled
          ? tokens.color.highContrastGray
          : tokens.color.hpBlue7};
      display: flex;
      align-items: center;
    }
  }
`
