import React, { useEffect, useMemo, useRef } from 'react'
import { useAsyncReducer } from '../../hooks'
import { JarvisAuthProvider } from '@jarvis/web-http'
import { BaseURLProvider } from '@jarvis/web-stratus-client'
import {
  shippingFormReducer,
  initialShippingFormState
} from '../../reducers/shippingForm'
import { ShippingFormContext } from '../../contexts/shippingFormContext'
import { setAssetsProviderAction } from '../../actions'
import { ShippingView } from '../ShippingView'
import { GenericErrorHandler } from '../GenericErrorHandler'
import { OnSaveData, OnSaveError } from '../../types'
import '@veneer/styles/typography/typography.css'
import { setFallbackLocale } from '../../lib/localeHelpers'
import { ContainerSizeProvider } from '../ContainerSizeProvider'
import { GlobalStyle } from '../../styles/global'
import * as Styled from './styles'

/**
 * React component that renders the shipping form.
 */
export type ShippingFormProps = {
  /** the user interface country in ISO 3166-1 alpha-2 format */
  country: string

  /**
   * the user interface language in ISO 639-1 format
   */
  language: string

  /**
   * function to get the API base URLs - apiName and apiVersion can be used to query Services Discovery API
   */
  baseURLProvider?: BaseURLProvider

  /**
   * authentication provider object that must include the getAccessToken function
   */
  authProvider?: JarvisAuthProvider

  /**
   * xCorrelationId used for logging
   */
  xCorrelationId?: string

  /**
   * mock flag, if set to true then all Shipping API calls will be mocked
   */
  mockStratus?: boolean

  /**
   * function called when shipping data is updated, if an error parameter is present then the save failed.
   * Possible error values:
   *- OnSaveError.expired\_critical\_scope
   *
   * data parameter list:
   * - addressId
   */
  onSave?: (error?: OnSaveError, data?: OnSaveData) => void

  /**
   * function called when save button is clicked. Used for SMB analytics, receives button click event
   */
  onSaveButtonClick?: (event?: unknown) => void

  /**
   * function called when user cancels. If not defined, will not render cancel button
   */
  onCancel?: () => void

  /**
   * printer cloud identifier, used to fetch and associate addresses for a printer and takes precedence over address identifier
   */
  cloudId?: string

  /**
   * address identifier, used to edit an address
   */
  addressId?: string

  /**
   * function called to track click events, used for analytics
   */
  trackClickEvent?: (linkId: string, linkPlacement: string) => void

  /**
   * custom save button text. Defaults to Save if not passed in
   */
  saveButtonText?: string

  /**
   * hide title text
   */
  hideTitle?: boolean

  /**
   * custom secondary button.
   */
  secondaryButton?: HTMLElement

  /**
   * disable list view when multishipping is supported for the tenant
   */
  disableListView?: boolean

  /**
   * enable debugging
   */
  enableDebugging?: boolean
}

export const ShippingForm = (props: ShippingFormProps): JSX.Element => {
  const containerRef = useRef(null)

  const { language, country } = useMemo(
    () => setFallbackLocale(props.language, props.country),
    [props.language, props.country]
  )

  const [shippingFormState, dispatch] = useAsyncReducer(
    shippingFormReducer,
    initialShippingFormState({ ...props, language, country })
  )

  useEffect(() => {
    ;(async () => {
      await dispatch(setAssetsProviderAction({ language, country }))
    })()
  }, [language, country, dispatch])

  return (
    <ShippingFormContext.Provider value={{ shippingFormState, dispatch }}>
      <GlobalStyle />
      <ContainerSizeProvider containerRef={containerRef}>
        <Styled.ShippingFormContainer ref={containerRef}>
          <GenericErrorHandler />
          <ShippingView />
        </Styled.ShippingFormContainer>
      </ContainerSizeProvider>
    </ShippingFormContext.Provider>
  )
}

ShippingForm.defaultProps = {
  onSave: () => null,
  onSaveButtonClick: () => null,
  trackClickEvent: () => null,
  disableListView: false,
  enableDebugging: false
}
