import React from 'react'
import Page from './components/Page'

const InitialComponent = (props) => {
  props.commonProps.notificationList =
    props.commonProps.commonNotificationData.notificationsList
  return (
    <>
      {(props?.commonProps?.isHPOneSubscriptionAvailable ||
        (props?.commonProps?.iInk?.iInkSubscriptionInfo
          ?.instantInkSubscriptions &&
          props?.commonProps?.iInk?.iInkSubscriptionInfo.instantInkSubscriptions
            .length > 0)) && <Page commonProps={props.commonProps} />}
    </>
  )
}

export default InitialComponent
