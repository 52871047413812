import React, { useCallback, useEffect, useState } from 'react'
import { LoadingHandler } from '@monetization/hpaip-ui-shared-components'
import { SubscriptionType } from '../../../types/SubscriptionType'
import { HpOneSubsEntityState } from '../../../types/HpOneSubsEntityState'
import { mblEntityType } from '../../../types/SubscriptionEntity'
import { getMainEntity } from '../../../helpers/getMainEntity'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { ShippingAddressItemContainer } from '../styles'
import useGetText from '@/components/ShippingBillingRoot/hooks/useGetText'
import Card from '@veneer/core/dist/scripts/card'
import ErrorCard from '../../ErrorCard'
import HPAIPShippingAddressInfo from './HPAIPShippingAddressInfo'
import { StyledAddressItemContainer } from './HPAIPShippingAddressInfo/styles'
import {
  AddressClient,
  Address
} from '@monetization/hpaip-notification-rules-react'

const HPAIPShipping = ({ commonProps }) => {
  const { hpaip, stack, authProvider, commonNotificationData } = commonProps
  const shippingAddressId =
    commonNotificationData?.printerData?.printer?.shippingAddressId
  const { enableManagePaperPostEnrollment } = useFlags()
  const [isPaperEnrolled, setIsPaperEnrolled] = useState(false)
  const [paperEligibility, setPaperEligibility] = useState(false)
  const [shippingInfo, setShippingInfo] = useState<Address>(null)
  const [IsShippingFetching, setIsShippingFetching] = useState<boolean>(true)
  const [shippingError, setShippingError] = useState<boolean>(false)
  const getText = useGetText()
  const fetchAddress = useCallback(async () => {
    try {
      const addressClient = new AddressClient(authProvider, stack)
      const getAddressByIdData = await addressClient.getAddressById(
        shippingAddressId
      )
      setShippingInfo(getAddressByIdData)
      setIsShippingFetching(false)
    } catch (err) {
      setIsShippingFetching(false)
      setShippingError(true)
    }
  }, [authProvider, stack, shippingAddressId])

  useEffect(() => {
    if (shippingAddressId) {
      fetchAddress()
    }
  }, [fetchAddress, shippingAddressId])

  useEffect(() => {
    const instantPaper = hpaip?.hpOneSubscriptionInfo?.some((subitem) =>
      subitem.entities?.some(
        (item) =>
          item.entityType === 'instant-paper' &&
          [
            HpOneSubsEntityState.ACTIVE,
            HpOneSubsEntityState.PENDING,
            HpOneSubsEntityState.DEACTIVATING
          ].includes(item.state)
      )
    )
    setIsPaperEnrolled(instantPaper)
  }, [hpaip?.hpOneSubscriptionInfo])

  useEffect(() => {
    setPaperEligibility(enableManagePaperPostEnrollment && isPaperEnrolled)
  }, [isPaperEnrolled, enableManagePaperPostEnrollment])

  const isHpOneEntity = (entry) =>
    [
      mblEntityType.pc,
      mblEntityType.printer,
      mblEntityType.chromebook
    ].includes(entry.entityType)

  const isSubscribed = (entry): boolean => {
    const entity = [getMainEntity(entry.entities)]
    return !entity.find(
      (item) =>
        isHpOneEntity(item) && item.state !== HpOneSubsEntityState.ACTIVE
    )
  }

  const isCancelling = (entry) => {
    const entity = [getMainEntity(entry.entities)]
    return !entity.find(
      (item) => isHpOneEntity(item) && !cancellingStates.includes(item.state)
    )
  }

  const cancellingStates = [
    HpOneSubsEntityState.DEACTIVATING,
    HpOneSubsEntityState.INACTIVE
  ]

  const onAddressSaved = async () => {
    setIsShippingFetching(true)
    setShippingError(false)
    fetchAddress()
  }

  const cardContent = (
    <ErrorCard
      hpOneSubtitle={getText('errorScreen.hpOneSubtitle', {
        defaultValue: 'Your HP All-In Plan information did not load.'
      })}
      refetchData={onAddressSaved}
    />
  )
  const renderShippingAddressInfo = () => (
    <HPAIPShippingAddressInfo
      commonProps={commonProps}
      shippingAddressInfo={shippingInfo}
      friendlySubscriptionId={
        hpaip?.hpOneSubscriptionInfo[0].friendlySubscriptionId
      }
      subscriptionId={hpaip?.hpOneSubscriptionInfo[0].subscriptionId}
      entityType={SubscriptionType.HP_ONE}
      enableEdit={isSubscribed(hpaip?.hpOneSubscriptionInfo[0])}
      onAddressSaved={onAddressSaved}
      isDisabled={isCancelling(hpaip?.hpOneSubscriptionInfo[0])}
      paperEligibility={paperEligibility}
    />
  )

  const renderContent = () => {
    if (shippingInfo) {
      return renderShippingAddressInfo()
    }

    return (
      <StyledAddressItemContainer>
        <LoadingHandler
          loading={IsShippingFetching}
          error={shippingError}
          customError={
            <Card
              content={cardContent}
              className="card-box"
              customStyle={{ borderRadius: 8 }}
            />
          }
        />
      </StyledAddressItemContainer>
    )
  }

  return (
    <ShippingAddressItemContainer>
      {renderContent()}
    </ShippingAddressItemContainer>
  )
}

export default HPAIPShipping
