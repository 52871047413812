import styled from 'styled-components'

interface ExpirationTextProps {
  expirationStatus: string
}

const statusToColorMap = {
  about_to_expire: '#b2600c',
  expired: '#CC2C00'
}

export const PaymentIconInfo = styled.div<ExpirationTextProps>`
  && {
    color: ${(props) => statusToColorMap[props.expirationStatus]};
  }
  .iconInfo {
    position: relative;
    top: 3px;
    color: ${(props) => statusToColorMap[props.expirationStatus]};
  }
`
