import React from 'react'
import Modal from '@veneer/core/dist/scripts/modal'
import Button from '@veneer/core/dist/scripts/button'
import Group from '@veneer/core/dist/scripts/group'
import { forceLoginHandling } from '../../../utils/forceLoginHandling'
import useGetText from '../../../hooks/useGetText'

interface SecuritySessionExpiredModalProps {
  onClose: () => void
  show: boolean
}

export default function SecuritySessionExpiredModal({
  onClose,
  show
}: Partial<SecuritySessionExpiredModalProps>) {
  const [shouldShow, setShouldShow] = React.useState(false)
  const getText = useGetText('sessionExpired')

  const handleClose = () => {
    setShouldShow(false)
    onClose()
  }
  const handleLogin = () => {
    forceLoginHandling()
  }
  React.useEffect(() => {
    if (show !== shouldShow) {
      setShouldShow(show)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show])
  return (
    <>
      {shouldShow && (
        <div style={{ position: 'relative' }}>
          <Modal
            align="center"
            id="modal-id"
            show={show}
            onClose={onClose}
            footer={
              <Group>
                <Button
                  appearance="secondary"
                  data-testid="modalCloseButton"
                  onClick={handleClose}
                >
                  {getText('onCancel', {
                    defaultValue: 'Cancel'
                  })}
                </Button>
                <Button data-testid="modalLoginButton" onClick={handleLogin}>
                  {getText('onLogin', {
                    defaultValue: 'Login'
                  })}
                </Button>
              </Group>
            }
            title={getText('title', {
              defaultValue: 'Security session expired'
            })}
          >
            <p>
              {getText('subtitle1', {
                defaultValue:
                  'For your security, as there was no activity for more than 15 minutes, you should log in again to continue the action you were taking.'
              })}
            </p>
            <div style={{ marginTop: '32px' }}>
              {getText('subtitle2', {
                defaultValue: 'Please log in again.'
              })}
            </div>
          </Modal>
        </div>
      )}
    </>
  )
}
