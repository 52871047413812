import { useEffect, useState } from 'react'
import useStoreFrontClient from '../useStoreFrontClient'
import { AccountType, Action, TaxpayerDetails } from '@/types/TaxpayerDetails'
import sendOpenTelemetryEvent from '@/helpers/sendOpenTelemetryEvent'

const useTaxId = () => {
  const storeFrontClient = useStoreFrontClient()

  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(false)
  const [data, setData] = useState<TaxpayerDetails>(null)

  const [config, setConfig] = useState({
    show: false,
    action: Action.None,
    enabled: false
  })
  const fetchData = async () => {
    setIsLoading(true)
    try {
      const taxIdData = await storeFrontClient.getTaxIdData()
      if (
        taxIdData?.companyName &&
        taxIdData?.accountType === AccountType.Business
      ) {
        setData(taxIdData)
      } else {
        setConfig({ show: false, action: Action.None, enabled: false })
        setData(null)
      }
    } catch (error) {
      sendOpenTelemetryEvent(
        'Error while fetching getTaxIdData: ' + JSON.stringify(error)
      )
      setError(true)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (storeFrontClient) {
      fetchData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeFrontClient])

  useEffect(() => {
    if (data) {
      const hasTaxId = !!data?.taxId
      const hasNonProfitTaxId = !!data?.nonProfitTaxId
      const allowEditingTaxId = !!data?.allowEditingTaxId

      let newConfig = { show: false, action: Action.None, enabled: false }

      if (hasTaxId && hasNonProfitTaxId) {
        newConfig = { show: false, action: Action.None, enabled: false }
      } else if ((hasTaxId || hasNonProfitTaxId) && !allowEditingTaxId) {
        newConfig = { show: true, action: Action.Update, enabled: false }
      } else if ((hasTaxId || hasNonProfitTaxId) && allowEditingTaxId) {
        newConfig = { show: true, action: Action.Update, enabled: true }
      } else if (!hasNonProfitTaxId) {
        newConfig = { show: true, action: Action.Add, enabled: true }
      }
      setConfig(newConfig)
    }
  }, [data])

  return {
    data,
    error,
    isLoading,
    config,
    forceRefresh: fetchData
  }
}

export default useTaxId
