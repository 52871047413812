import useAppContext from '@/hooks/useAppContext'

const useAssetsProvider = () => {
  const {
    state: { assetsProvider }
  } = useAppContext()
  return assetsProvider
}

export default useAssetsProvider
