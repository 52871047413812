import styled from 'styled-components'
import tokens from '@veneer/tokens'

export const CCImageContainer = styled.div`
  && {
    img {
      width: 40px;
      height: ${tokens.layout.size7};
      margin-top: 64px;
    }
  }
`
export const CCInfoText = styled.div`
  && {
    font-size: ${tokens.typography.size1};
    line-height: ${tokens.typography.lineHeight2};
    color: ${tokens.color.gray12};
    margin-bottom: ${tokens.layout.size1};
  }
`

export const StyledAddButtonContainer = styled.div<{ isDisabled?: boolean }>`
  pointer-events: ${(props) => (props.isDisabled ? 'none' : 'auto')};
  cursor: ${(props) => (props.isDisabled ? 'none' : 'default')};
  opacity: ${(props) => (props.isDisabled ? 0.5 : 1)};
  && {
    padding: 0;
    font-size: ${tokens.typography.size1};
    line-height: ${tokens.typography.lineHeight2};
    a {
      cursor: pointer;
      color: ${(props) =>
        props.isDisabled ? tokens.color.highContrastGray : '#0278ab'};
    }
  }
`
