import styled from 'styled-components'
import tokens from '@veneer/tokens'
import Modal from '@veneer/core/dist/scripts/modal'

const { color, typography } = tokens

interface FooterProps {
  isDisabled: boolean
}

export const StyledModal = styled(Modal)`
  div.vn-modal--content {
    @media (min-width: 991px) {
      width: calc(100% - 13px - 13px);
      max-width: 648px !important;
    }
  }
`

export const ManagePaymentText = styled.a<FooterProps>`
  font-size: ${typography.size1};
  line-height: ${typography.lineHeight2};
  font-weight: 400;
  pointer-events: ${(props) => (props.isDisabled ? 'none' : 'auto')};
  cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'pointer')};
  color: ${(props) =>
    props.isDisabled ? color.highContrastGray : color.hpBlue7};
`
