import React from 'react'
import { ShellProvider } from '@/context/shellContext'
import { MfePropsType, AppProps } from '@/types/mfeProps'
import AppProvider from '@/context/AppProvider'
import Shipping from '../Shipping'

type AppPropsType = AppProps & MfePropsType

const ShippingContainer = (props: AppPropsType) => {
  console.log('In ShippingContainer, props: ', props)
  return (
    <AppProvider>
      <ShellProvider value={props}>
        <Shipping />
      </ShellProvider>
    </AppProvider>
  )
}

export default ShippingContainer
