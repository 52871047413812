import { JarvisAuthProvider } from '@jarvis/web-http'
import axios, { AxiosInstance } from 'axios'
import ShippingAddress from '@/types/ShippingAddress'
import InstantInkShippingAddress from '@/types/InstantInkShippingAddress'

export default class AddressInfoClient {
  httpClient: AxiosInstance
  authProvider: JarvisAuthProvider
  baseURL: string
  updateURL: string

  constructor(
    authProvider: JarvisAuthProvider,
    baseURL: string,
    updateURL: string
  ) {
    this.httpClient = axios
    this.httpClient.defaults.params = {}
    this.authProvider = authProvider
    this.baseURL = baseURL
    this.updateURL = updateURL
  }

  //for HP_ONE address, call MBLC API
  async getHPOneAddressItemData(addressId: string): Promise<ShippingAddress> {
    const headers = await this.getRequestHeader()
    return this.httpClient
      .get(`${this.baseURL}/${addressId}`, {
        headers
      })
      .then(({ data }) => data)
  }

  //for InstantInk address, call Comfe api
  async getInstantInkAddressItemData(
    addressId: string
  ): Promise<ShippingAddress> {
    const headers = await this.getRequestHeader()
    return this.httpClient
      .get(`${this.baseURL}/${addressId}`, {
        headers
      })
      .then(({ data }) => this.formatInstantInkAddressData(data))
  }

  // POST: Create a new HPOne address, resourceId: must be empty
  async addHPOneAddress(
    address: Omit<ShippingAddress, 'resourceId'>
  ): Promise<ShippingAddress> {
    const headers = await this.getRequestHeader()
    return this.httpClient
      .post<ShippingAddress>(`${this.updateURL}/addresses`, address, {
        headers
      })
      .then(({ data }) => data)
  }

  // POST: Create a new HPOne address, resourceId: must be empty
  async addInstantInkAddress(
    address: Omit<ShippingAddress, 'resourceId'>
  ): Promise<ShippingAddress> {
    const instantInkAddress = this.convertToInstantInkAddress(address)
    const headers = await this.getRequestHeader()
    return this.httpClient
      .post<ShippingAddress>(`${this.updateURL}/addresses`, instantInkAddress, {
        headers
      })
      .then(({ data }) => data)
  }

  // PATCH: Update an existed HP_ONE address, resourceId: not empty
  async updateHPOneAddress(
    address: ShippingAddress,
    skipAddressSuggestion: boolean,
    paperEligibility: boolean
  ): Promise<ShippingAddress> {
    const headers = await this.getRequestHeader()
    const paperParameter = paperEligibility ? '&skipEligibility=false' : ''
    return this.httpClient
      .patch<ShippingAddress>(
        `${this.updateURL}/addresses/${address.resourceId}?skipAddressSuggestion=${skipAddressSuggestion}${paperParameter}`,
        address,
        {
          headers
        }
      )
      .then(({ data }) => data)
  }

  // PATCH: Update an existed InstantInk address, resourceId: not empty
  async updateInstantInkAddress(
    address: ShippingAddress
  ): Promise<ShippingAddress> {
    const instantInkAddress = this.convertToInstantInkAddress(address)
    const headers = await this.getRequestHeader()
    return this.httpClient
      .patch<ShippingAddress>(
        `${this.updateURL}/${address.resourceId}`,
        instantInkAddress,
        {
          headers
        }
      )
      .then(({ data }) => data)
  }

  //Get all HPOne Addresses
  async getAllHPOneAddressesData(): Promise<ShippingAddress[]> {
    const headers = await this.getRequestHeader()
    return this.httpClient
      .get(`${this.baseURL}`, {
        headers
      })
      .then(({ data }) => data?.resourceList)
  }

  //Get all InstantInk Addresses
  async getAllInstantInkAddressesData(): Promise<ShippingAddress[]> {
    const headers = await this.getRequestHeader()
    return this.httpClient
      .get(`${this.baseURL}`, {
        headers
      })
      .then(({ data }) =>
        data?.map((address) => this.formatInstantInkAddressData(address))
      )
  }

  getRequestHeader = async () => {
    const stratusToken = await this.authProvider.getAccessToken()
    return {
      accept: 'application/json',
      authorization: `Bearer ${stratusToken}`
    }
  }

  private formatInstantInkAddressData(data: InstantInkShippingAddress) {
    const shippingAddress: ShippingAddress = {
      ...data,
      resourceId: data.id,
      address: data.street1,
      address2: data.street2,
      postalCode: data.zipCode,
      phoneNumber: data.phoneNumber1,
      bisPrimary: data.isDefaultAddress
    }
    return shippingAddress
  }

  private convertToInstantInkAddress(data: ShippingAddress) {
    const instantInkShippingAddress: InstantInkShippingAddress = {
      id: data.resourceId,
      street1: data.address,
      street2: data.address2,
      zipCode: data.postalCode,
      phoneNumber1: data.phoneNumber,
      isDefaultAddress: data.bisPrimary,
      tenantClassification: 'CONSUMER',
      source: 'stratus',
      firstName: data.firstName,
      lastName: data.lastName,
      city: data.city,
      countryCode: data.countryCode,
      company: data.company,
      state: data.state
    }
    return instantInkShippingAddress
  }
}
