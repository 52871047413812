import getSubscriptionItemImage from '@/helpers/getSubscriptionItemImage'
import React from 'react'
import {
  Container,
  ImageContainer,
  StyledSubscriptionContainer,
  StyledTitle
} from './styles'

export type SubscriptionItemProps = {
  subscriptionType?: string
  subscriptionIds?: string[]
  isDisabled?: boolean
  title?: string
}

const SubscriptionItem = (props: SubscriptionItemProps) => {
  const isDisabled = props.isDisabled
  const title = props.title
  const image = getSubscriptionItemImage(
    props.subscriptionType,
    isDisabled ? 'DISABLED' : 'DEFAULT'
  )
  const subscriptionIdList = props.subscriptionIds
    ? props.subscriptionIds.join(', ')
    : null
  const subscriptionIdContent = subscriptionIdList ? (
    <>
      <StyledTitle isDisabled={isDisabled}>{title}</StyledTitle>
      <StyledSubscriptionContainer
        data-testid="subscriptionIdsTestId"
        isDisabled={isDisabled}
      >
        {subscriptionIdList}
      </StyledSubscriptionContainer>
    </>
  ) : null
  return (
    <>
      <Container data-testid="subscriptionTestId">
        <ImageContainer isDisabled={isDisabled}>
          <img src={image} alt="HP All In Plan" />
        </ImageContainer>
        {subscriptionIdContent}
      </Container>
    </>
  )
}

export default SubscriptionItem
