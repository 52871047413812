import React, { useEffect, useRef } from 'react'
import { SelectInterface, SelectOption } from '@veneer/core/dist/scripts/select'
import { VeneerSelect } from './styles'
import { useField } from 'formik'

type ShippingSelectProps = {
  inputName: string
  errorMessage: string
} & SelectInterface
const ShippingSelect = ({
  clearIcon,
  onSearch,
  options,
  label,
  inputName,
  id,
  errorMessage,
  disabled,
  ...otherProps
}: ShippingSelectProps) => {
  const [field, meta, helpers] = useField(inputName)
  const inputRef = useRef(null)
  const handleChange = (option: SelectOption) => {
    helpers.setValue(option.value)
  }
  const handleClear = () => {
    helpers.setValue('', false)
  }
  useEffect(() => {
    if (inputRef.current?.node && meta.error)
      inputRef.current?.node.scrollIntoView({
        behavior: 'smooth',
        block: 'end'
      })
  }, [meta.error])
  const hasError = meta.touched && !!meta.error && !!errorMessage

  return (
    <VeneerSelect
      {...otherProps}
      clearIcon={clearIcon}
      ref={inputRef}
      onSearch={onSearch}
      options={options}
      id={id}
      label={label}
      placement="bottom"
      helperTextVisibility="auto"
      required
      error={hasError}
      helperText={errorMessage}
      onChange={handleChange}
      onClear={handleClear}
      value={[field.value]}
      disabled={disabled}
    />
  )
}

export default ShippingSelect
