import styled from 'styled-components'
import tokens from '@veneer/tokens'
interface StyledExpStatus {
  expirationStatus?: boolean
}

const statusToColorMap = {
  about_to_expire: '#b2600c',
  expired: '#CC2C00'
}
export const PaymentIconInfo = styled.div<StyledExpStatus>`
  && {
    color: ${(props) => statusToColorMap[props.expirationStatus]};
  }
  .iconInfo {
    position: relative;
    top: 3px;
    color: ${(props) => statusToColorMap[props.expirationStatus]};
  }
`

export const CCImageContainer = styled.div`
  margin-bottom: ${tokens.layout.size1};
  && {
    img {
      width: 40px;
      height: ${tokens.layout.size7};
    }
  }
`
export const CCInfoText = styled.div`
  && {
    font-size: ${tokens.typography.size1};
    line-height: ${tokens.typography.lineHeight2};
    color: ${tokens.color.gray12};
    margin-bottom: ${tokens.layout.size1};
    align-items: center;
  }
`

export const GPayPayment = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`
