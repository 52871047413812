import React, { useEffect, useRef, useState } from 'react'
import { ShippingForm as ShippingHPOneForm } from '@monetization/shipping-react'
import useShellContext from '@/hooks/useShellContext'
import getLocalization from '@/helpers/getLocalization'
import getAuthProvider from '@/helpers/getAuthProvider'
import useGetText from '@/hooks/useGetText'
import Images from '../../../assets/images'
import Card from '@veneer/core/dist/scripts/card'
import SubscriptionItem from '@/components/SubscriptionItem'
import { ShippingAddress } from '@/types/ShippingAddress'
import { ShippingForm as ShippingInstantInkForm } from '@jarvis/react-shipping'
import { setInlineNotification } from '@/actions/Actions'
import { useAppContext } from '@/context/AppContext'
import { ErrorNotification } from '@/utils/ErrorNotification'
import useCriticalScopes from '@/hooks/useCriticalScopes/useCriticalScopes'
import SecuritySessionExpiredModal from '@/components/SecuritySessionExpiredModal/SecuritySessionExpiredModal'
import { redirectUrl } from '@/utils/redirectUrl'

import {
  IconWrapper,
  ModalContentContainer,
  StyledAddressItemContainer,
  StyledButtonContainer,
  StyledContent,
  StyledDisabled,
  StyledIconContainer,
  StyledModal,
  StyledTitle,
  StyledAddButtonContainer
} from './styles'

import getSubscriptionLineItemEntityType from '@/helpers/getSubscriptionLineItemEntityType'
import { SubscriptionType } from '@/types/SubscriptionType'
import {
  EditShippingCancelButtonClicked,
  EditShippingClicked,
  EditShippingModuleDisplayed,
  EditShippingSaveButtonClicked,
  publishEvent,
  ShippingAddressesModuleDisplayed
} from '@/utils/analytics'
import { IconChevronRight } from '@veneer/core/dist/scripts/icons'
import useWarningType from '@/hooks/useWarningType'
import getSubscriptionTitleTextId from '@/helpers/getSubscriptionTitleTextId'
import useAccount from '@/hooks/useAccount'

export type AdressInfoProps = React.PropsWithChildren<{
  shippingAddress: ShippingAddress
  friendlysubscriptionId: string
  subscriptionId: string
  entityType: string
  enableEdit?: boolean
  onAddressSaved: () => void
  isDisabled?: boolean
  cloudId?: string
  addressDisabled?: boolean
  paperEligibility?: boolean
}>

export const instankInkURLProvider = async () =>
  `${process.env.INSTANTINK_BASE_URL_PROVIDER}/instantink/v1/commerce`

export default function AddressInfo({
  shippingAddress,
  friendlysubscriptionId,
  subscriptionId,
  entityType,
  enableEdit = true,
  onAddressSaved,
  isDisabled,
  cloudId,
  addressDisabled,
  paperEligibility
}: AdressInfoProps) {
  const [isOpen, setOpenModal] = useState(false)
  const [customErr, setCustomErr] = useState(false)
  const getText = useGetText('shipping')
  const { dispatch } = useAppContext()
  const shell = useShellContext()
  const account = useAccount().info
  const { language } = getLocalization()
  const authProvider = getAuthProvider()
  const getTextSub = useGetText('subscriptions')
  const subscriptionContent = entityType && (
    <SubscriptionItem
      subscriptionType={entityType}
      subscriptionIds={[friendlysubscriptionId]}
      isDisabled={isDisabled}
      title={getTextSub(getSubscriptionTitleTextId(entityType))}
    />
  )
  const translatedEntityType = getSubscriptionLineItemEntityType(entityType)
  const country = account ? account.data?.regionId : process.env.DEFAULT_COUNTRY
  const currLanguage = language || process.env.DEFAULT_LANGUAGE + '_' + country
  const shippingAddressInfo = shippingAddress
  const hponeBaseURLProvider = process.env.SHIPPING_BASE_URL_PROVIDER
  const updateURLProvider = process.env.SHIPPING_BASE_UPDATE_URL_PROVIDER
  const initialRender = useRef(true)
  const {
    error,
    isLoading,
    forceRefresh: forceCriticalScope
  } = useCriticalScopes()

  useEffect(() => {
    publishEvent(ShippingAddressesModuleDisplayed, {
      actionParams: { subscriptionId: subscriptionId },
      entityType: translatedEntityType
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleShippingBtn = async () => {
    if (entityType === SubscriptionType.INSTANT_INK) forceCriticalScope()
    setOpenModal(true)
    publishEvent(EditShippingClicked, {
      actionParams: { subscriptionId: subscriptionId },
      entityType: translatedEntityType
    })
  }

  const onSave = async (err: string) => {
    if (err === 'expired_critical_scope') {
      setOpenModal(true)
      setCustomErr(true)
      return
    }
    publishEvent(EditShippingSaveButtonClicked, {
      entityType: translatedEntityType
    })
    const notification = err
      ? ErrorNotification({ getText })
      : {
          display: true,
          refreshNotification: false,
          title:
            entityType === SubscriptionType.INSTANT_INK
              ? getText('shippingAddressSavedSuccess.instantInkTitle', {
                  defaultValue:
                    'Your Instant Ink shipping information has been saved.'
                })
              : getText('shippingAddressSavedSuccess.hpOneTitle', {
                  defaultValue:
                    'Your HP All-In Plan shipping information has been saved.'
                }),
          status: 'positive' as const
        }
    dispatch(setInlineNotification(notification))
    setOpenModal(false)
    onAddressSaved()

    if (!err && entityType === SubscriptionType.INSTANT_INK) {
      window.sessionStorage.setItem('IsIIShippingSaved', 'true')
    }
  }

  const handleCloseModal = () => {
    publishEvent(EditShippingCancelButtonClicked, {
      entityType: translatedEntityType
    })
    setOpenModal(false)
  }

  const onFormOpen = () => {
    publishEvent(EditShippingModuleDisplayed, {
      entityType: translatedEntityType
    })
  }

  /* istanbul ignore next */
  const handleRemovePaperService = () =>
    redirectUrl(
      `/hp-all-in-print-plan/cancellation/${subscriptionId}?entity=paper`,
      shell.navigation
    )

  /* istanbul ignore next */
  const handleBackToAddress = async () => {
    setOpenModal(true)
  }

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false
      return
    }
    if (error && !isLoading) {
      setOpenModal(true)
    }
  }, [error, isLoading])

  const renderModalContent = (addressId) => {
    if (
      (customErr || (error && !isLoading)) &&
      entityType === SubscriptionType.INSTANT_INK
    ) {
      return (
        <SecuritySessionExpiredModal show={isOpen} onClose={handleCloseModal} />
      )
    } else {
      return (
        <ModalContentContainer>
          {isOpen &&
            (entityType === SubscriptionType.INSTANT_INK ? (
              <ShippingInstantInkForm
                data-testid="editInstantInkShippingFormId"
                country={country}
                language={currLanguage}
                baseURLProvider={instankInkURLProvider}
                authProvider={authProvider}
                onSave={(err: string) => onSave(err)}
                onCancel={handleCloseModal}
                stack={shell?.stack}
                cloudId={cloudId}
                saveButtonText={getText('save')}
                hideTitle={false}
                secondaryButton={null}
              />
            ) : (
              <ShippingHPOneForm
                data-testid="editShippingFormId"
                country={country}
                language={currLanguage}
                subscriptionType={entityType}
                baseURLProvider={hponeBaseURLProvider}
                updateURLProvider={updateURLProvider}
                authProvider={authProvider}
                onSave={(err: string) => onSave(err)}
                onCancel={handleCloseModal}
                stack={shell?.stack}
                addressId={addressId}
                onOpen={onFormOpen}
                paperEligibility={paperEligibility}
                removePaperService={handleRemovePaperService}
                backToAddress={handleBackToAddress}
              />
            ))}
        </ModalContentContainer>
      )
    }
  }
  const modalContent = (addressId?) => (
    <StyledModal
      data-testid="modal"
      closeButton
      show={isOpen}
      onClose={handleCloseModal}
      closeOnBlur={false}
    >
      {renderModalContent(addressId)}
    </StyledModal>
  )
  const containResourceId = Boolean(shippingAddressInfo?.resourceId)
  useWarningType(containResourceId ? 'noWarning' : 'shippingWarning')

  const addressButtonActionLabel = containResourceId ? (
    getText('editShippingAddress')
  ) : (
    <>
      {getText('addShippingAddress')}
      <IconChevronRight
        size={20}
        customStyle={{
          marginLeft: '5px',
          color: '#0278ab',
          verticalAlign: 'middle'
        }}
      />
    </>
  )

  const actionBtn =
    enableEdit && !isDisabled ? (
      <StyledAddButtonContainer isDisabled={addressDisabled}>
        <a
          role="button"
          data-testid={`editshippingButton_${shippingAddressInfo?.resourceId}`}
          onClick={handleShippingBtn}
          tabIndex={0}
          id={`edit-shipping-address-${entityType}`}
        >
          {addressButtonActionLabel}
        </a>
      </StyledAddButtonContainer>
    ) : (
      <StyledDisabled isDisabled={isDisabled}>
        {getText('editShippingAddress')}
      </StyledDisabled>
    )

  const getAddressIcon = (): JSX.Element => {
    return isDisabled ? (
      <img src={Images.shipping_gray} alt="title icon" />
    ) : (
      <img src={Images.shipping} alt="title icon" />
    )
  }

  const getEmptyAddressContent = (): JSX.Element => {
    return (
      <StyledContent data-testid="emptyAddressContent" isDisabled={isDisabled}>
        <img src={Images.shipping} alt="title icon" />
        <p>{getText('noShipping')}</p>
      </StyledContent>
    )
  }

  const cardContent = (
    <>
      <StyledAddressItemContainer data-testid="addressItem">
        {subscriptionContent}
        <div>
          <IconWrapper>
            <StyledIconContainer>
              {containResourceId && getAddressIcon()}
            </StyledIconContainer>
          </IconWrapper>
          <div>
            <StyledTitle isDisabled={isDisabled}>
              {containResourceId && shippingAddressInfo?.fullName}
            </StyledTitle>
            <StyledContent isDisabled={isDisabled}>
              {containResourceId && shippingAddressInfo?.address} <br />
              {containResourceId && shippingAddressInfo?.address2 ? (
                <span>{shippingAddressInfo?.address2}</span>
              ) : (
                <></>
              )}
            </StyledContent>
            <StyledContent isDisabled={isDisabled}>
              {containResourceId && (
                <span>
                  {shippingAddressInfo?.city}, {shippingAddressInfo?.state}{' '}
                  {shippingAddressInfo?.postalCode}
                </span>
              )}
            </StyledContent>
            <StyledButtonContainer>
              {!containResourceId && getEmptyAddressContent()}
              {actionBtn}
            </StyledButtonContainer>
          </div>
        </div>
      </StyledAddressItemContainer>

      {modalContent(shippingAddressInfo?.resourceId)}
    </>
  )

  return (
    <Card
      content={cardContent}
      className="card-box"
      customStyle={{ borderRadius: 8 }}
    />
  )
}
