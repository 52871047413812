import useAppContext from '@/hooks/useAppContext'

const useAllAddressItemData = () => {
  const {
    state: { allAddressItemData: allAddressItemData }
  } = useAppContext()
  return allAddressItemData
}

export default useAllAddressItemData
