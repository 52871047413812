import React, { useEffect, useState } from 'react'
import { SelectOption } from '@veneer/core/dist/scripts/select'
import IconMinusCircle from '@veneer/core/dist/scripts/icons/icon_minus_circle'
import { streetCharacterLimit } from '../../lib/addressValidator'
import addressHelpers from '../../lib/addressHelpers'
import {
  handleAssociateAddressWithPrinter,
  handleSaveOrUpdateShippingDataAction,
  saveFieldErrorCodeAction,
  setDefaultAddressAction,
  setFieldsAction,
  setTextMessageOptInAction,
  validateFieldAndCacheShippingDataAction
} from '../../actions'
import { SuggestedAddressModal } from '../SuggestedAddressModal'
import { UnsupportedPostalCodeModal } from '../UnsupportedPostalCodeModal'
import { FieldErrorCodes, FormErrorCodes } from '../../constants/shippingForm'
import {
  useDispatch,
  useGetText,
  useOnCancel,
  useSettingsData,
  useShippingData,
  useShippingFormState,
  useStateOptions,
  useAssetsProvider,
  useOnSaveButtonClick,
  useSaveText
} from '../../hooks'
import { Spinner } from '../Spinner'
import { ShippingFormDataField } from '../../types'
import { UnsupportedPaperAddressModal } from '../UnsupportedPaperAddressModal'
import * as Styled from './styles'
import { useTheme } from 'styled-components'
import { isMobile } from '../../lib/screenHelpers'

export const ShippingContent = ({
  newAddressView
}: ShippingContentProps): JSX.Element => {
  const getText = useGetText()
  const saveText = useSaveText()
  const {
    settingsDataLoading,
    errorFields,
    warningFields,
    enableErrors,
    fieldErrorCode,
    formErrorCode,
    cloudId,
    addressId,
    hasExistingAddress,
    addressCount,
    validatedAddress,
    fields,
    trackClickEvent,
    hideTitle,
    secondaryButton
  } = useShippingFormState()
  const { containerSize } = useTheme()
  const assetsProvider = useAssetsProvider()
  const shippingData = useShippingData()
  const settingsData = useSettingsData()
  const dispatch = useDispatch()
  const stateOptions = useStateOptions()
  const onShippingContentCancel = useOnCancel()
  const onSaveButtonClick = useOnSaveButtonClick()
  const language = assetsProvider.getLanguage()
  const country = assetsProvider.getCountry()
  const { supportMultiShipping, allowPoBox, optedIn } = shippingData
  const associationFailed = formErrorCode === FormErrorCodes.ASSOCIATION_FAILED
  const newStratusAddress =
    supportMultiShipping && hasExistingAddress && !addressId && !cloudId
  let forceEmptyAddress = newAddressView || newStratusAddress
  const isDefault =
    !forceEmptyAddress && (shippingData.isDefaultAddress || addressCount <= 1)
  const [textMessageOptIn, setTextMessageOptIn] = useState(optedIn)
  const [isDefaultAddress, setDefaultAddress] = useState(isDefault)
  const [saving, setSaving] = useState(false)
  const countryString = getText('shipping_form.fields.country')

  useEffect(() => {
    if (!fields.size) {
      const visibleFields = new Set([
        ShippingFormDataField.firstName,
        ShippingFormDataField.lastName,
        ShippingFormDataField.company,
        ShippingFormDataField.street1,
        ShippingFormDataField.street2,
        ShippingFormDataField.phoneNumber1
      ])

      if (stateOptions.length > 0)
        visibleFields.add(ShippingFormDataField.state)
      if (!addressHelpers.hideZip(country))
        visibleFields.add(ShippingFormDataField.zipCode)
      if (addressHelpers.hideCity(country)) {
        shippingData.city = countryString
      } else {
        visibleFields.add(ShippingFormDataField.city)
      }

      ;(async () => await dispatch(setFieldsAction(visibleFields)))()
    }
  }, [fields, stateOptions, countryString, country, shippingData, dispatch])

  useEffect(() => {
    if (forceEmptyAddress) {
      shippingData.street1 = ''
      shippingData.street2 = ''
      shippingData.city = ''
      shippingData.state = ''
      shippingData.zipCode = ''
      shippingData.phoneNumber1 = ''
      shippingData.firstName = ''
      shippingData.lastName = ''
      shippingData.company = ''
      shippingData.id = ''
      shippingData.isDefaultAddress = false
      shippingData.fullName = ''
      shippingData.optedIn = false
    }

    return () => {
      forceEmptyAddress = false
    }
  }, [])

  if (settingsDataLoading) {
    return <Spinner />
  }

  if (settingsData === undefined) {
    return null
  }

  const {
    enableStreetCharacterLimitErrors,
    enableSmbPoBoxExclusion,
    enableTextMessageOptin
  } = settingsData

  const validationOptions = { blockPoBox: !allowPoBox }

  const onChangeInput = (field: string) => async (value: string) => {
    await dispatch(
      validateFieldAndCacheShippingDataAction({
        field,
        value,
        options: validationOptions
      })
    )
  }

  const onFocusInput = (field: string) => async () => {
    await dispatch(
      validateFieldAndCacheShippingDataAction({
        field,
        value: shippingData[field],
        options: validationOptions
      })
    )
  }

  const onBlurInput = () => async () => {
    await dispatch(saveFieldErrorCodeAction(null))
  }

  const onChangeSelect = (field: string) => async (option: SelectOption) => {
    await dispatch(
      validateFieldAndCacheShippingDataAction({
        field,
        value: option.value.toString()
      })
    )
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    if (!saving) {
      setSaving(true)
      try {
        if (formErrorCode === FormErrorCodes.ASSOCIATION_FAILED) {
          await dispatch(handleAssociateAddressWithPrinter(shippingData))
        } else {
          await dispatch(setTextMessageOptInAction(textMessageOptIn))
          await dispatch(setDefaultAddressAction(isDefaultAddress))
          await dispatch(handleSaveOrUpdateShippingDataAction())
        }
      } finally {
        setSaving(false)
      }
    }
  }

  const renderSaveButton = () => (
    <Styled.CodeButton
      data-analyticsid="SaveButton"
      data-testid="save-button"
      type="submit"
      appearance="secondary"
      loading={saving}
      onClick={(event) => {
        event.persist()
        trackClickEvent('shipment_information', 'save-button')
        onSaveButtonClick(event)
      }}
      disabled={disableSave()}
      key="save-button"
    >
      {saveText}
    </Styled.CodeButton>
  )

  const renderCancelButton = () => {
    const onCancel = () => {
      trackClickEvent('shipment_information', 'cancel-button')
      onShippingContentCancel()
    }

    return (
      <Styled.CodeButton
        onClick={onCancel}
        $secondaryButton={secondaryButton}
        appearance="secondary"
        data-testid="cancel-button"
        key="cancel-button"
      >
        {getText('shipping_form.generic_error_handler.cancel')}
      </Styled.CodeButton>
    )
  }

  const renderSecondaryButton = () => {
    if (secondaryButton)
      return <Styled.SecondaryButton>{secondaryButton}</Styled.SecondaryButton>
  }

  const renderRightButtons = () => (
    <Styled.RightButtons key="right-buttons">
      {renderSecondaryButton()}
      {renderSaveButton()}
    </Styled.RightButtons>
  )

  const renderButtons = () => {
    const buttons = []

    if (onShippingContentCancel) {
      buttons.push(renderCancelButton())
    }

    buttons.push(renderRightButtons())

    return (
      <Styled.ShippingButtons spaceBetween={onShippingContentCancel}>
        {buttons}
      </Styled.ShippingButtons>
    )
  }

  const showErrorForField = (field: string) =>
    (errorFields.has(field) || warningFields.has(field)) &&
    ((shippingData[field] && shippingData[field].length > 0) || enableErrors)

  const renderFirstName = () => (
    <Styled.InputField
      id="first-name"
      name="firstName"
      key="firstName"
      label={getText('shipping_form.fields.first_name')}
      data-testid="first-name"
      value={shippingData.firstName}
      onChange={onChangeInput('firstName')}
      error={showErrorForField('firstName')}
      disabled={associationFailed}
      required
    />
  )

  const renderLastName = () => (
    <Styled.InputField
      id="last-name"
      name="lastName"
      key="lastName"
      label={getText('shipping_form.fields.last_name')}
      data-testid="last-name"
      value={shippingData.lastName}
      onChange={onChangeInput('lastName')}
      error={showErrorForField('lastName')}
      disabled={associationFailed}
      required
    />
  )

  const renderStreet = () => (
    <React.Fragment key="street">
      <Styled.InputField
        id="street1"
        name="address1"
        key="street1"
        label={getText('shipping_form.fields.street1')}
        data-testid="street1"
        value={shippingData.street1}
        maxLength={streetCharacterLimit + 1}
        onChange={onChangeInput('street1')}
        onFocus={onFocusInput('street1')}
        onBlur={onBlurInput()}
        error={showErrorForField('street1')}
        disabled={associationFailed}
        required
      />
      <Styled.InputField
        id="street2"
        name="address2"
        key="street2"
        label={getText('shipping_form.fields.street2')}
        data-testid="street2"
        value={shippingData.street2 || ''}
        maxLength={streetCharacterLimit + 1}
        onChange={onChangeInput('street2')}
        onFocus={onFocusInput('street2')}
        onBlur={onBlurInput()}
        error={showErrorForField('street2')}
        disabled={associationFailed}
      />
    </React.Fragment>
  )

  const renderCity = () => {
    if (fields.has(ShippingFormDataField.city)) {
      return (
        <Styled.InputField
          id="city"
          name="city"
          key="city"
          label={getText('shipping_form.fields.city')}
          data-testid="city"
          value={shippingData.city}
          onChange={onChangeInput('city')}
          error={showErrorForField('city')}
          disabled={associationFailed}
          required
        />
      )
    }
  }

  const renderZip = () => {
    if (fields.has(ShippingFormDataField.zipCode)) {
      return (
        <Styled.InputField
          id="zip-code"
          name="zipCode"
          key="zip-code"
          label={getText('shipping_form.fields.zip')}
          data-testid="zip-code"
          value={shippingData.zipCode}
          onChange={onChangeInput('zipCode')}
          error={showErrorForField('zipCode')}
          disabled={associationFailed}
          required={country !== 'CN'}
        />
      )
    }
  }

  const renderCountry = () => (
    <Styled.InputField
      id="country"
      name="country"
      key="country"
      data-testid="country"
      label={getText('shipping_form.fields.country_label')}
      disabled={true}
      value={countryString}
      readOnly
    />
  )

  const renderStateDropdown = () => {
    if (fields.has(ShippingFormDataField.state)) {
      return (
        <Styled.StateDropdown
          id="state"
          label={getText('shipping_form.fields.state')}
          i18n={{
            clear: 'Clear',
            noResults: 'No results found',
            open: getText('state_dropdown.open_tooltip'),
            searchPlaceholder: 'Search Items',
            selected: 'Selected',
            showingResult: 'Showing %s result',
            showingResults: 'Showing %s results',
            unselected: 'Unselected'
          }}
          key="state"
          options={stateOptions}
          value={[shippingData.state]}
          visibleOptions={7}
          onChange={onChangeSelect('state')}
          error={showErrorForField('state')}
          clearIcon={false}
          data-testid="state"
          required
          disabled={associationFailed}
        />
      )
    }
  }

  const onChangeCheckbox =
    (trackerId: string, checkboxStateSetter: (prev) => void) => () => {
      trackClickEvent('shipment_information', trackerId)
      checkboxStateSetter((prev) => !prev)
    }

  const renderTextMessageOptinCheckbox = () => {
    if (enableTextMessageOptin && !supportMultiShipping) {
      return (
        <Styled.TextMessageOptIn
          data-testid="text-message-opt-in"
          label={getText('shipping_form.text_message_opt_in')}
          checked={textMessageOptIn}
          onChange={onChangeCheckbox(
            'text-message-opt-in',
            setTextMessageOptIn
          )}
        />
      )
    }
  }

  const renderDefaultAddressCheckbox = () => {
    if (supportMultiShipping) {
      return (
        <Styled.TextMessageOptIn
          data-testid="default-address-checkbox"
          label={getText('shipping_form.default_address_checkbox')}
          checked={isDefaultAddress}
          disabled={isDefault || associationFailed}
          onChange={onChangeCheckbox(
            'default-address-checkbox',
            setDefaultAddress
          )}
        />
      )
    }
  }

  const renderLeftSection = () => {
    const nameFields = [renderFirstName(), renderLastName()]

    return (
      <Styled.LeftCol>
        {language === 'zh' ? nameFields.reverse() : nameFields}
        <Styled.InputField
          id="company"
          name="companyName"
          label={getText('shipping_form.fields.company')}
          data-testid="company"
          value={shippingData.company || ''}
          onChange={onChangeInput('company')}
          error={showErrorForField('company')}
          disabled={associationFailed}
        />
        <Styled.InputField
          id="phoneNumberSmall"
          name="phoneNumberSmall"
          label={getText('shipping_form.fields.mobile_number')}
          data-testid="phoneNumberSmall"
          value={shippingData.phoneNumber1}
          onChange={onChangeInput('phoneNumber1')}
          error={showErrorForField('phoneNumber1')}
          disabled={associationFailed}
          required
        />
        {isMobile(containerSize) ? renderPhoneNumberReminder() : null}
      </Styled.LeftCol>
    )
  }

  const renderPhoneNumberReminder = () => (
    <Styled.AddPhoneNumber
      dangerouslySetInnerHTML={{
        __html: getText('shipping_form.fields.add_mobile_number_html')
      }}
    />
  )

  const inputGroup = (child1, child2) => {
    if (child1 && !child2) {
      return child1
    } else if (!child1 && child2) {
      return child2
    } else if (child1 || child2) {
      return (
        <Styled.InputGroup key={child1?.key || child2?.key}>
          {child1}
          {child2}
        </Styled.InputGroup>
      )
    }
  }

  const renderRightSection = () => {
    let rows

    if (language === 'zh') {
      if (country === 'CN') {
        rows = [
          inputGroup(renderCountry(), renderStateDropdown()),
          inputGroup(renderCity(), renderZip()),
          renderStreet()
        ]
      } else if (country === 'HK') {
        rows = [renderCountry(), renderStateDropdown(), renderStreet()]
      } else {
        rows = [
          inputGroup(renderCountry(), renderZip()),
          inputGroup(renderCity(), renderStateDropdown()),
          renderStreet()
        ]
      }
    } else {
      rows = [
        renderStreet(),
        inputGroup(renderCity(), renderStateDropdown()),
        inputGroup(renderZip(), renderCountry())
      ]
    }

    return (
      <Styled.RightCol>
        {rows}
        {renderDefaultAddressCheckbox()}
      </Styled.RightCol>
    )
  }

  const renderErrorMessage = () => {
    let message = <></>
    let errorText
    let hasErrors = false
    errorFields.forEach((field) => {
      if (showErrorForField(field)) hasErrors = true
    })

    if (
      fieldErrorCode === FieldErrorCodes.PO_BOX_NOT_ALLOWED &&
      enableSmbPoBoxExclusion
    ) {
      errorText = getText('shipping_form.field_error_messages.po_box')
    } else if (
      fieldErrorCode === FieldErrorCodes.STREET1_CHARACTER_LIMIT &&
      enableStreetCharacterLimitErrors
    ) {
      errorText = getText(
        'shipping_form.field_error_messages.character_limit_next',
        { limit: streetCharacterLimit }
      )
    } else if (
      fieldErrorCode === FieldErrorCodes.STREET2_CHARACTER_LIMIT &&
      enableStreetCharacterLimitErrors
    ) {
      errorText = getText(
        'shipping_form.field_error_messages.character_limit',
        { limit: streetCharacterLimit }
      )
    } else if (hasErrors && !formErrorCode) {
      errorText = getText(
        'shipping_form.form_error_messages.complete_required_fields'
      )
    }

    if (enableErrors) {
      if (formErrorCode === FormErrorCodes.ADDRESS_NOT_FOUND) {
        errorText = getText(
          'shipping_form.form_error_messages.address_not_found'
        )
      } else if (
        formErrorCode === FormErrorCodes.GENERIC_ERROR ||
        associationFailed
      ) {
        errorText = getText('shipping_form.form_error_messages.server_error')
      } else if (errorFields.size > 0) {
        errorText = getText(
          'shipping_form.form_error_messages.complete_required_fields'
        )
      }
    }

    if (errorText) {
      message = (
        <Styled.ErrorMessage>
          <IconMinusCircle size={24} color="red7" filled />
          {errorText}
        </Styled.ErrorMessage>
      )
    }

    return message
  }

  const disableSave = () => {
    if (enableStreetCharacterLimitErrors) {
      if (
        formErrorCode === FormErrorCodes.ADDRESS_NOT_FOUND &&
        validatedAddress === shippingData
      ) {
        return false
      }

      return errorFields.size > 0
    }

    return false
  }

  const renderTitle = () => {
    if (hideTitle) {
      return null
    }

    const mode =
      addressId || (hasExistingAddress && !supportMultiShipping)
        ? 'edit_shipping'
        : 'add_shipping'

    return <h4>{getText(`shipping_form.title.${mode}`)}</h4>
  }

  return (
    <>
      <Styled.ShippingContent
        id="shipping-content-container"
        onSubmit={onSubmit}
      >
        {renderTitle()}
        <Styled.ShippingFields>
          {renderLeftSection()}
          {renderRightSection()}
        </Styled.ShippingFields>
        {isMobile(containerSize) ? null : renderPhoneNumberReminder()}
        {renderTextMessageOptinCheckbox()}
        {renderErrorMessage()}
        {renderButtons()}
      </Styled.ShippingContent>
      <SuggestedAddressModal />
      <UnsupportedPostalCodeModal />
      <UnsupportedPaperAddressModal />
    </>
  )
}

type ShippingContentProps = {
  newAddressView: boolean
}
