import { JarvisAuthProvider } from '@jarvis/web-http'
import AppDispatch from '@/types/AppDispatch'
import AddressInfoClient from '@/services/AddressInfoClient'
import ShippingAppState from '@/types/ShippingAppState'
import {
  setRetrieveAddressItemSuccess,
  setRetrieveAllAddressItemsSuccess,
  setError,
  setIsFetchingFlag,
  resetState,
  setSuggestedAddress,
  setShowSuggestAddressForm,
  setShowUnSupportedAddressForm
} from '@/actions/Actions'
import ShippingAddress from '@/types/ShippingAddress'
import { SubscriptionType } from '@/types/SubscriptionType'
import { AddressErrorCode, HTTP_STATUS_PREFIX } from '@/types/AddressErrorCode'
import sendOpenTelemetryEvent from '@/utils/sendOpenTelemetryEvent'

export const getAddressItem = async (
  addressId: string,
  state: ShippingAppState,
  subscriptionType: string,
  authProvider: JarvisAuthProvider,
  dispatch: AppDispatch,
  baseURLProvider: string,
  updateURLProvider: string
) => {
  const baseURL = baseURLProvider
  const updateURL = updateURLProvider
  const dataService = new AddressInfoClient(authProvider, baseURL, updateURL)

  if (addressId) {
    if (!state.addressItemData && !state.error) {
      try {
        dispatch(setIsFetchingFlag(true))
        let data
        if (subscriptionType === SubscriptionType.INSTANT_INK)
          data = await dataService
            .getInstantInkAddressItemData(addressId)
            .catch((err) => {
              handleAddressError(err)
            })
        else
          data = await dataService
            .getHPOneAddressItemData(addressId)
            .catch((err) => {
              handleAddressError(err)
            })
        dispatch(setRetrieveAddressItemSuccess(data))
      } catch (e) {
        sendOpenTelemetryEvent(
          `Error occurred while fetching getting adress item data: ${JSON.stringify(
            e
          )}`
        )
        dispatch(setError(e.toString()))
      } finally {
        dispatch(setIsFetchingFlag(false))
      }
    }
  } else {
    dispatch(resetState())
  }
}

export const updateAddressItem = async (
  address: ShippingAddress,
  state: ShippingAppState,
  subscriptionType: string,
  authProvider: JarvisAuthProvider,
  dispatch: AppDispatch,
  baseURLProvider: string,
  updateURLProvider: string,
  onSave: () => void,
  skipAddressSuggestion = true,
  paperEligibility: boolean
) => {
  const baseURL = baseURLProvider
  const updateURL = updateURLProvider
  const dataService = new AddressInfoClient(authProvider, baseURL, updateURL)

  if (address) {
    try {
      dispatch(setIsFetchingFlag(true))
      if (address.resourceId) {
        if (subscriptionType === SubscriptionType.INSTANT_INK)
          await dataService.updateInstantInkAddress(address).catch((err) => {
            handleAddressError(err)
          })
        else
          await dataService
            .updateHPOneAddress(
              address,
              skipAddressSuggestion,
              paperEligibility
            )
            .catch((err) => {
              if (
                err?.response?.data?.message ===
                'Address is not eligible for paper'
              ) {
                dispatch(setShowUnSupportedAddressForm(true))
              } else {
                dispatch(setShowUnSupportedAddressForm(false))
              }
              handleAddressError(err)
            })
      } else {
        if (subscriptionType === SubscriptionType.INSTANT_INK)
          await dataService.addInstantInkAddress(address).catch((err) => {
            handleAddressError(err)
          })
        else
          await dataService.addHPOneAddress(address).catch((err) => {
            handleAddressError(err)
          })
      }
      onSave()
      dispatch(setSuggestedAddress(null))
      dispatch(setShowSuggestAddressForm(false))
    } catch (e) {
      handleUpdateAddressCatch(e, address, dispatch)
    } finally {
      dispatch(setIsFetchingFlag(false))
    }
  } else {
    dispatch(setIsFetchingFlag(false))
  }
}

export const getAllAddressItems = async (
  state: ShippingAppState,
  subscriptionType: string,
  authProvider: JarvisAuthProvider,
  dispatch: AppDispatch,
  baseURLProvider: string,
  updateURLProvider: string
) => {
  const baseURL = baseURLProvider
  const updateURL = updateURLProvider
  const dataService = new AddressInfoClient(authProvider, baseURL, updateURL)

  if (!state.addressItemData && !state.error) {
    try {
      dispatch(setIsFetchingFlag(true))
      let data
      if (subscriptionType === SubscriptionType.INSTANT_INK)
        data = await dataService.getAllInstantInkAddressesData()
      else data = await dataService.getAllHPOneAddressesData()
      dispatch(setRetrieveAllAddressItemsSuccess(data))
    } catch (e) {
      sendOpenTelemetryEvent(
        `Error occurred while fetching addresses data: ${JSON.stringify(e)}`
      )
      dispatch(setError(e.toString()))
    } finally {
      dispatch(setIsFetchingFlag(false))
    }
  } else {
    dispatch(resetState())
  }
}

export function handleAddressError(error: any) {
  const responseError = error?.response
  const errorData = responseError?.data
  if (responseError) {
    if (errorData?.suggestedAddress) {
      throw errorData
    } else if (
      Object.values(AddressErrorCode).includes(
        responseError?.data?.errors?.[0]?.code
      )
    ) {
      throw responseError?.data?.errors?.[0]?.code
    } else if (responseError.status) {
      throw (
        AddressErrorCode[HTTP_STATUS_PREFIX.concat(responseError.status)] ||
        AddressErrorCode.GENERIC_ERROR
      )
    }
  } else if (error?.toString().includes('Network Error')) {
    throw AddressErrorCode.NETWORK_ERROR
  } else throw AddressErrorCode.GENERIC_ERROR
}

export const handleUpdateAddressCatch = (
  e: any,
  address: ShippingAddress,
  dispatch: AppDispatch
) => {
  sendOpenTelemetryEvent(
    `Error occurred while adding/updating address:  ${
      e?.suggestedAddress ? e?.message : JSON.stringify(e)
    }`
  )
  if (e?.suggestedAddress) {
    dispatch(
      setSuggestedAddress({
        ...e?.suggestedAddress,
        resourceId: address?.resourceId
      })
    )
    dispatch(setShowSuggestAddressForm(true))
    dispatch(setRetrieveAddressItemSuccess(address))
  } else {
    dispatch(setError(e.toString()))
  }
}
