import bg_BG from './bg_BG.json'
import cs_CZ from './cs_CZ.json'
import da_DK from './da_DK.json'
import de_AT from './de_AT.json'
import de_CH from './de_CH.json'
import de_DE from './de_DE.json'
import el_CY from './el_CY.json'
import el_GR from './el_GR.json'
import en_AU from './en_AU.json'
import en_CA from './en_CA.json'
import en_GB from './en_GB.json'
import en_IE from './en_IE.json'
import en_MT from './en_MT.json'
import en_NZ from './en_NZ.json'
import en_US from './en_US.json'
import es_ES from './es_ES.json'
import et_EE from './et_EE.json'
import fi_FI from './fi_FI.json'
import fr_BE from './fr_BE.json'
import fr_CA from './fr_CA.json'
import fr_CH from './fr_CH.json'
import fr_FR from './fr_FR.json'
import fr_LU from './fr_LU.json'
import hr_HR from './hr_HR.json'
import hu_HU from './hu_HU.json'
import it_CH from './it_CH.json'
import it_IT from './it_IT.json'
import ja_JP from './ja_JP.json'
import ko_KR from './ko_KR.json'
import lt_LT from './lt_LT.json'
import lv_LV from './lv_LV.json'
import nb_NO from './nb_NO.json'
import nl_BE from './nl_BE.json'
import nl_NL from './nl_NL.json'
import pl_PL from './pl_PL.json'
import pt_BR from './pt_BR.json'
import pt_PT from './pt_PT.json'
import ro_MD from './ro_MD.json'
import ro_RO from './ro_RO.json'
import ru_RU from './ru_RU.json'
import sk_SK from './sk_SK.json'
import sl_SI from './sl_SI.json'
import sv_SE from './sv_SE.json'
import tr_TR from './tr_TR.json'
import zh_CN from './zh_CN.json'
import zh_HK from './zh_HK.json'
import zh_TW from './zh_TW.json'
import no_NO from './no_NO.json'
import en_CN from './en_CN.json'
import en_HK from './en_HK.json'
import en_IL from './en_IL.json'
import en_PR from './en_PR.json'
import en_SG from './en_SG.json'
import en_UK from './en_UK.json'

export default {
  bg_BG,
  cs_CZ,
  da_DK,
  de_AT,
  de_CH,
  de_DE,
  el_CY,
  el_GR,
  en_AU,
  en_CA,
  en_GB,
  en_IE,
  en_MT,
  en_NZ,
  en_US,
  es_ES,
  et_EE,
  fi_FI,
  fr_BE,
  fr_CA,
  fr_CH,
  fr_FR,
  fr_LU,
  hr_HR,
  hu_HU,
  it_CH,
  it_IT,
  ja_JP,
  ko_KR,
  lt_LT,
  lv_LV,
  nb_NO,
  nl_BE,
  nl_NL,
  pl_PL,
  pt_BR,
  pt_PT,
  ro_MD,
  ro_RO,
  ru_RU,
  sk_SK,
  sl_SI,
  sv_SE,
  tr_TR,
  zh_CN,
  zh_HK,
  zh_TW,
  no_NO,
  en_CN,
  en_HK,
  en_IL,
  en_PR,
  en_SG,
  en_UK
}
