import { useCallback, useEffect, useState } from 'react'
import useStoreFrontClient from '../useStoreFrontClient'
import InstantInkShippingAddress from 'src/types/InstantInkShippingAddress'

type InstantInkBillingAddressResponse = {
  data: InstantInkShippingAddress
  error: boolean
  isLoading: boolean
  forceRefresh: () => void
}

const useInstantInkBillingAddress = (): InstantInkBillingAddressResponse => {
  const client = useStoreFrontClient()

  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(false)
  const [data, setData] = useState(undefined)

  const getBillingAddressInstantInk = useCallback(async () => {
    setIsLoading(true)
    try {
      const data = await client.getBillingAddressInstantInk()
      setData(data)
    } catch (err) {
      setError(true)
    } finally {
      setIsLoading(false)
    }
  }, [client])

  useEffect(() => {
    getBillingAddressInstantInk()
  }, [getBillingAddressInstantInk])

  return {
    data,
    error,
    isLoading,
    forceRefresh: getBillingAddressInstantInk
  }
}

export default useInstantInkBillingAddress
