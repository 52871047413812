import { useEffect, useState } from 'react'
import { PaymentMethod } from '../../types'
import PaymentMethodClient from '../../services/PaymentMethodClient'
import useSubscriptionInfo from '@/hooks/useSubscriptionInfo'

const usePaymentMethod = (authProvider: any, subscriptionIndex: number) => {
  const { info, forceRefresh: subscriptionInfoRefresh } = useSubscriptionInfo()
  const paymentMethodId = info?.data?.[subscriptionIndex]?.paymentMethodId

  const [data, setData] = useState<PaymentMethod>(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  const fetchPaymentDetails = () => {
    setLoading(true)
    const paymentMethodClient = new PaymentMethodClient(authProvider)
    paymentMethodClient
      .getPaymentMethodsData(paymentMethodId)
      .then((res) => {
        setData(res)
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
        console.error('Error fetching payment details:', err)
        setError(err)
      })
  }

  const forceRefresh = () => {
    subscriptionInfoRefresh()
    fetchPaymentDetails()
  }

  useEffect(() => {
    if (paymentMethodId) {
      fetchPaymentDetails()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentMethodId])

  return { data, loading, error, forceRefresh }
}
export default usePaymentMethod
