const forceLogin = () => {
  if (window?.Portal) {
    window.Portal?.auth?.v2?.forceLogin()
  } else {
    window.Shell.v1.navigation.redirect(
      '/login?prompt=login+consent&postLoginRedirect=%2Fprint_plans'
    )
  }
}

export const AuthProvider = {
  forceLogin: forceLogin
}

export default forceLogin
