import useShellContext from '@/hooks/useShellContext'

const useRootProps = () => {
  const shellContext = useShellContext()
  let language = shellContext.language?.toString().toLowerCase()
  let country = shellContext.country?.toString().toUpperCase()

  language = language ? language : process.env.DEFAULT_LANGUAGE || 'en'
  country = country ? country : process.env.DEFAULT_COUNTRY || 'US'
  return {
    language,
    country,
    subscriptionType: shellContext.subscriptionType,
    addressId: shellContext.addressId,
    authProvider: shellContext.authProvider,
    baseURLProvider: shellContext.baseURLProvider,
    updateURLProvider: shellContext.updateURLProvider,
    onSave: shellContext.onSave,
    onCancel: shellContext.onCancel,
    onOpen: shellContext.onOpen,
    paperEligibility: shellContext.paperEligibility,
    removePaperService: shellContext.removePaperService,
    backToAddress: shellContext.backToAddress
  }
}

export default useRootProps
