export interface PaymentIssue {
  pendingChangeId: string
  subscriptionId: string
  entityId: string
  type: string
  value: Value
  initiator: Initiator
  reason: Reason
  createdAt: string
  updatedAt: string
}

export interface Value {
  state: string
  at: string
}

export interface Initiator {
  type: string
  value: InitiatorValue
}

export interface InitiatorValue {
  clientId: string
}

export interface Reason {
  type: string
  value: ReasonValue
}

export interface ReasonValue {
  attribute_1: string
  attribute_2: string
}

export interface PaginatedResponse {
  version: string
  totalCount: number
  maxPaginationLimit: number
  defaultPaginationLimit: number
  contents: PaymentIssue[]
  links: Link[]
}

interface Link {
  rel: string
  href: string
}

export enum EDelinquencyNotification {
  OVERDUE = 'payment_overdue',
  SUSPENDED = 'suspended',
  INACTIVE = 'no_longer_active',
  EXPIRED = 'expired',
  ABOUT_TO_EXPIRE = 'about_to_expire',
  NONE = 'none'
}
