import { trace } from '@opentelemetry/api'

const sendOpenTelemetryEvent = (message: string) => {
  const span = trace.getTracer('shipping-react').startSpan('shipping-react', {
    attributes: {
      'workflow.name': 'monetization/shipping-react.error',
      error: true,
      'error.message': message
    }
  })
  span.end()
}

export default sendOpenTelemetryEvent
