import styled, { css } from 'styled-components'
import Button from '@veneer/core/dist/scripts/button'

import tokens from '@veneer/tokens'
import { isMobile } from '../../lib/screenHelpers'

export const ButtonsContainer = styled.div`
  margin: 32px 0;
  width: 100%;
  display: flex;
  justify-content: end;

  ${({ theme: { containerSize } }) =>
    isMobile(containerSize) &&
    css`
      flex-direction: column-reverse;
    `}
`

export const AddButton = styled(Button)`
  && {
    margin-right: 16px;

    ${({ theme: { containerSize } }) =>
      isMobile(containerSize) &&
      css`
        margin-top: 16px;
        margin-right: 0;
        white-space: normal;
      `}
  }
`

export const ErrorMessage = styled.span`
  display: inline-flex;
  color: ${tokens.color.red7};
  font-weight: bold;
  margin-right: 16px;
  align-items: center;

  ${({ theme: { containerSize } }) =>
    isMobile(containerSize) &&
    css`
      justify-content: center;
      margin-right: 0;
      margin-top: 16px;
    `}

  svg {
    margin-right: 8px;
  }
`
