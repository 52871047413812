import tokens from '@veneer/tokens'
import styled from 'styled-components'

const { color, layout, typography } = tokens

export const Title = styled.div`
  margin-top: 40px;
  font-size: ${typography.size5};
  font-weight: 400;
  line-height: ${typography.lineHeight5};
  color: #737373;
`

export const SubTitle = styled.p`
  margin-top: ${layout.size1};
  margin-bottom: ${typography.size9};
  font-size: ${typography.size2};
  line-height: ${typography.lineHeight3};
  color: ${color.gray12};
`

export const BillingContainer = styled.div`
  && {
    display: flex;
    flex-wrap: wrap;
    gap: ${tokens.layout.size4};
  }
`
