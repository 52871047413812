import { useEffect, useState } from 'react'
import SettingInfoClient from '../../services/SettingInfoClient'
import sendOpenTelemetryEvent from '@/helpers/sendOpenTelemetryEvent'
import getAuthProvider from '../../helpers/getAuthProvider'

const useSettingsInfo = (invokeFlag: boolean) => {
  const [error, setError] = useState(false)
  const [data, setData] = useState(null)
  const authProvider = getAuthProvider()
  const getSettingsData = async () => {
    const client = new SettingInfoClient(authProvider)
    try {
      const settingsData = await client.getSettingsData()
      if (settingsData) {
        setData(settingsData)
      } else {
        setData(null)
      }
    } catch (error) {
      sendOpenTelemetryEvent(
        'Error while fetching getSettingsData: ' + JSON.stringify(error)
      )
      setError(true)
      setData(null)
    }
  }

  useEffect(() => {
    if (invokeFlag) {
      getSettingsData()
    }
  }, [])

  return {
    data,
    error,
    forceRefresh: getSettingsData
  }
}

export default useSettingsInfo
