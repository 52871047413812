import Modal from '@veneer/core/dist/scripts/modal'
import tokens from '@veneer/tokens'
import styled from 'styled-components'

const { color, layout, typography } = tokens

export const StyledModal = styled(Modal)`
  && {
    .vn-modal__close > span > svg {
      color: ${color.white};
      background-color: ${color.gray9};
      border-radius: 50%;
      padding: 2px;
    }

    .vn-modal--content {
      padding: ${layout.size0};
      max-width: 750px;
    }
  }

  @media (max-width: ${layout.mdMax}) {
    .vn-modal--content {
      width: 75%;
    }
  }

  @media (max-width: ${layout.smMin}) {
    .vn-modal--content {
      width: 90%;
    }
  }
`

export const ImageBlock = styled.div`
  > img {
    width: 100%;
  }
`

export const ModalBody = styled.div`
  padding: 40px 48px;

  @media (max-width: ${layout.smMin}) {
    padding: ${layout.size8} ${layout.size4};
  }
`

export const Title = styled.p`
  color: ${color.gray12};
  font-size: ${typography.size7};
  line-height: ${typography.lineHeight7};
  margin-bottom: ${layout.size6};
  padding: ${layout.size0};

  @media (max-width: ${layout.smMin}) {
    font-size: ${typography.size5};
    line-height: ${typography.lineHeight5};
  }
`

export const Description = styled.p`
  color: ${color.gray12};
  font-size: ${typography.size2};
  line-height: ${typography.lineHeight3};
  margin-bottom: ${layout.size6};

  @media (max-width: ${layout.smMin}) {
    font-size: ${typography.size1};
    line-height: ${typography.lineHeight2};
  }
`

export const CheckboxBlock = styled.div`
  display: flex;
  align-items: center;
  gap: ${layout.size3};
  margin-bottom: ${layout.size6};
`

export const CheckboxText = styled.p`
  color: ${color.gray12};
  font-size: ${typography.size2};
  line-height: ${typography.lineHeight2};

  @media (max-width: ${layout.smMin}) {
    font-size: ${typography.size0};
    line-height: ${typography.lineHeight1};
  }
`

export const LinkBlock = styled.div`
  display: flex;
  gap: ${layout.size4};
  margin-bottom: ${layout.size4};

  @media (max-width: ${layout.smMin}) {
    flex-direction: column;
  }
`

export const LinkText = styled.a`
  color: ${color.hpBlue7};
  font-size: ${typography.size2};
  line-height: ${typography.lineHeight2};
  cursor: pointer;
`

export const List = styled.div`
  margin-bottom: ${layout.size6};
`

export const ListText = styled.p`
  color: ${color.gray8};
  font-size: ${typography.size0};
  line-height: ${typography.lineHeight1};
`

export const ButtonBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: ${layout.size4};

  @media (max-width: ${layout.smMin}) {
    flex-direction: column-reverse;
    > button {
      width: 100%;
    }
  }
`
