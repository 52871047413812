import styled from 'styled-components'
import tokens from '@veneer/tokens'

export const StyledAddressItemContainer = styled.div`
  height: 260px;
  min-height: 260px;

  && {
    padding: ${tokens.layout.size4};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: ${tokens.layout.cornerRadius4};
    background-color: ${tokens.color.white};
  }
`
export const ShippingAddressItemContainer = styled.div`
  && {
    width: calc(50% - 16px);
    font-family: ${tokens.typography.family0};
    font-size: ${tokens.typography.size2};
    font-weight: 400;

    && {
      @media only screen and (max-width: ${tokens.layout.smMax}) {
        width: 100%;
      }
    }

    > div {
      border-radius: ${tokens.layout.cornerRadius4};
    }
  }
`
