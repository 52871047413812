import SubscriptionItem from '@/components/SubscriptionItem'
import getAuthProvider from '@/helpers/getAuthProvider'
import getLocalization from '@/helpers/getLocalization'
import useBillingInfo from '@/hooks/useBillingInfo'
import useGetText from '@/hooks/useGetText'
import useShellContext from '@/hooks/useShellContext'
import { SubscriptionType } from '@/types/SubscriptionType'
import getSubscriptionTitleTextId from '@/helpers/getSubscriptionTitleTextId'
import HandleTaxInfo from '@/components/Billing/HandleTaxInfo'
import { setInlineNotification, setIIBillingForm } from '@/actions/Actions'
import { instantInkStates } from '@/utils/instantInkStates'
import { ErrorNotification } from '@/utils/ErrorNotification'
import useSettingsInfo from '@/hooks/useSettingsInfo/useSettingsInfo'
import {
  BillingModalWindowCancelBtnClicked,
  BillingModalWindowContinueBtnClicked,
  BillingModalWindowDisplayed,
  BillingModuleDisplayed,
  EditPaymentLinkClicked,
  publishEvent
} from '@/utils/analytics'
import { BillingForm } from '@jarvis/react-billing'
import Card from '@veneer/core/dist/scripts/card'
import React, { useContext, useEffect, useState, useRef } from 'react'
import { PaymentMethodType } from '../../../types/PaymentMethodType'
import {
  ModalContentContainer,
  StyledEditButtonContainer,
  StyledModal
} from './styles'
import CreditCard from '../CreditCard'
import { useAppContext } from '@/context/AppContext'
import useCookie from '@/hooks/useCookie/useCookies'
import PayPal from '../PayPal'
import { WarningContext } from '@/context/WarningContext'
import useTaxId from '@/hooks/useTaxId/useTaxId'
import { BillingCard, BillingCardWrapper } from '../styles'
import useCriticalScopes from '@/hooks/useCriticalScopes/useCriticalScopes'
import SecuritySessionExpiredModal from '@/components/SecuritySessionExpiredModal'
import useInstantInkBillingAddress from '@/hooks/useInstantInkBillingAddress/useInstantInkBillingAddress'
import getBorderStyle from '@/helpers/getBorderStyle'
import Skeleton from '@/components/Skeleton'
import { useFlags } from 'launchdarkly-react-client-sdk'

const PaymentMethod = () => {
  const isAgentSession = useCookie('is_agent_session')

  const [customErr, setCustomErr] = useState(false)
  const { setWarningType } = useContext(WarningContext)
  const getText = useGetText('billing')
  const getTextSub = useGetText('subscriptions')
  const title = getTextSub(
    getSubscriptionTitleTextId(SubscriptionType.INSTANT_INK)
  )
  const shell = useShellContext()
  const { enableDebug } = useFlags()
  const authProvider = getAuthProvider()
  const initialRender = useRef(true)
  const [currentBillingModalStep, setCurrentBillingModalStep] =
    useState<number>(1)
  const { state, dispatch } = useAppContext()
  const { instantInkSubscriptionInfo, account } = useAppContext().state
  const {
    error,
    isLoading,
    forceRefresh: forceCriticalScope
  } = useCriticalScopes()
  const {
    data: instantInkBillingAddress,
    isLoading: instantInkBillingAddressFeatch,
    forceRefresh: instantInkBillingAddressForceRefresh
  } = useInstantInkBillingAddress()
  const {
    info: { data: billingInfo, isFetching: instantInkBillingIsFetching },
    forceRefresh
  } = useBillingInfo()
  const { data: enablePaypalInWebview } = useSettingsInfo()
  const isNative = window?.JWeb?.isNative
  const { language } = getLocalization()
  const billingBaseURLProvider = async () =>
    `${process.env.BILLING_BASE_URL_PROVIDER}/instantink/v1/commerce`

  const fullName = instantInkBillingAddress?.fullName

  const country = account ? account.data.regionId : process.env.DEFAULT_COUNTRY
  const currLanguage = language || process.env.DEFAULT_LANGUAGE

  const {
    config: { show },
    data: taxData,
    isLoading: isLoadingTax,
    forceRefresh: forceTaxIdRefresh
  } = useTaxId()

  const deliquencyStatus =
    state.deliquencyNotificationStatus.instantInkDeliquencyStatus
  const cardBoxBorder = getBorderStyle(deliquencyStatus)
  const instantInkState = [
    instantInkStates.SUBSCRIBED_NO_PENS,
    instantInkStates.SUBSCRIBED_NO_PRINTER,
    instantInkStates.SUBSCRIBED,
    instantInkStates.INITIATED_UNSUBSCRIBE,
    instantInkStates.UNSUBSCRIBED
  ]
  const editTaxId =
    !!instantInkSubscriptionInfo?.data?.instantInkSubscriptions?.find(
      ({ state }) => instantInkState.includes(state as instantInkStates)
    )
  useEffect(() => {
    publishEvent(
      { ...BillingModuleDisplayed, screenMode: 'InstantInk' },
      {
        actionParams: { subscriptionId: subscriptionIds.join('+') }
      }
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (
      billingInfo &&
      !billingInfo?.paymentType &&
      instantInkSubscriptionInfo?.data?.instantInkSubscriptions.length > 0
    ) {
      setWarningType('billingWarning')
    } else if (
      billingInfo?.paymentType &&
      instantInkSubscriptionInfo?.data?.instantInkSubscriptions.length > 0
    ) {
      setWarningType('noWarning')
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billingInfo, instantInkSubscriptionInfo])

  const editBillingClick = async () => {
    if (instantInkSubscriptionInfo?.data?.instantInkSubscriptions?.length > 0)
      forceCriticalScope()
    dispatch(setIIBillingForm(!state.iiBillingForm.isModalOpen))
    publishEvent(
      { ...EditPaymentLinkClicked, screenMode: 'InstantInk' },
      { actionParams: { subscriptionId: subscriptionIds.join('+') } }
    )
    publishEvent({
      ...BillingModalWindowDisplayed,
      screenName: `Billing1`,
      screenMode: 'InstantInk'
    })
  }

  const handleCloseModal = () => {
    forceRefresh()
    forceTaxIdRefresh()
    if (instantInkSubscriptionInfo?.data?.instantInkSubscriptions?.length > 0)
      forceCriticalScope()
    instantInkBillingAddressForceRefresh()
    dispatch(setIIBillingForm(false))
    publishEvent({
      ...BillingModalWindowCancelBtnClicked,
      screenName: `Billing${currentBillingModalStep}`,
      screenMode: 'InstantInk'
    })
    setCurrentBillingModalStep(1)
  }

  const handleSessionModalClose = () => {
    dispatch(setIIBillingForm(false))
    setCurrentBillingModalStep(1)
  }

  const handleUpdate = () => {
    publishEvent({
      ...BillingModalWindowContinueBtnClicked,
      screenName: `Billing1`,
      screenMode: 'InstantInk'
    })
    publishEvent({
      ...BillingModalWindowDisplayed,
      screenName: `Billing2`,
      screenMode: 'InstantInk'
    })
    setCurrentBillingModalStep(2)
  }

  const onSave = async (err: string) => {
    if (err === 'expired_critical_scope') {
      dispatch(setIIBillingForm(true))
      setCustomErr(true)
      return
    }
    dispatch(setIIBillingForm(false))
    const notification = err
      ? ErrorNotification({ getText })
      : {
          display: true,
          refreshNotification: true,
          title: getText('billingSavedSuccess.instantInkTitle', {
            defaultValue: 'Your Instant Ink payment method has been saved.'
          }),
          status: 'positive' as const
        }

    if (!err) {
      sessionStorage.setItem('isIIBillingSaved', 'true')
    }
    dispatch(setInlineNotification(notification))
    publishEvent({
      ...BillingModalWindowContinueBtnClicked,
      screenName: `Billing2`,
      screenMode: 'InstantInk'
    })
    forceRefresh()
    forceTaxIdRefresh()
    instantInkBillingAddressForceRefresh()
  }

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false
      return
    }

    if (error && !isLoading) {
      dispatch(setIIBillingForm(true))
    }
  }, [dispatch, error, isLoading])

  const activeSubscriptions =
    instantInkSubscriptionInfo?.data?.instantInkSubscriptions?.filter(
      (subscription) => subscription.state !== instantInkStates.OBSOLETE
    )

  const subscriptionIds = activeSubscriptions?.map(
    (item) => item.accountIdentifier
  )

  const editPaymentMethodDisabled = subscriptionIds?.length === 0
  const skeletonLoading =
    instantInkBillingIsFetching ||
    isLoadingTax ||
    instantInkBillingAddressFeatch
  const paymentMethodSubscriptionContent = activeSubscriptions &&
    subscriptionIds && (
      <SubscriptionItem
        subscriptionType={SubscriptionType.INSTANT_INK}
        subscriptionIds={subscriptionIds}
        title={title}
      />
    )

  const paymentMethodDetailsContent = (
    <>
      <BillingCard>
        {enableDebug && (
          <span>
            <>IsJweb Native: {String(isNative)}</>
            <br></br>
            <>enablePaypalInWebview : {String(enablePaypalInWebview)}</>
            <br></br>
          </span>
        )}
        <div>
          {paymentMethodSubscriptionContent}
          <div>
            {billingInfo?.paymentType === PaymentMethodType.pay_pal ? (
              <PayPal userName={fullName} billingInfo={billingInfo} />
            ) : (
              <CreditCard
                userName={fullName}
                billingInfo={billingInfo}
                t={getText}
                expirationStatus={deliquencyStatus}
              />
            )}
            <StyledEditButtonContainer isDisabled={editPaymentMethodDisabled}>
              <a
                role="button"
                data-testid="editbillingButton"
                onClick={editBillingClick}
              >
                {getText('editPayment')}
              </a>
            </StyledEditButtonContainer>
          </div>
        </div>
      </BillingCard>
      {show && (
        <HandleTaxInfo
          taxId={taxData?.taxId}
          nonProfitTaxId={taxData?.nonProfitTaxId}
          allowEditingTaxId={taxData?.allowEditingTaxId}
          forceRefresh={forceTaxIdRefresh}
          editTaxId={editTaxId}
        />
      )}
    </>
  )

  const paymentMethodTileContent = (
    <React.Fragment data-testid="paymentMethodTileContent">
      {billingInfo?.paymentType &&
        !skeletonLoading &&
        paymentMethodDetailsContent}

      {(customErr || (error && !isLoading)) &&
      instantInkSubscriptionInfo?.data?.instantInkSubscriptions?.length > 0 ? (
        <SecuritySessionExpiredModal
          show={state.iiBillingForm.isModalOpen}
          onClose={handleSessionModalClose}
        />
      ) : (
        <StyledModal
          data-testid="modal"
          closeButton
          show={state.iiBillingForm.isModalOpen}
          onClose={handleCloseModal}
          closeOnBlur={false}
        >
          {state.iiBillingForm.isModalOpen ? (
            <ModalContentContainer>
              <BillingForm
                accountIdentifier={'testingId'}
                country={country}
                language={currLanguage}
                baseURLProvider={billingBaseURLProvider}
                authProvider={authProvider}
                mockStratus={false}
                onSave={(err: string) => onSave(err)}
                onCancel={handleCloseModal}
                onUpdate={handleUpdate}
                stack={shell?.stack}
                virtualKeyboard={isAgentSession}
                nativeApp={isNative}
                enablePaypalInNativeApp={enablePaypalInWebview}
              />
            </ModalContentContainer>
          ) : (
            <></>
          )}
        </StyledModal>
      )}
    </React.Fragment>
  )

  return (
    <BillingCardWrapper isDisabled={false}>
      {skeletonLoading ? (
        <Skeleton width="30vw" height="5vh" />
      ) : (
        <Card
          content={paymentMethodTileContent}
          className="card-box"
          customStyle={{ border: `${cardBoxBorder}` }}
        />
      )}
    </BillingCardWrapper>
  )
}

export default PaymentMethod
