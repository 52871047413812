import React, { useEffect, useRef } from 'react'
import TextBox, { TextBoxProps } from '@veneer/core/dist/scripts/text_box'
import { useField } from 'formik'

export type ShippingTextFieldProps = {
  label: string
  defaultValue?: string
  required?: boolean
  errorMessage?: string
  dataTestId?: string
  pattern?: string
  onChange?: (
    value: string,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void
} & TextBoxProps

const ShippingTextField: React.FC<ShippingTextFieldProps> = (props) => {
  const {
    label,
    defaultValue,
    required,
    errorMessage,
    dataTestId,
    value,
    ...otherProps
  } = props
  const inputRef = useRef(null)
  const [field, meta, helpers] = useField(props.name)
  const handleChange = (fieldValue: unknown) => {
    helpers.setValue(fieldValue)
  }
  useEffect(() => {
    if (inputRef.current && meta.error)
      inputRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end'
      })
  }, [meta.error])
  const hasError = meta.touched && !!meta.error
  return (
    <TextBox
      {...otherProps}
      {...field}
      ref={inputRef}
      name={props.name}
      data-testid={dataTestId}
      label={label}
      defaultValue={defaultValue}
      required={required}
      error={hasError}
      helperText={errorMessage}
      onChange={handleChange}
      value={value}
    />
  )
}

export default ShippingTextField
