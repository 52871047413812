import React from 'react'
import {
  StyledIconContainer,
  StyledContent,
  StyledTitleContent
} from './styles'
import Images from '../../../../../../../assets/images'

interface SectionProps {
  instantInkAddress: any
}

const Section = (props: SectionProps) => {
  const { instantInkAddress } = props
  const shippingAddressInfo = instantInkAddress

  return (
    <div>
      <StyledIconContainer>
        <img src={Images.shipping} alt="title icon" />
      </StyledIconContainer>
      <div>
        <StyledTitleContent>{shippingAddressInfo?.fullName}</StyledTitleContent>
        <StyledContent>
          {shippingAddressInfo?.address} <br />
          {shippingAddressInfo?.address2 ? (
            <span>{shippingAddressInfo?.address2}</span>
          ) : (
            <></>
          )}
        </StyledContent>
        <StyledContent>
          <span>
            {shippingAddressInfo?.city}, {shippingAddressInfo?.state}{' '}
            {shippingAddressInfo?.postalCode}
          </span>
        </StyledContent>
      </div>
    </div>
  )
}

export default Section
