import { ExpirationStatus } from '../../../types/SubscriptionInfo'
import moment from 'moment'

export const getExpirationStatus = (
  expirationDate: string
): ExpirationStatus | null => {
  if (!expirationDate) return null

  const formatExpirationDate = moment(
    expirationDate,
    ['MM.YYYY', 'MM/YYYY', 'MM-YYYY', 'YYYY-MM', 'YYYY/MM', 'YYYY.MM'],
    true
  )
  const month = formatExpirationDate.format('MM')
  const year = formatExpirationDate.format('YYYY')
  const expirationMonth = parseInt(month, 10)
  const expirationYear = parseInt(year, 10)
  const currentDay = moment()
  const cardExpiryDate = moment()
    .month(expirationMonth - 1)
    .year(expirationYear)
    .endOf('month')

  const hasExpired = moment(currentDay).isAfter(cardExpiryDate)
  const isAboutToExpire =
    moment(cardExpiryDate).diff(moment(currentDay), 'month', true) <= 2

  const expirationStatus = isAboutToExpire
    ? 'about_to_expire'
    : hasExpired
    ? 'expired'
    : null

  return expirationStatus
}
