import React from 'react'
import { ShellProvider } from '@/context/shellContext'
import { MfePropsType, AppProps } from '@/types/mfeProps'
import AppProvider from '@/context/AppProvider'
import SelectAddress from '../SelectAddress'

type AppPropsType = AppProps & MfePropsType

const SelectAddressContainer = (props: AppPropsType) => {
  return (
    <AppProvider>
      <ShellProvider value={props}>
        <SelectAddress />
      </ShellProvider>
    </AppProvider>
  )
}

export default SelectAddressContainer
