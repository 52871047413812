import useAppContext from '@/hooks/useAppContext'

const useAddressItemData = () => {
  const {
    state: { addressItemData: addressItemData }
  } = useAppContext()
  return addressItemData
}

export default useAddressItemData
