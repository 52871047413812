import React from 'react'
import { BillingCard, CardWrapper, StyledLoader } from './styles'
import Header from './Header'
import BillingAddress from './BillingAddress'
import Footer from './Footer'
import usePaymentMethod from '../../../hooks/usePaymentMethod'
import getCardBorderColor from '../../../helpers/CardBorderColor/cardBorderColor'
import ErrorCard from '../../ErrorCard'
import { LoadingHandler } from '@monetization/hpaip-ui-shared-components'
import useGetText from '../../../hooks/useGetText'
import getLocalization from '../../../helpers/getLocalization'
import useShellContext from '../../../hooks/useShellContext'
import { getExpirationStatus } from '../../../helpers/getExpirationStatus'
import Card from '@veneer/core/dist/scripts/card'

interface HPAIPBillingProps {
  commonProps: any
  isDisabled: boolean
  subscriptionIndex: number
}

const HPAIPBilling = (props: HPAIPBillingProps) => {
  const { commonProps, isDisabled, subscriptionIndex } = props

  const shell = useShellContext()
  const getText = useGetText()

  const hpOneSubscriptionInfos = commonProps?.hpaip?.hpOneSubscriptionInfo
  const accountData = commonProps?.accountData
  const expirationStatus = commonProps?.commonNotificationData?.deliquencyStatus
  const { language } = getLocalization()
  const country = accountData?.regionId || process.env.DEFAULT_COUNTRY
  const localeLanguage = language ? language : process.env.DEFAULT_LANGUAGE

  const {
    data,
    loading,
    forceRefresh: refetchPaymentData
  } = usePaymentMethod(commonProps?.authProvider, subscriptionIndex)

  const expirationDate = data?.payment_method_details?.expiration_date
  const expirationStatusinfo = getExpirationStatus(expirationDate)
  const borderColor = getCardBorderColor(
    expirationStatusinfo || expirationStatus
  )
  const subscriptionFriendlyIds = hpOneSubscriptionInfos?.map(
    (item) => item?.friendlySubscriptionId
  )

  const errorCardContent = (
    <ErrorCard
      hpOneSubtitle={getText('errorScreen.hpOneSubtitle', {
        defaultValue: 'Your HP All-In Plan information did not load.'
      })}
      refetchData={refetchPaymentData}
    />
  )

  const loadingContent = (
    <CardWrapper>
      <BillingCard>
        {loading ? (
          <StyledLoader>
            <LoadingHandler loading={loading} />
          </StyledLoader>
        ) : (
          <Card
            content={errorCardContent}
            className="card-box"
            customStyle={{ borderRadius: 8 }}
          />
        )}
      </BillingCard>
    </CardWrapper>
  )
  const renderContent = () => {
    if (data) {
      return (
        <CardWrapper isDisabled={isDisabled}>
          <BillingCard borderColor={borderColor}>
            <div>
              <div>
                <Header
                  subscriptionFriendlyIds={subscriptionFriendlyIds}
                  isDisabled={isDisabled}
                />
              </div>
              <div>
                <BillingAddress
                  creditCardDetails={data?.payment_method_details}
                  expirationStatus={expirationStatus}
                />
                <Footer
                  authProvider={commonProps?.authProvider}
                  country={country}
                  language={localeLanguage}
                  shell={shell}
                  subscriptionFriendlyIds={subscriptionFriendlyIds}
                  subscriptionId={
                    hpOneSubscriptionInfos?.[subscriptionIndex]?.subscriptionId
                  }
                  isDisabled={isDisabled}
                  commonProps={commonProps}
                  subscriptionIndex={subscriptionIndex}
                />
              </div>
            </div>
          </BillingCard>
        </CardWrapper>
      )
    }

    return loadingContent
  }
  return renderContent()
}

export default HPAIPBilling
