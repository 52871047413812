import styled, { css } from 'styled-components'
import Button from '@veneer/core/dist/scripts/button'
import TextBox from '@veneer/core/dist/scripts/text_box'
import Select from '@veneer/core/dist/scripts/select'
import Checkbox from '@veneer/core/dist/scripts/checkbox'

// import tokens from '@veneer/tokens'
import { isMobile } from '../../lib/screenHelpers'

export const ShippingContent = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  margin: auto;

  h4 {
    margin: 0 0 24px 0;
  }
`

export const AddPhoneNumber = styled.p`
  font-size: 16px;
  margin: 0 0 16px;

  ${({ theme: { containerSize } }) =>
    !isMobile(containerSize) &&
    css`
      max-width: 48%;
    `}
`

export const ShippingFields = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  ${({ theme: { containerSize } }) =>
    isMobile(containerSize) &&
    css`
      width: 100%;
      flex-direction: column;
      align-items: center;
    `}
`

export const LeftCol = styled.div`
  && {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-right: 12px;

    ${({ theme: { containerSize } }) =>
      isMobile(containerSize) &&
      css`
        margin-right: 0;

        p {
          margin-top: 0px;
        }
      `}
  }
`

export const RightCol = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  /*
    ${({ theme: { containerSize } }) =>
    isMobile(containerSize) &&
    css`
      margin-top: 8px;
    `}
     */
`

export const InputField = styled(TextBox)`
  margin: 0 0 12px 0;
  width: 100%;

  > div:first-child {
    margin-top: 0;
  }

  ${({ theme: { containerSize } }) =>
    isMobile(containerSize) &&
    css`
      //> .div:nth-last-child(2) {
      //  margin-bottom: 8px;
      //}
      gap: 16px;
    `}

  > div {
    background-color: white;
  }
`

export const TextMessageOptIn = styled(Checkbox)`
  align-self: flex-start;
  margin: 0 0 16px 0;

  > span {
    font-size: 16px;
  }

  ${({ theme: { containerSize } }) =>
    isMobile(containerSize) &&
    css`
      max-width: 100%;
    `}
`

export const DefaultAddressCheckbox = styled.div`
  font-size: 16px;
`

export const StateDropdown = styled(Select)`
  width: 100%;
  margin-bottom: 16px;

  > div {
    background-color: white;
  }
`

export const InputGroup = styled.div`
  ${({ theme: { containerSize } }) =>
    !isMobile(containerSize) &&
    css`
      width: 100%;
      display: flex;

      div:first-child {
        margin-right: 16px;
      }
    `}
`

export const ErrorMessage = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 16px 0;
  font-size: 16px;

  svg {
    margin-right: 8px;
  }
`

export const ShippingButtons = styled.div`
  margin: 32px 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;

  ${({ theme: { containerSize } }) =>
    isMobile(containerSize) &&
    css`
      width: 100%;
      flex-direction: column-reverse;

      .button:last-child {
        margin-bottom: 16px;
      }
    `}

  ${({ spaceBetween }) =>
    spaceBetween &&
    css`
      justify-content: space-between;
    `}
`

export const RightButtons = styled.div`
  display: inline-flex;
  align-items: center;

  ${({ theme: { containerSize } }) =>
    isMobile(containerSize) &&
    css`
      display: flex;
      flex-direction: column-reverse;
      margin-bottom: 16px;

      > button {
        width: 100%;
      }
    `}
`

export const SecondaryButton = styled.div`
  & button {
    ${({ theme: { containerSize } }) =>
      isMobile(containerSize) &&
      css`
        width: 100%;
      `}
  }

  ${({ theme: { containerSize } }) =>
    isMobile(containerSize) &&
    css`
      margin-top: 16px;
      width: 100%;
    `}

  ${({ theme: { containerSize } }) =>
    !isMobile(containerSize) &&
    css`
      margin-right: 16px;
    `}
`

export const CodeButton = styled(Button)`
  ${({ $secondaryButton }) =>
    $secondaryButton &&
    css`
      margin-top: 16px;
    `}

  ${({ theme: { containerSize } }) =>
    isMobile(containerSize) &&
    css`
      width: 100%;
    `}
`
