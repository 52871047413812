import tokens from '@veneer/tokens'
import styled from 'styled-components'

const { layout } = tokens

export const CCImageContainer = styled.div`
  margin-top: ${layout.size4};
  margin-bottom: ${layout.size1};
  && {
    img {
      width: 40px;
      height: ${layout.size7};
    }
  }
`
export const CCInfoText = styled.div`
  font-size: ${tokens.typography.size1};
  line-height: ${tokens.typography.lineHeight2};
  color: ${tokens.color.gray12};
  margin-bottom: ${tokens.layout.size1};
`
