import styled from 'styled-components'
import tokens from '@veneer/tokens'
import Modal from '@veneer/core/dist/esm/scripts/modal'
const { gray6, gray12, hpBlue7 } = tokens.color
const { lgMin } = tokens.layout
const { cornerRadius4 } = tokens.layout
interface StyledModalProps {
  transition: number
}

export const StyledModal = styled(Modal).attrs((props: StyledModalProps) => ({
  transition: props.transition
}))`
  && {
    .vn-modal--footer {
      padding-top: 0;
      display: block;
    }

    .vn-modal__close > span > svg {
      color: ${gray12};
    }

    .vn-modal__close:focus {
      cursor: pointer;
      border: 2px solid ${hpBlue7};
      border-radius: 5px;
    }

    .vn-modal--content {
      opacity: 1;
      left: 0;
      padding: 0;
      .vn-modal__close > span > svg {
        color: #ffffff;
        background-color: ${gray6};
        opacity: 0.85;
        border-radius: 50%;
      }
      &.transitioning {
        animation: slide ${(props) => `${props.transition * 2}ms`} forwards;
        animation-timing-function: ease-in-out;

        @keyframes slide {
          0% {
            left: 0;
            opacity: 1;
          }
          49% {
            left: -100%;
            opacity: 0;
          }
          50% {
            left: 100%;
            opacity: 0;
          }
          100% {
            left: 0;
            opacity: 1;
          }
        }
      }

      min-width: 270px;
      @media (min-width: ${lgMin}) {
        max-width: 648px;
        min-height: 488px;
      }
    }
  }
`

export const ModalContainer = styled.div`
  display: flex;
  width: max-content;
  flex-direction: column;
  border-radius: 0 0 16px 16px;
  align-items: flex-start;
  min-height: 488px;
  top: 496px;
  left: 258px;
  margin-bottom: 10px;
`

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 488px;
`

export const Header = styled.div`
  border-radius: 0 0 16px 16px;
  max-width: 648px;
  height: 200px;
  > img {
    border-radius: 16px 16px 0 0;
    width: 100%;
  }
`

export const ModalDescription = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 48px 40px 48px;
  height: 100%;
`

export const Title = styled.div`
  font-family: 'Forma DJR UI';
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  margin-bottom: 24px;
`

export const SubTitle = styled.div`
  font-family: 'Forma DJR UI';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 24px;
`

export const PrintingPlan = styled.div``

export const PrintingTitle = styled.div`
  font-family: 'Forma DJR UI';
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin-bottom: 12px;
`

export const PrintingSubTitle = styled.div`
  font-family: 'Forma DJR UI';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 15px;
`

export const ShippingTo = styled.div`
  display: flex;
  flex-direction: column;
`

export const ShippingTitle = styled.div`
  font-family: 'Forma DJR UI';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 10px;
`

export const ShippingDetail = styled.div`
  font-family: 'Forma DJR UI';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`

export const Change = styled.div`
  margin-top: 12px;
  margin-bottom: 20px;
  font-family: 'Forma DJR UI';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: ${hpBlue7};
  cursor: pointer;
`

export const Button = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  font-family: 'Forma DJR UI';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`

export const Done = styled.div`
  display: flex;
  min-width: 112px;
  height: 48px;
  padding: 14px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background-color: ${hpBlue7};
  color: white;
  border-radius: ${cornerRadius4};
  cursor: pointer;
`
