import React from 'react'
import getPaymentCardInfo from '@/helpers/getPaymentCardInfo'
import { IconInfo } from '@veneer/core/dist/scripts/icons'
import { CCImageContainer, CCInfoText } from '../styles'

import { EDelinquencyNotification } from '@/types/pendingChanges'
import { PaymentIconInfo } from './styles'
import { PaymentMethodType } from '@/types/PaymentMethodType'

const CreditCard = ({ userName, billingInfo, t, expirationStatus }) => {
  const cardInfo =
    billingInfo?.paymentType === PaymentMethodType.direct_debit
      ? 'directDebit'
      : billingInfo?.creditCard
  const creditCardInfo = getPaymentCardInfo(cardInfo)

  let expireInfo

  if (expirationStatus === EDelinquencyNotification.ABOUT_TO_EXPIRE) {
    expireInfo = (
      <PaymentIconInfo expirationStatus={expirationStatus}>
        <IconInfo className="iconInfo" size={15} /> {t('expiringSoon')}{' '}
        {billingInfo?.creditCard?.displayExpirationDate}
      </PaymentIconInfo>
    )
  } else if (expirationStatus === EDelinquencyNotification.EXPIRED) {
    expireInfo = (
      <PaymentIconInfo expirationStatus={expirationStatus}>
        <IconInfo className="iconInfo" size={15} /> {t('expired')}
      </PaymentIconInfo>
    )
  } else {
    expireInfo = (
      <>
        {t('expires')} {billingInfo?.creditCard?.displayExpirationDate}
      </>
    )
  }

  return (
    <>
      <CCImageContainer>
        <img
          src={creditCardInfo?.cardImage}
          alt={billingInfo?.creditCard?.cardTypeKey}
        />
      </CCImageContainer>
      {billingInfo?.paymentType === PaymentMethodType.direct_debit ? (
        <CCInfoText>{billingInfo?.directDebit?.bankRedactedIban}</CCInfoText>
      ) : (
        <>
          <CCInfoText>{userName}</CCInfoText>
          <CCInfoText>{billingInfo?.creditCard?.cardNumber}</CCInfoText>
          <CCInfoText>
            <>{expireInfo}</>
          </CCInfoText>
        </>
      )}
    </>
  )
}

export default CreditCard
