import styled from 'styled-components'
import tokens from '@veneer/tokens'

const { color, layout } = tokens

export const CardWrapper = styled.div<{ props }>`
  width: calc(50% - 16px);
  pointer-events: ${(props) => (props.isDisabled ? 'none' : 'auto')};
  border-radius: ${layout.cornerRadius4};
  background-color: ${color.white};
  border: ${(props) =>
    props.borderColor ? `1px solid ${props.borderColor}` : 'none'};
  @media only screen and (max-width: ${tokens.layout.smMax}) {
    width: 100%;
  }
`
export const BillingCard = styled.div`
  background-color: ${tokens.color.white};
  border-radius: ${tokens.layout.cornerRadius4};
  && > div {
    padding: ${tokens.layout.size4};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 240px;
    height: 260px;
  }
`
export const Wrapper = styled.div`
  width: calc(50% - 16px);
  box-sizing: border-box;
  background-color: ${color.white};
  border-radius: ${layout.cornerRadius4};
  padding: ${layout.size4};
`
