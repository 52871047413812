import styled, { css } from 'styled-components'
import primitives from '@veneer/primitives'

export const PaymentButtons = styled.div`
  && {
    display: flex;
    margin: 32px 0 24px 0;
    width: 100%;
    text-align: center;

    ${(props) => {
      if (
        ['tablet-landscape-container', 'desktop-container'].includes(
          props.theme.containerSize
        )
      ) {
        return css`
          flex-direction: row;
        `
      } else {
        return css`
          flex-direction: column;
        `
      }
    }}

    > div {
      flex: 1 1;

    ${(props) => {
      if (
        !['tablet-landscape-container', 'desktop-container'].includes(
          props.theme.containerSize
        )
      ) {
        return css`
          &:not(:last-child) {
            margin-bottom: 16px;
          }
        `
      } else {
        return css`
          &:not(:last-child) {
            margin-right: 32px;
          }
        `
      }
    }}
  }
`

export const OrSection = styled.div`
  && {
    display: flex;
    text-align: center;
    width: 100%;

    span {
      text-transform: uppercase;
      line-height: 20px;
      font-size: 14px;
    }
  }
`

export const OrBar = styled.div`
  && {
    background: ${primitives.color.gray3};
    height: 2px;
    width: 100%;
    margin: 8px;
  }
`

type PgsFormSectionProps = {
  hasMorePaymentMethods: boolean
}

export const PgsFormSection = styled.div<PgsFormSectionProps>`
  && {
    ${({ hasMorePaymentMethods }) =>
      hasMorePaymentMethods &&
      css`
        transform: translateY(-8px);
      `}
  }
`
