import React from 'react'
import useGetText from '@/hooks/useGetText'
import {
  ModalContainer,
  StyledModal,
  ModalContent,
  ModalDescription,
  Title,
  Desc1,
  Desc2,
  ButtonBlock,
  StyledButton
} from './styles'
import useAppContext from '@/hooks/useAppContext'
import useRootProps from '@/hooks/useRootProps'
import { setShowUnSupportedAddressForm } from '@/actions/Actions'

const UnSupportedAddress = () => {
  const { state, dispatch } = useAppContext()
  const { removePaperService, backToAddress } = useRootProps()
  const getText = useGetText('unSupportedAddress')

  const handleBackToAddressClick = () => {
    backToAddress()
    dispatch(setShowUnSupportedAddressForm(false))
  }

  return (
    <ModalContainer>
      <StyledModal
        className="modal-paper-on-paas-unsupported-address"
        data-testid="modal-paper-on-paas-unsupported-address"
        show={state.showUnSupportedAddressForm}
        closeButton={false}
      >
        <ModalContent>
          <ModalDescription>
            <Title>{getText('title')}</Title>

            <Desc1>{getText('description1')}</Desc1>
            <Desc2>{getText('description2')}</Desc2>
            <Desc1>{getText('description3')}</Desc1>

            <ButtonBlock>
              <StyledButton
                data-testid="btn1-test"
                appearance="secondary"
                colorScheme="default"
                onClick={handleBackToAddressClick}
              >
                {getText('backToAddressButton')}
              </StyledButton>
              <StyledButton
                appearance="secondary"
                data-testid="btn2-test"
                onClick={removePaperService}
              >
                {getText('removePaperServiceButton')}
              </StyledButton>
            </ButtonBlock>
          </ModalDescription>
        </ModalContent>
      </StyledModal>
    </ModalContainer>
  )
}

export default UnSupportedAddress
