import React from 'react'
import Images from '@/assets/images'
import { ImageContainer, Subtitle, Title } from './styles'
import useGetText from '../../../../hooks/useGetText'

interface HeaderProps {
  subscriptionId: string[]
}

const Header = (props: HeaderProps) => {
  const getText = useGetText('subscriptions')
  const { subscriptionId } = props
  const combinedSubscriptionId = subscriptionId.join(', ')
  return (
    <div>
      <ImageContainer>
        <img src={Images.instant_ink_single_line} alt="II Plan" />
      </ImageContainer>
      {subscriptionId.length > 0 && (
        <>
          <Title>{getText('subscriptionId')}</Title>
          <Subtitle>{combinedSubscriptionId}</Subtitle>
        </>
      )}
    </div>
  )
}

export default Header
