import { JarvisAuthProvider } from '@jarvis/web-http'
import axios, { AxiosInstance } from 'axios'
import sendOpenTelemetryEvent from '../../helpers/sendOpenTelemetryEvent'
import { forceLoginHandling } from '@/utils/forceLoginHandling'
import InfoDatas from '@/types/InfoDatas'
export default class IInkBillingInfoClient {
  httpClient: AxiosInstance
  baseURL: string
  mfeAuthProvider: JarvisAuthProvider

  constructor(authProvider: JarvisAuthProvider) {
    this.httpClient = axios
    this.mfeAuthProvider = authProvider
    this.baseURL = `${process.env.BILLING_BASE_URL_PROVIDER}/instantink/v1/commerce`
  }

  async getBillingData(): Promise<InfoDatas['billingInfo']> {
    const stratusToken = await this.mfeAuthProvider.getAccessToken()
    const headers = {
      accept: 'application/json',
      authorization: `Bearer ${stratusToken}`
    }

    return this.httpClient
      .get(`${this.baseURL}/billing`, {
        headers
      })
      .then(({ data }) => data)
      .catch((err) => {
        if (err?.response?.status === 403) {
          forceLoginHandling()
        }
        sendOpenTelemetryEvent(
          'Error while fetching billing data from storefront client: ' +
            JSON.stringify(err)
        )
        throw err
      })
  }
}
