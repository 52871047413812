import styled from 'styled-components'
import tokens from '@veneer/tokens'

interface HPAIPBillingProps {
  isDisabled: boolean
  borderColor: string
}

export const CardWrapper = styled.div<HPAIPBillingProps>`
  width: calc(50% - 16px);
  pointer-events: ${(props) => (props.isDisabled ? 'none' : 'auto')};
  cursor: ${(props) => (props.isDisabled ? 'none' : 'default')};
  opacity: ${(props) => (props.isDisabled ? 0.5 : 1)};
  border-radius: ${tokens.layout.cornerRadius4};

  @media only screen and (max-width: ${tokens.layout.smMax}) {
    width: 100%;
  }
`
export const BillingCard = styled.div<HPAIPBillingProps>`
  && > div {
    background-color: ${tokens.color.white};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 240px;
    height: 260px;
    border-radius: ${tokens.layout.cornerRadius4};
    padding: ${tokens.layout.size4};
    border: ${(props) =>
      props.borderColor ? `1px solid ${props.borderColor}` : 'none'};
  }
`
export const StyledLoader = styled.div`
  transform: translate(10px, 10px);
  position: relative;
`
export const ErrorItemContainer = styled.div`
  height: 260px;
  min-height: 260px;

  width: calc(50% - 16px);
  && {
    padding: ${tokens.layout.size4};
    background-color: ${tokens.color.white};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: ${tokens.layout.cornerRadius4};
  }
`
