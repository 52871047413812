import React, { FC, useEffect, useState } from 'react'
import {
  AddressBlock,
  AddressCardContainer,
  AddressLine,
  Title
} from './styles'
import ShippingAddress from 'src/types/ShippingAddress'

interface AddressCardProps {
  title: string
  selectedAddress: boolean
  address: ShippingAddress
  onClick: () => void
}
const AddressCard: FC<AddressCardProps> = (props: AddressCardProps) => {
  const { title, selectedAddress, address, onClick } = props
  const [formattedAddress, setFormattedAddress] =
    useState<ShippingAddress | null>(null)

  useEffect(() => {
    setFormattedAddress(address)
  }, [address])
  return (
    <AddressCardContainer>
      <Title>{title}</Title>
      <AddressBlock
        onClick={onClick}
        selected={selectedAddress}
        data-testid="address-block"
      >
        <AddressLine>{formattedAddress?.address}</AddressLine>
        <AddressLine>{formattedAddress?.address2}</AddressLine>
        <AddressLine>{formattedAddress?.city}</AddressLine>
        <AddressLine>{formattedAddress?.postalCode}</AddressLine>
      </AddressBlock>
    </AddressCardContainer>
  )
}

export default AddressCard
