import React, { useEffect, useState } from 'react'
import {
  SubscriptionsStateClient,
  Subscription
} from '@monetization/hpaip-notification-rules-react'
import useInstantInkSubscription from './hooks/useInstantInkSubscription'
import useAccount from './hooks/useAccount/useAccount'
import CombinedNotificationFlow from './components/CombinedNotificationFlow/CombinedNotificationFlow'
import IINotificationFlow from './components/IINotificationFlow/IINotificationFlow'
import { LoadingHandler } from '@monetization/hpaip-ui-shared-components'
import ErrorCard from './components/ErrorCard'
import { Container, StyledMainTitle } from './styles'
import useGetText from '../../hooks/useGetText'
import Card from '@veneer/core/dist/scripts/card'
import EmptyState from './components/EmptyState'

const ShippingBillingRoot = (props) => {
  const getText = useGetText()
  const { stack } = props
  const authProvider = window?.Shell?.v1?.authProvider
  const [hpOneSubscriptionInfo, setHPOneSubscriptionInfo] =
    useState<Subscription>(null)
  const [hpOneIsFetching, setHPOneIsFetching] = useState<boolean>(false)
  const [hpOneError, setHPOneError] = useState<boolean>(false)
  const [refreshNotification, setRefreshNotification] = useState<boolean>(false)

  const handleRefreshNotification = () => {
    setRefreshNotification(!refreshNotification)
  }

  //II Subscription call
  const {
    info: {
      data: iInkSubscriptionInfo,
      isFetching: iInkIsFetching,
      error: iInkError
    }
  } = useInstantInkSubscription()
  const account = useAccount().info
  //HP All in plan Subscription call
  useEffect(() => {
    const fetchSubscriptions = async () => {
      setHPOneIsFetching(true)
      try {
        const subscriptionsClient = new SubscriptionsStateClient(
          authProvider,
          stack
        )
        const subscriptionsData =
          await subscriptionsClient.getAllSubscriptions()
        setHPOneSubscriptionInfo(subscriptionsData)
        setHPOneIsFetching(false)
      } catch (err) {
        console.error('Error fetching subscriptions:', err)
        setHPOneIsFetching(false)
        setHPOneError(true)
        //setError('Failed to fetch subscriptions')
      } finally {
        //setLoading(false)
      }
    }

    fetchSubscriptions()
  }, [authProvider, stack, refreshNotification])

  const hpaipsubscriptionData = {
    hpOneSubscriptionInfo,
    hpOneIsFetching,
    hpOneError
  }
  const iisubscriptionData = {
    iInkSubscriptionInfo,
    iInkIsFetching,
    iInkError
  }

  const commonProps = {
    hpaip: hpaipsubscriptionData,
    iInk: iisubscriptionData,
    accountData: account?.data,
    notificationList: [],
    isHPOneSubscriptionAvailable: hpOneSubscriptionInfo?.length > 0,
    commonNotificationData: [],
    authProvider: authProvider,
    stack: stack,
    localNotificationList: props?.localNotificationList,
    setLocalNotificationList: props?.setLocalNotificationList,
    handleRefreshNotification
  }

  const renderNotificationFlow = () => {
    if (hpOneSubscriptionInfo?.length > 0) {
      return <CombinedNotificationFlow commonProps={commonProps} />
    }
    return <IINotificationFlow commonProps={commonProps} />
  }

  const isLoading = hpOneIsFetching || iInkIsFetching
  const isError = hpOneError && !!iInkError
  const reloadPage = () => {
    window.location.reload()
  }
  const errorCardContent = (
    <Container>
      <StyledMainTitle role="heading" aria-level="1">
        {getText('mainTitle', { defaultValue: 'Shipping & Billing' })}
      </StyledMainTitle>
      <ErrorCard
        hpOneSubtitle={getText('errorScreen.subtitle', {
          defaultValue: 'Your shipping & billing information did not load.'
        })}
        refetchData={reloadPage}
      />
    </Container>
  )
  const renderContent = () => {
    if (isLoading) {
      return <LoadingHandler loading={isLoading} />
    }

    if (isError) {
      return (
        <Card
          content={errorCardContent}
          className="card-box"
          customStyle={{ borderRadius: 8 }}
        />
      )
    }

    if (
      commonProps.isHPOneSubscriptionAvailable ||
      commonProps.iInk?.iInkSubscriptionInfo?.instantInkSubscriptions?.length >
        0
    ) {
      return <>{renderNotificationFlow()}</>
    }

    return <EmptyState />
  }

  return <div>{renderContent()}</div>
}

export default ShippingBillingRoot
