import styled from 'styled-components'
import tokens from '@veneer/tokens'

export const StyledIconContainer = styled.div`
  && {
    display: flex;
    font-family: ${tokens.typography.family0};
    font-weight: 400;
    flex-direction: column;
    padding-bottom: ${tokens.layout.size1};
    img {
      width: ${tokens.layout.size6};
      height: ${tokens.layout.size6};
    }
  }
`
export const StyledTitleContent = styled.div`
  && {
    font-family: ${tokens.typography.family0};
    font-size: ${tokens.typography.size2};
    line-height: ${tokens.typography.lineHeight3};
    font-weight: 400;
    span {
      word-break: break-word;
    }
  }
`
export const StyledContent = styled.div`
  && {
    font-family: ${tokens.typography.family0};
    font-size: ${tokens.typography.size1};
    line-height: ${tokens.typography.lineHeight2};
    font-weight: 400;
    span {
      word-break: break-word;
    }
  }
`
