import { BillingForm } from '@jarvis/react-paas-billing'
import React from 'react'
import { StyledModal } from './styles'

const BillingModal = ({
  billingModal,
  handleOnCloseModal,
  subscriptionId,
  country,
  language,
  authProvider,
  shell,
  handleOnSave,
  handleOnUpdate,
  isAgentSession
}: any) => {
  return (
    <>
      {billingModal && (
        <StyledModal
          data-testid="modal"
          closeButton
          show={billingModal}
          onClose={handleOnCloseModal}
          closeOnBlur={false}
        >
          <BillingForm
            subscriptionId={subscriptionId}
            accountIdentifier={'testingId'}
            country={country}
            language={language}
            authProvider={authProvider}
            onSave={handleOnSave}
            onCancel={handleOnCloseModal}
            onUpdate={handleOnUpdate}
            stack={shell?.stack}
            virtualKeyboard={isAgentSession}
            nativeApp={shell?.isNative}
            mockStratus={false}
          />
        </StyledModal>
      )}
    </>
  )
}

export default BillingModal
