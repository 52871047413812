import React from 'react'
import { ShippingContent } from './styles'
import AssetsProviderFactory from '@/assets/AssetsProviderFactory'
import { setAssetsProvider } from '@/actions/Actions'
import Spinner from '@/components/Spinner'
import useAppContext from '@/hooks/useAppContext'
import ShippingAddressForm from '../ShippingAddressForm'
import useRootProps from '@/hooks/useRootProps'
import { getAddressItem } from '@/services/AddressService'
import useGetAddressId from '@/hooks/useGetAddressId'
import SuggestAddressForm from '../SuggestAddressForm'
import UnSupportedAddressForm from '../UnsupportedAddress/UnSupportedAddress'

const Shipping = () => {
  const { state, dispatch } = useAppContext()
  const {
    language,
    country,
    subscriptionType,
    authProvider,
    baseURLProvider,
    updateURLProvider
  } = useRootProps()
  const addressId = useGetAddressId()

  React.useEffect(() => {
    if (language && country) {
      const assetProvider = AssetsProviderFactory.create(language, country)
      dispatch(setAssetsProvider(assetProvider))
    }
    getAddressItem(
      addressId,
      state,
      subscriptionType,
      authProvider,
      dispatch,
      baseURLProvider,
      updateURLProvider
    )
  }, [addressId, subscriptionType, authProvider, dispatch]) // eslint-disable-line react-hooks/exhaustive-deps

  const renderForm = state.showUnSupportedAddressForm ? (
    <UnSupportedAddressForm />
  ) : state.showSuggestAddressForm ? (
    <SuggestAddressForm />
  ) : (
    <ShippingAddressForm />
  )

  const content = !state.isFetching ? <>{renderForm}</> : <Spinner />

  return <ShippingContent>{content}</ShippingContent>
}

export default Shipping
