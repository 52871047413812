import React from 'react'
import { useBillingFormState, useContainerSize } from '../../hooks'
import { TaxIdFormContent } from './taxIdFormContent'
import { ThemeProvider } from 'styled-components'
import { StyledTaxIdForm } from './styles'

export const TaxIdFormContainer = () => {
  const containerSize = useContainerSize()
  const { trackClickEvent } = useBillingFormState()
  const handleEvent = (event: React.MouseEvent<HTMLElement>) => {
    try {
      const linkId = (event.target as Element).getAttribute('data-analyticsid')
      if (linkId) {
        trackClickEvent?.call(null, linkId, 'tax-id-information')
      }
    } catch {
      // do nothing
    }
  }

  return (
    <ThemeProvider theme={{ containerSize }}>
      <StyledTaxIdForm onClick={handleEvent}>
        <TaxIdFormContent />
      </StyledTaxIdForm>
    </ThemeProvider>
  )
}
