import styled from 'styled-components'
import tokens from '@veneer/tokens'

export const ImageContainer = styled.div`
  margin-bottom: ${tokens.layout.size2};
`

export const StyledTitle = styled.div`
  && {
    font-size: ${tokens.typography.size0};
    font-family: ${tokens.typography.family0};
    line-height: ${tokens.layout.size4};
    font-weight: 400;
    word-wrap: break-word;
    margin-bottom: ${tokens.layout.size1};
  }
`
export const StyledSubscriptionContainer = styled.p`
  && {
    font-size: ${tokens.typography.size0};
    line-height: ${tokens.typography.size2};
  }
`
