import {
  ShippingFormState,
  ShippingFormAction,
  ShippingFormInitialState,
  ValidateFieldOptions
} from '../types'
import { ShippingFormActionType } from '../constants/shippingFormAction'
import { AddressValidator } from '../lib/addressValidator'
import AssetsProviderFactory from '../assets/AssetsProviderFactory'
import { MockShippingService } from '../mock/lib/mockShippingService'
import { ShippingService } from '../lib/shippingService'

export const initialShippingFormState = ({
  language,
  country,
  mockStratus,
  baseURLProvider,
  authProvider,
  xCorrelationId,
  onSave,
  onSaveButtonClick,
  onCancel,
  cloudId,
  addressId,
  trackClickEvent,
  saveButtonText,
  hideTitle,
  secondaryButton,
  disableListView,
  enableDebugging
}: ShippingFormInitialState): ShippingFormState => ({
  shippingService:
    mockStratus === true
      ? new MockShippingService()
      : new ShippingService(baseURLProvider, authProvider, xCorrelationId),
  assetsProvider: AssetsProviderFactory.create(language, country),
  errorFields: new Set(),
  warningFields: new Set(),
  enableErrors: false,
  shippingDataLoading: false,
  shippingAddressesLoading: false,
  settingsDataLoading: false,
  showSuggestedAddressModal: false,
  showUnsupportedPostalCodeModal: false,
  showUnsupportedPaperAddressModal: false,
  country,
  onSave,
  onSaveButtonClick,
  onCancel,
  fetchErrors: [],
  cloudId,
  addressId,
  addressCount: 0,
  fields: new Set(),
  trackClickEvent,
  saveButtonText,
  hideTitle,
  secondaryButton,
  disableListView,
  enableDebugging
})

export const shippingFormReducer = (
  state: ShippingFormState,
  action: ShippingFormAction
): ShippingFormState => {
  const { shippingData, errorFields, country, suggestedAddress, fields } = state
  let newState = state

  const addressValidator = new AddressValidator(country)

  const validateField = (
    field: string,
    value: string,
    options: ValidateFieldOptions = {}
  ): string => {
    const { valid, fieldErrorCode } = addressValidator.validateField(
      field,
      value,
      options
    )
    if (valid) {
      errorFields.delete(field)
    } else {
      errorFields.add(field)
    }

    return fieldErrorCode
  }

  switch (action.type) {
    case ShippingFormActionType.SET_ASSETS_PROVIDER:
      newState = {
        ...state,
        assetsProvider: AssetsProviderFactory.create(
          action.language,
          action.country
        )
      }
      break
    case ShippingFormActionType.FETCH_SETTINGS_DATA:
      newState = {
        ...state,
        settingsDataLoading: true
      }
      break
    case ShippingFormActionType.FETCH_SETTINGS_DATA_SUCCESS:
      newState = {
        ...state,
        settingsData: action.data,
        settingsDataError: undefined,
        settingsDataLoading: false
      }
      break
    case ShippingFormActionType.FETCH_SETTINGS_DATA_FAILED:
      newState = {
        ...state,
        settingsDataLoading: false,
        settingsDataError: action.error
      }
      break
    case ShippingFormActionType.FETCH_SHIPPING_DATA:
      newState = {
        ...state,
        shippingDataLoading: true
      }
      break
    case ShippingFormActionType.FETCH_SHIPPING_DATA_SUCCESS:
      newState = {
        ...state,
        shippingData: action.data,
        hasExistingAddress: action.data?.street1,
        shippingDataError: undefined,
        shippingDataLoading: false,
        selectedAddress: action.data
      }
      break
    case ShippingFormActionType.FETCH_SHIPPING_DATA_FAILED:
      newState = {
        ...state,
        shippingDataLoading: false,
        shippingDataError: action.error
      }
      break
    case ShippingFormActionType.FETCH_SHIPPING_ADDRESSES:
      newState = {
        ...state,
        shippingAddressesLoading: true
      }
      break
    case ShippingFormActionType.FETCH_SHIPPING_ADDRESSES_SUCCESS:
      newState = {
        ...state,
        shippingAddresses: action.data,
        shippingAddressesError: undefined,
        shippingAddressesLoading: false
      }
      break
    case ShippingFormActionType.FETCH_SHIPPING_ADDRESSES_FAILED:
      newState = {
        ...state,
        shippingAddressesLoading: false,
        shippingAddressesError: action.error
      }
      break
    case ShippingFormActionType.SET_TEXT_MESSAGE_OPT_IN:
      newState = {
        ...state,
        shippingData: { ...shippingData, optedIn: action.data }
      }
      break
    case ShippingFormActionType.SET_DEFAULT_ADDRESS:
      newState = {
        ...state,
        shippingData: { ...shippingData, isDefaultAddress: action.data }
      }
      break
    case ShippingFormActionType.SET_ADDRESS_COUNT:
      newState = {
        ...state,
        addressCount: action.data
      }
      break
    case ShippingFormActionType.SET_SHIPPING_DATA_TO_SUGGESTED_ADDRESS:
      newState = {
        ...state,
        shippingData: { ...shippingData, ...suggestedAddress }
      }
      break
    case ShippingFormActionType.VALIDATE_FIELD_AND_CACHE_SHIPPING_DATA: {
      const { field, value, options } = action
      const newShippingData = { ...shippingData }
      newShippingData[field] = value

      const fieldErrorCode = validateField(field, value, options)
      newState = {
        ...state,
        shippingData: newShippingData,
        fieldErrorCode: fieldErrorCode,
        errorFields: new Set(errorFields)
      }
      break
    }
    case ShippingFormActionType.VALIDATE_ALL_FIELDS: {
      fields.forEach((field) => {
        validateField(field, shippingData[field], {
          blockPoBox: !shippingData.allowPoBox
        })
      })

      newState = {
        ...state,
        errorFields: new Set(errorFields)
      }
      break
    }
    case ShippingFormActionType.CLEAR_ERROR_FIELDS:
      newState = {
        ...state,
        errorFields: new Set()
      }
      break
    case ShippingFormActionType.ENABLE_ERRORS:
      newState = {
        ...state,
        enableErrors: true,
        formErrorCode: undefined
      }
      break
    case ShippingFormActionType.CACHE_SHIPPING_DATA:
      newState = {
        ...state,
        shippingData: action.data
      }
      break
    case ShippingFormActionType.VALIDATE_ALL_FIELDS_FAILED:
      newState = {
        ...state
      }
      break
    case ShippingFormActionType.ASSOCIATE_ADDRESS_WITH_PRINTER:
      newState = {
        ...state,
        formErrorCode: undefined
      }
      break
    case ShippingFormActionType.ASSOCIATE_ADDRESS_WITH_PRINTER_SUCCESS:
      newState = {
        ...state,
        shippingData: action.data
      }
      break
    case ShippingFormActionType.OPEN_SUGGESTED_ADDRESS_MODAL:
      newState = {
        ...state,
        suggestedAddress: action.data,
        selectedAddress: action.data,
        showSuggestedAddressModal: true
      }
      break
    case ShippingFormActionType.CLOSE_SUGGESTED_ADDRESS_MODAL:
      newState = {
        ...state,
        showSuggestedAddressModal: false
      }
      break
    case ShippingFormActionType.OPEN_UNSUPPORTED_PAPER_ADDRESS_MODAL:
      newState = {
        ...state,
        showUnsupportedPaperAddressModal: true
      }
      break
    case ShippingFormActionType.CLOSE_UNSUPPORTED_PAPER_ADDRESS_MODAL:
      newState = {
        ...state,
        showUnsupportedPaperAddressModal: false
      }
      break
    case ShippingFormActionType.OPEN_UNSUPPORTED_POSTAL_CODE_MODAL:
      newState = {
        ...state,
        showUnsupportedPostalCodeModal: true
      }
      break
    case ShippingFormActionType.CLOSE_UNSUPPORTED_POSTAL_CODE_MODAL:
      newState = {
        ...state,
        showUnsupportedPostalCodeModal: false
      }
      break
    case ShippingFormActionType.SAVE_SELECTED_ADDRESS:
      newState = {
        ...state,
        selectedAddress: action.data
      }
      break
    case ShippingFormActionType.SAVE_FIELD_ERROR_CODE: {
      const { fieldErrorCode } = action

      newState = {
        ...state,
        fieldErrorCode
      }
      break
    }
    case ShippingFormActionType.SAVE_FORM_ERROR_CODE: {
      const { formErrorCode, warningFields } = action

      newState = {
        ...state,
        formErrorCode,
        warningFields
      }
      break
    }
    case ShippingFormActionType.SET_VALIDATED_ADDRESS:
      newState = {
        ...state,
        validatedAddress: shippingData
      }
      break
    case ShippingFormActionType.SET_FETCH_ERRORS: {
      const { fetchErrors: currentFetchErrors } = state

      newState = {
        ...state,
        fetchErrors: [...currentFetchErrors, action.data]
      }
      break
    }
    case ShippingFormActionType.CLEAR_FAILED_ACTIONS:
      newState = {
        ...state,
        fetchErrors: []
      }
      break
    case ShippingFormActionType.SET_FIELDS:
      newState = {
        ...state,
        fields: action.fields
      }
      break
  }
  return newState
}
