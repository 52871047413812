import { AssetsProvider } from '@jarvis/web-assets-provider'
import ShippingAddress from '@/types/ShippingAddress'

export enum ActionsType {
  SET_RETRIEVE_ADDRESS_ITEM_SUCCESS = 'SET_RETRIEVE_ADDRESS_ITEM_SUCCESS',
  SET_RETRIEVE_AllADDRESS_ITEMS_SUCCESS = 'SET_RETRIEVE_AllADDRESS_ITEMS_SUCCESS',
  SET_ERROR = 'SET_ERROR',
  SET_IS_FETCHING_FLAG = 'SET_IS_FETCHING_FLAG',
  SET_ASSETS_PROVIDER = 'SET_ASSETS_PROVIDER',
  RESET_STATE = 'RESSET_STATE',
  SET_SELECTED_ADDRESS = `SET_SELECTED_ADDRESS`,
  SAVE_SELECTED_ADDRESS = `SAVE_SELECTED_ADDRESS`,
  SET_SUGGESTED_ADDRESS = 'SET_SUGGESTED_ADDRESS',
  SET_SHOW_SUGGEST_ADDRESS_FORM = 'SET_SHOW_SUGGEST_ADDRESS_FORM',
  SET_SHOW_UNSUPPORTED_ADDRESS_FORM = 'SET_SHOW_UNSUPPORTED_ADDRESS_FORM'
}

export type Actions = {
  type: ActionsType
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any
}

export const setRetrieveAddressItemSuccess = (
  data: ShippingAddress
): Actions => ({
  type: ActionsType.SET_RETRIEVE_ADDRESS_ITEM_SUCCESS,
  data
})

export const setRetrieveAllAddressItemsSuccess = (
  data: ShippingAddress[]
): Actions => ({
  type: ActionsType.SET_RETRIEVE_AllADDRESS_ITEMS_SUCCESS,
  data
})

export const setError = (data: string): Actions => ({
  type: ActionsType.SET_ERROR,
  data
})

export const setIsFetchingFlag = (data: boolean): Actions => ({
  type: ActionsType.SET_IS_FETCHING_FLAG,
  data
})

type ReturnSetAssetsProvider = {
  type: ActionsType.SET_ASSETS_PROVIDER
  data: AssetsProvider
}

export const setAssetsProvider = (
  assetsProvider: AssetsProvider
): ReturnSetAssetsProvider => ({
  type: ActionsType.SET_ASSETS_PROVIDER,
  data: assetsProvider
})

export const resetState = (): Actions => ({
  type: ActionsType.RESET_STATE
})

export const setSelectedAddress = (data: ShippingAddress): Actions => ({
  type: ActionsType.SET_SELECTED_ADDRESS,
  data: data
})

export const saveSelectedAddress = (data: ShippingAddress): Actions => ({
  type: ActionsType.SAVE_SELECTED_ADDRESS,
  data: data
})

export const setSuggestedAddress = (data: ShippingAddress): Actions => ({
  type: ActionsType.SET_SUGGESTED_ADDRESS,
  data: data
})

export const setShowSuggestAddressForm = (data: boolean): Actions => ({
  type: ActionsType.SET_SHOW_SUGGEST_ADDRESS_FORM,
  data: data
})

export const setShowUnSupportedAddressForm = (data: boolean): Actions => ({
  type: ActionsType.SET_SHOW_UNSUPPORTED_ADDRESS_FORM,
  data: data
})
