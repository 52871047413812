import React, { useEffect, useMemo, useState } from 'react'
import {
  BillingSectionContainer,
  StyledTitle,
  StyledDescContainer,
  BillingItemsContainer,
  BillingCardWrapper,
  BillingCard
} from './styles'
import useGetText from '@/hooks/useGetText'
import Images from '../../assets/images'
import Skeleton from '../Skeleton'
import PaymentMethod from './PaymentMethod'
import HpOnePaymentMethod from './HpOnePaymentMethod'
import usePaymentMethodsInfo from '@/hooks/usePaymentMethodsInfo'
import { PaymentMethodType } from '@/types/PaymentMethodType'
import { SubscriptionInfo } from '@/types/SubscriptionInfo'
import { mblEntityType, SubscriptionEntity } from '@/types/SubscriptionEntity'
import { HpOneSubsEntityState } from '@/types/HpOneSubsEntityState'
import { instantInkStates } from '@/utils/instantInkStates'
import {
  StyledIconContainer,
  StyledTextContainer
} from './NoBillingDataContent/styles'
import AddPaymentMethod from '@/components/Billing/AddPaymentMethod'
import { useAppContext } from '@/context/AppContext'
import useBillingInfo from '@/hooks/useBillingInfo'

const Billing = () => {
  const [hpOneBillingInfo, setHpOneBillingInfo] = useState(null)
  const getText = useGetText('billing')
  const {
    instantInkSubscriptionInfo,
    account,
    subscriptionInfo: {
      data: subscriptionInfo,
      isFetching: subscriptionInfoIsFetching
    }
  } = useAppContext().state

  const {
    info: {
      data: instantInkBillingInfo,
      isFetching: instantInkBillingIsFetching
    }
  } = useBillingInfo()

  const {
    info: { data: billingInfo, isFetching: billingIsFetching },
    forceRefresh
  } = usePaymentMethodsInfo(subscriptionInfo?.[0]?.paymentMethodId)

  useEffect(() => {
    if (
      !billingIsFetching &&
      !subscriptionInfoIsFetching &&
      billingInfo &&
      subscriptionInfo
    ) {
      setHpOneBillingInfo({
        ...billingInfo,
        payment_methods: billingInfo.payment_method_details
      })
    }
  }, [
    billingIsFetching,
    subscriptionInfoIsFetching,
    billingInfo,
    subscriptionInfo
  ])

  const showHpOneCreditCard =
    hpOneBillingInfo?.payment_method_details?.card_type || false
  const showIICreditCard =
    instantInkBillingInfo?.paymentType === PaymentMethodType.credit_card &&
    instantInkSubscriptionInfo?.data?.instantInkSubscriptions?.length > 0
  const showPayPal =
    instantInkBillingInfo?.paymentType === PaymentMethodType.pay_pal &&
    instantInkSubscriptionInfo?.data?.instantInkSubscriptions?.length > 0
  const showDirectDebit =
    instantInkBillingInfo?.paymentType === PaymentMethodType.direct_debit &&
    instantInkSubscriptionInfo?.data?.instantInkSubscriptions?.length > 0

  const showBilling = showIICreditCard || showPayPal || showHpOneCreditCard
  const showIIBilling = showIICreditCard || showPayPal || showDirectDebit

  const instantInkState = [
    instantInkStates.SUBSCRIBED_NO_PENS,
    instantInkStates.SUBSCRIBED_NO_PRINTER,
    instantInkStates.SUBSCRIBED,
    instantInkStates.INITIATED_UNSUBSCRIBE,
    instantInkStates.UNSUBSCRIBED
  ]

  const isAddPayment =
    !showIIBilling &&
    instantInkSubscriptionInfo?.data?.instantInkSubscriptions?.find(
      ({ state }) =>
        state === HpOneSubsEntityState.ACTIVE ||
        instantInkState.includes(state as instantInkStates)
    )
  useEffect(() => {
    forceRefresh()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account?.data])

  const cancellingStates = [HpOneSubsEntityState.INACTIVE]
  const isCancelling = (entry: SubscriptionInfo): boolean => {
    return !entry?.entities.find(
      (entry) => isHpOneEntity(entry) && !cancellingStates.includes(entry.state)
    )
  }

  const isHpOneEntity = (entity: SubscriptionEntity): boolean => {
    return (
      entity.entityType === mblEntityType.pc ||
      entity.entityType === mblEntityType.printer ||
      entity.entityType === mblEntityType.chromebook
    )
  }

  const billingHeaderContent = useMemo(
    () =>
      (showBilling || isAddPayment) && (
        <>
          <StyledTitle role="heading" aria-level="3">
            {getText('yourBilling')}
          </StyledTitle>
          <StyledDescContainer>
            {getText('paymentInfoOnFileMsg')}
          </StyledDescContainer>
        </>
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [showBilling, getText]
  )

  const NoBillingDataContent = useMemo(
    () =>
      !showBilling &&
      !isAddPayment && (
        <BillingSectionContainer>
          <StyledIconContainer>
            <img src={Images.billing} alt="title icon" />
          </StyledIconContainer>
          <StyledTitle>{getText('header')}</StyledTitle>
          <StyledTextContainer>
            {getText('noBillingInfoMsg')}
          </StyledTextContainer>
        </BillingSectionContainer>
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [showBilling, getText]
  )

  const skeletonLoading =
    instantInkBillingIsFetching ||
    billingIsFetching ||
    subscriptionInfoIsFetching

  return (
    <>
      {skeletonLoading && (
        <div data-testid="instantInkBillingIsFetching-card">
          <Skeleton width="60vw" height="5vh" />
        </div>
      )}

      {!skeletonLoading && (
        <>
          {NoBillingDataContent}
          {billingHeaderContent}
          <BillingItemsContainer>
            {showHpOneCreditCard && subscriptionInfo.length > 0 && (
              <HpOnePaymentMethod
                isDisabled={isCancelling(subscriptionInfo[0])}
              />
            )}
            {instantInkBillingInfo?.paymentType &&
              (showIICreditCard || showPayPal || showDirectDebit) && (
                <PaymentMethod />
              )}

            {!showIICreditCard && !showPayPal && isAddPayment && (
              <BillingCardWrapper isDisabled={false}>
                <BillingCard>
                  <AddPaymentMethod />
                </BillingCard>
              </BillingCardWrapper>
            )}
          </BillingItemsContainer>
        </>
      )}
    </>
  )
}

export default Billing
