import { useEffect, useState } from 'react'
import useStoreFrontClient from '../useStoreFrontClient'
import sendOpenTelemetryEvent from '@/helpers/sendOpenTelemetryEvent'

const useInstantInkShippingAddress = (hasInstantInkSubscription) => {
  const storeFrontClient = useStoreFrontClient()

  const [error, setError] = useState(false)
  const [data, setData] = useState(null)
  const [isfetching, setIsFetching] = useState(false)

  const fetchData = async () => {
    setIsFetching(true)
    try {
      const instantInkAddress =
        await storeFrontClient.getInstnatInkShippingAddress()
      if (instantInkAddress) {
        setData(instantInkAddress)
        setIsFetching(false)
      } else {
        setData(null)
      }
    } catch (error) {
      sendOpenTelemetryEvent(
        'Error while fetching getSettingsData: ' + JSON.stringify(error)
      )
      setError(true)
      setIsFetching(false)
    }
  }

  useEffect(() => {
    if (storeFrontClient && hasInstantInkSubscription) {
      fetchData()
    }
  }, [storeFrontClient])

  return {
    data,
    error,
    isfetching,
    forceRefresh: fetchData
  }
}

export default useInstantInkShippingAddress
