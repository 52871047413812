import { useEffect, useState } from 'react'
import useStoreFrontClient from '../useStoreFrontClient'
import sendOpenTelemetryEvent from '@/helpers/sendOpenTelemetryEvent'

const useSettingsInfo = () => {
  const storeFrontClient = useStoreFrontClient()

  const [error, setError] = useState(false)
  const [data, setData] = useState(false)

  const fetchData = async () => {
    try {
      const settingsData = await storeFrontClient.getSettingsData()
      if (settingsData?.enablePaypalInWebview) {
        setData(settingsData?.enablePaypalInWebview)
      } else {
        setData(false)
      }
    } catch (error) {
      sendOpenTelemetryEvent(
        'Error while fetching getSettingsData: ' + JSON.stringify(error)
      )
      setError(true)
      setData(false)
    }
  }

  useEffect(() => {
    if (storeFrontClient) {
      fetchData()
    }
  }, [storeFrontClient])

  return {
    data,
    error,
    forceRefresh: fetchData
  }
}

export default useSettingsInfo
