import { useFetchReturnData } from '@/hooks/useFetchReturnData'
import useGetText from '@/hooks/useGetText'
import getAuthProvider from '@/helpers/getAuthProvider'
import getApplicationStack from '@/helpers/getApplicationStack'
import { SubscriptionReturnStatusEnum, SubscriptionStateEnum } from '@/types'
import { getSkuBasedProduct } from '@/utils/getSkuBasedProduct'
import { getNumOfDaysLeft } from '@/utils/subscriptionState'
import { formatDaysToReturn, returnDate } from '@/utils/formatDaysToReturn'
import moment from 'moment'
import { useProductQuery } from '../../hooks/useProductQuery'
import { useEffect, useState } from 'react'
import { getAllNotification } from '@/utils/Notification'
import { useCommerceDetails } from '@/hooks/useCommerceDetails'
import { handleNavigationPath } from '@/utils/handleNavigationPath'
import { NavigationType } from '@/types/shell'
import {
  handleCompleteStatus,
  handleItemsReceivedReturnStatus,
  handleProcessingReturnStatus
} from './usePostCancelNotificationHelper'

export const usePostCancelNotification = (
  subscriptionId,
  tenantId,
  mainEntity,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  navigation?: NavigationType,
  allNotificationsObject?: any,
  pendingData?: any
) => {
  const authProvider = getAuthProvider()
  const stack = getApplicationStack()
  const getText = useGetText('postCancelNotification')
  const [skuBasedProduct, setSkuBasedProduct] = useState(null)
  const [cancelledInRemorse, setCancelledInRemorse] = useState<boolean>(false)
  const [returnStatus, setReturnStatus] = useState('')
  const [numOfDaysLeft, setNumOfDaysLeft] = useState<number>(0)
  const [daysRemainingToReturn, setDaysRemainingToReturn] = useState<string>('')
  const [billingDate, setBillingDate] = useState<string>('')

  const [notificationObject, setNotificationObject] = useState(null)

  const products = useProductQuery(mainEntity?.product?.value?.parentProductSku)
  const returnData = useFetchReturnData(tenantId, authProvider, stack)
  const commerceData = useCommerceDetails(
    mainEntity?.commerce?.value?.subscriptionId,
    authProvider,
    stack
  )
  allNotificationsObject.notificationApiInfo.pendingChangesPostCancellation =
    pendingData
  allNotificationsObject.notificationApiInfo.returnDetails = returnData
  allNotificationsObject.notificationApiInfo.commerceData = commerceData

  /* istanbul ignore next */
  const handleRescindCancel = () => {
    /* istanbul ignore next */
    if (navigation) {
      return navigation.push(
        handleNavigationPath(
          `/hp-all-in-print-plan/cancellation/${subscriptionId}?rescindCancel=true`
        )
      )
    } /* istanbul ignore next */
    window.location.href = handleNavigationPath(
      `/hp-all-in-print-plan/cancellation/${subscriptionId}?rescindCancel=true`
    )
  }

  useEffect(() => {
    const fetchBillingDate = async () => {
      if (commerceData) {
        const { billingDate, cancelledOn, customerEndDate } = commerceData
        const newBillingDate = await returnDate(
          billingDate,
          cancelledOn,
          customerEndDate
        )
        setBillingDate(newBillingDate)
      }
    }

    fetchBillingDate()
  }, [commerceData])

  useEffect(() => {
    if (returnData == '404') {
      setReturnStatus('404')
    } else if (returnData) {
      const returnStatus = returnData?.parts?.find(
        (part) => part.modelSku === mainEntity?.product?.value?.productSku
      )?.status
      setReturnStatus(returnStatus)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [returnData])

  useEffect(() => {
    if (pendingData && mainEntity?.state === SubscriptionStateEnum.CANCELING) {
      const cancellationWithinTrialPeriod = !!pendingData?.contents?.find(
        ({ reason }) => reason.type === 'cancellationWithinTrialPeriod'
      )
      setCancelledInRemorse(cancellationWithinTrialPeriod)
    } else {
      const entityEndDate = moment(
        allNotificationsObject?.printerData?.instantInk?.entityEndDate
      )
      const cancellation =
        allNotificationsObject?.printerData?.optionsData?.subscription
          ?.cancellation
      const trialPeriodData = cancellation?.find(
        (item) => item.name === 'trialPeriod'
      )
      const validUntil = moment(trialPeriodData?.details?.validUntil)
      setCancelledInRemorse(entityEndDate < validUntil)
    }
  }, [allNotificationsObject?.printerData, mainEntity?.state, pendingData])

  useEffect(() => {
    if (products) {
      const getProduct = async () => {
        const product = await getSkuBasedProduct(
          products,
          mainEntity?.product?.value?.parentProductSku
        )
        setSkuBasedProduct(product)
      }
      getProduct()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products])

  useEffect(() => {
    const setCancelNotificationData = async () => {
      if (returnStatus != '404') {
        const numOfDaysLeft = await getNumOfDaysLeft(
          returnData?.createdAt,
          skuBasedProduct
        )
        setNumOfDaysLeft(numOfDaysLeft)
        const daysRemainingToReturn = numOfDaysLeft?.toString()
        setDaysRemainingToReturn(daysRemainingToReturn)
      }
      const billingDateFormatted = moment(billingDate?.split('T')[0]).format(
        'MMM DD'
      )

      const printerStopDateFormatted = await formatDaysToReturn(
        billingDateFormatted,
        skuBasedProduct?.hp_max_days_to_return || 10
      )

      setNotificationObject(
        getSubscriptionReturnStatus(
          numOfDaysLeft,
          daysRemainingToReturn,
          printerStopDateFormatted,
          billingDateFormatted
        )
      )
    }

    if (returnStatus && skuBasedProduct) {
      setCancelNotificationData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    skuBasedProduct,
    cancelledInRemorse,
    returnStatus,
    billingDate,
    daysRemainingToReturn,
    numOfDaysLeft
  ])

  const getSubscriptionReturnStatus = (
    numOfDaysLeft,
    daysRemainingToReturn,
    printerStopDateFormatted,
    billingDateFormatted
  ) => {
    const allNotifications = getAllNotification(
      getText,
      daysRemainingToReturn,
      printerStopDateFormatted,
      billingDateFormatted,
      handleRescindCancel
    )
    if (returnStatus) {
      switch (returnStatus) {
        case SubscriptionReturnStatusEnum.NEW:
        case SubscriptionReturnStatusEnum.INITIATED:
          return allNotifications.deactivating[returnStatus]
        case SubscriptionReturnStatusEnum.PROCESSING:
          return handleProcessingReturnStatus(
            numOfDaysLeft,
            allNotifications,
            returnStatus,
            cancelledInRemorse
          )
        case SubscriptionReturnStatusEnum.ITEMS_RECEIVED:
          return handleItemsReceivedReturnStatus(
            allNotifications,
            cancelledInRemorse,
            returnStatus
          )
        case SubscriptionReturnStatusEnum.COMPLETE_SUCCESSFUL:

        case SubscriptionReturnStatusEnum.TIMEOUT:
          return handleCompleteStatus(
            cancelledInRemorse,
            allNotifications,
            returnStatus
          )
        case SubscriptionReturnStatusEnum.COMPLETE_UNSUCCESSFUL:
          return allNotifications?.inactive[returnStatus]
        case SubscriptionReturnStatusEnum.CANCELED:
          return allNotifications?.inactive[returnStatus]
        case SubscriptionReturnStatusEnum.ERROR_404:
          if (mainEntity.state === SubscriptionStateEnum.CANCELED) {
            return allNotifications?.inactive?.cancelled
          } else {
            return allNotifications?.deactivating?.printerStopDate
          }

        default:
          return allNotifications?.inactive?.cancelled
      }
    } else {
      return allNotifications?.inactive?.cancelled
    }
  }

  return notificationObject
}
