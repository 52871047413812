/* eslint-disable react/display-name */
import React, { useEffect } from 'react'
import {
  setBillingForm,
  setIIBillingForm,
  setDeliquencyNotification,
  setInlineNotification
} from '@/actions/Actions'
import {
  useCommonNotification,
  useFlags
} from '@monetization/hpaip-notification-rules-react'
import { useAppContext } from '@/context/AppContext'
import { defaultInlineNotification } from '@/context/appReducer'
import { MultipleNotification } from '@monetization/hpaip-ui-shared-components'
export interface NotificationPropData {
  subscriptionId?: string
  updateCommonNotification?: () => void
}
export default function CommonNotification({
  subscriptionId,
  updateCommonNotification
}: Readonly<NotificationPropData>) {
  const { state, dispatch } = useAppContext()
  const { inlineNotification } = state

  const handleBillingForm = () => dispatch(setBillingForm(true))
  const handleIIBillingForm = () => dispatch(setIIBillingForm(true))
  const handleInlineNotification = () =>
    dispatch(setInlineNotification(defaultInlineNotification))
  const { enableManagePaperPostEnrollment, enableBuyerRemorseCancellation } =
    useFlags()
  const flagObject = {
    enablePaperOnPaaS: enableManagePaperPostEnrollment,
    enableBuyerRemorseCancellation: enableBuyerRemorseCancellation
  }

  const commonNotification = useCommonNotification(
    'ShippingBilling',
    subscriptionId,
    flagObject,
    handleBillingForm,
    null,
    handleIIBillingForm
  )

  const notificationList = commonNotification?.notificationsList
  if (inlineNotification && inlineNotification?.refreshNotification) {
    updateCommonNotification()
  } else if (
    inlineNotification &&
    !inlineNotification?.refreshNotification &&
    inlineNotification?.display
  ) {
    notificationList.push({
      title: inlineNotification.title,
      description: inlineNotification.description,
      notificationID: 'inlineNotification',
      callbackFunction: handleInlineNotification,
      showCloseButton: true,
      notificationType: inlineNotification.status
    })
  }
  useEffect(() => {
    const notificationData = {
      display: true,
      deliquencyStatus: commonNotification?.deliquencyStatus,
      instantInkDeliquencyStatus: commonNotification?.instantInkDeliquencyStatus
    }
    dispatch(setDeliquencyNotification(notificationData))
  }, [
    commonNotification.deliquencyStatus,
    commonNotification.instantInkDeliquencyStatus,
    dispatch
  ])

  return commonNotification &&
    commonNotification?.printerData?.printer &&
    notificationList.length > 0 ? (
    <MultipleNotification multiNotificationArray={notificationList} />
  ) : null
}
