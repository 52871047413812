import React from 'react'
import useInstantInkShippingAddress from '../../../hooks/useInstantInkShippingAddress'
import ShippingAddress from './ShippingAddress'
import { LoadingHandler } from '@monetization/hpaip-ui-shared-components'
import Card from '@veneer/core/dist/scripts/card'
import {
  StyledAddressItemContainer,
  ShippingAddressItemContainer
} from '../InstantInkShipping/styles'
import ErrorCard from '../../ErrorCard'
import useGetText from '../../../hooks/useGetText'
interface InstantInkShippingingProps {
  commonProps: any
}
const InstantInkShipping = (props: InstantInkShippingingProps) => {
  const { commonProps } = props
  const getText = useGetText()
  const hasInstantInkSubscription =
    commonProps?.iInk?.iInkSubscriptionInfo?.instantInkSubscriptions?.length > 0
  const {
    data: instantInkAddress,
    isfetching: isLoading,
    forceRefresh: fetchIntantInkAddress
  } = useInstantInkShippingAddress(hasInstantInkSubscription)

  const errorCardContent = (
    <ErrorCard
      hpOneSubtitle={getText('errorScreen.instantInkSubTitle', {
        defaultValue: 'Your Instant Ink Plan information did not load.'
      })}
      refetchData={fetchIntantInkAddress}
    />
  )
  const loadingContent = (
    <StyledAddressItemContainer>
      {isLoading ? (
        <LoadingHandler loading={isLoading} />
      ) : (
        <Card
          content={errorCardContent}
          className="card-box"
          customStyle={{ borderRadius: 8 }}
        />
      )}
    </StyledAddressItemContainer>
  )
  const renderContent = () => {
    if (instantInkAddress) {
      return (
        <ShippingAddress
          commonProps={props?.commonProps}
          instantInkAddress={instantInkAddress}
          fetchIntantInkAddress={fetchIntantInkAddress}
        />
      )
    }

    return loadingContent
  }

  return (
    <ShippingAddressItemContainer>
      {renderContent()}
    </ShippingAddressItemContainer>
  )
}

export default InstantInkShipping
