export enum ShippingFormActionType {
  FETCH_SETTINGS_DATA = 'FETCH_SETTINGS_DATA',
  FETCH_SETTINGS_DATA_SUCCESS = 'FETCH_SETTINGS_DATA_SUCCESS',
  FETCH_SETTINGS_DATA_FAILED = 'FETCH_SETTINGS_DATA_FAILED',
  FETCH_SHIPPING_DATA = 'FETCH_SHIPPING_DATA',
  FETCH_SHIPPING_DATA_SUCCESS = 'FETCH_SHIPPING_DATA_SUCCESS',
  FETCH_SHIPPING_DATA_FAILED = 'FETCH_SHIPPING_DATA_FAILED',
  FETCH_SHIPPING_ADDRESSES = 'FETCH_SHIPPING_ADDRESSES',
  FETCH_SHIPPING_ADDRESSES_SUCCESS = 'FETCH_SHIPPING_ADDRESSES_SUCCESS',
  FETCH_SHIPPING_ADDRESSES_FAILED = 'FETCH_SHIPPING_ADDRESSES_FAILED',
  VALIDATE_FIELD_AND_CACHE_SHIPPING_DATA = 'VALIDATE_FIELD_AND_CACHE_SHIPPING_DATA',
  VALIDATE_ALL_FIELDS = 'VALIDATE_ALL_FIELDS',
  CACHE_SHIPPING_DATA = 'CACHE_SHIPPING_DATA',
  VALIDATE_ALL_FIELDS_FAILED = 'VALIDATE_ALL_FIELDS_FAILED',
  OPEN_SUGGESTED_ADDRESS_MODAL = 'OPEN_SUGGESTED_ADDRESS_MODAL',
  CLOSE_SUGGESTED_ADDRESS_MODAL = 'CLOSE_SUGGESTED_ADDRESS_MODAL',
  OPEN_UNSUPPORTED_PAPER_ADDRESS_MODAL = 'OPEN_UNSUPPORTED_PAPER_ADDRESS_MODAL',
  CLOSE_UNSUPPORTED_PAPER_ADDRESS_MODAL = 'CLOSE_UNSUPPORTED_PAPER_ADDRESS_MODAL',
  OPEN_UNSUPPORTED_POSTAL_CODE_MODAL = 'OPEN_UNSUPPORTED_POSTAL_CODE_MODAL',
  CLOSE_UNSUPPORTED_POSTAL_CODE_MODAL = 'CLOSE_UNSUPPORTED_POSTAL_CODE_MODAL',
  SAVE_SELECTED_ADDRESS = 'SAVE_SELECTED_ADDRESS',
  SAVE_FIELD_ERROR_CODE = 'SAVE_FIELD_ERROR_CODE',
  SAVE_FORM_ERROR_CODE = 'SAVE_FORM_ERROR_CODE',
  SET_SHIPPING_DATA_TO_SUGGESTED_ADDRESS = 'SET_SHIPPING_DATA_TO_SUGGESTED_ADDRESS',
  SET_ASSETS_PROVIDER = 'SET_ASSETS_PROVIDER',
  SET_VALIDATED_ADDRESS = 'SET_VALIDATED_ADDRESS',
  SET_FETCH_ERRORS = 'SET_FETCH_ERRORS',
  CLEAR_FAILED_ACTIONS = 'CLEAR_FAILED_ACTIONS',
  SET_TEXT_MESSAGE_OPT_IN = 'SET_TEXT_MESSAGE_OPT_IN',
  SET_DEFAULT_ADDRESS = 'SET_DEFAULT_ADDRESS',
  SET_ADDRESS_COUNT = 'SET_ADDRESS_COUNT',
  ASSOCIATE_ADDRESS_WITH_PRINTER = 'ASSOCIATE_ADDRESS_WITH_PRINTER',
  ASSOCIATE_ADDRESS_WITH_PRINTER_SUCCESS = 'ASSOCIATE_ADDRESS_WITH_PRINTER_SUCCESS',
  CLEAR_ERROR_FIELDS = 'CLEAR_ERROR_FIELDS',
  ENABLE_ERRORS = 'ENABLE_ERRORS',
  SET_FIELDS = 'SET_FIELDS'
}
