import React from 'react'
import { Header, Title } from './styles'

interface HpxHeaderProps {
  title: string
  showLogo?: boolean
}

export const HpxHeader = ({ title, showLogo = true }: HpxHeaderProps) => {
  return (
    <>
      <Header>
        <Title as="h2">{showLogo ? `HP All-In  ${title}` : title}</Title>
      </Header>
    </>
  )
}
