const useIIBillingFormActions = ({
  handleIIBillingModal,
  getText,
  localNotificationList,
  setLocalNotificationList,
  handleRefreshNotification,
  setCustomErr,
  forceRefresh,
  forceTaxIdRefresh,
  instantInkBillingAddressForceRefresh
}: any) => {
  const handleOnSave = (err: string) => {
    if (err === 'expired_critical_scope') {
      handleIIBillingModal()
      setCustomErr(true)
      return
    }

    forceRefresh()
    handleRefreshNotification()

    const successNotification = {
      title: getText('billingSavedSuccess.instantInkTitle', {
        defaultValue: 'Your Instant Ink payment method has been saved'
      }),
      notificationType: 'positive',
      notificationID: 'IIBillingSuccess'
    }

    const errorNotification = {
      title: getText('failedApi', {
        defaultValue: 'Something went wrong. Try again later.'
      }),
      notificationType: 'warning',
      notificationID: 'IIBillingError'
    }

    const notification = err ? errorNotification : successNotification

    setLocalNotificationList([notification])
    handleIIBillingModal()
    forceTaxIdRefresh()
    instantInkBillingAddressForceRefresh()

    if (!err) {
      sessionStorage.setItem('isIIBillingSaved', 'true')
    }
  }

  return {
    handleOnSave
  }
}

export default useIIBillingFormActions
