import {
  setAssetsProvider,
  setIsLoadingAction,
  setIsSubmittedAction,
  setBillingInfo,
  setIIBillingForm,
  setErrorAction,
  setShippingInfo,
  setAccount,
  setBillingForm,
  setDeliquencyNotification
} from './Actions'
import setSucessFetch from './setSucessFetch'
import setIsFetching from './setIsFetching'
import setErrorFetch from './setErrorFetch'

export type AppActionsType =
  | ReturnType<typeof setAssetsProvider>
  | ReturnType<typeof setIsLoadingAction>
  | ReturnType<typeof setIsSubmittedAction>
  | ReturnType<typeof setBillingInfo>
  | ReturnType<typeof setShippingInfo>
  | ReturnType<typeof setErrorAction>
  | ReturnType<typeof setAccount>
  | ReturnType<typeof setSucessFetch>
  | ReturnType<typeof setIsFetching>
  | ReturnType<typeof setErrorFetch>
  | ReturnType<typeof setBillingForm>
  | ReturnType<typeof setIIBillingForm>
  | ReturnType<typeof setDeliquencyNotification>

export default {
  setAssetsProvider,
  setIsLoadingAction,
  setIsSubmittedAction,
  setBillingInfo,
  setShippingInfo,
  setErrorAction,
  setAccount,
  setSucessFetch,
  setErrorFetch,
  setIsFetching,
  setBillingForm,
  setIIBillingForm,
  setDeliquencyNotification
}
