import styled from 'styled-components'
import tokens from '@veneer/tokens'

interface StyledTitleProps {
  isDisabled?: boolean
}

export const Container = styled.div``

export const ImageContainer = styled.div<StyledTitleProps>`
  margin-bottom: ${tokens.layout.size2};
  && > img {
    opacity: ${(props) => (props.isDisabled ? 0.5 : 1)};
  }
`

export const StyledTitle = styled.p<StyledTitleProps>`
  && {
    font-size: ${tokens.typography.size0};
    color: ${(props) =>
      props.isDisabled ? tokens.color.highContrastGray : tokens.color.gray7};
    line-height: ${tokens.typography.size2};
    margin-bottom: ${tokens.layout.size1};
  }
`

export const StyledSubscriptionContainer = styled.p<StyledTitleProps>`
  && {
    font-size: ${tokens.typography.size0};
    color: ${(props) =>
      props.isDisabled ? tokens.color.highContrastGray : tokens.color.gray12};
    line-height: ${tokens.typography.size2};
  }
`
