export enum PaymentMethodType {
  credit_card = 'credit_card',
  direct_debit = 'direct_debit',
  pay_pal = 'pay_pal',
  two_checkout = 'two_checkout'
}
export type PaymentMethodDetails = {
  payment_type: string
  status: string
  mid_restricted: boolean
  transaction_default: boolean
  subscription_default: boolean
  date_added: string
  card_type: string
  expiration_date: string
  last_four: string
  name_on_card: string
}
