const useBillingFormActions = ({
  handleBillingModal,
  getText,
  localNotificationList,
  setLocalNotificationList,
  handleRefreshNotification,
  forceRefresh
}: any) => {
  const handleOnSave = (err: string) => {
    if (!err) {
      forceRefresh()
      handleRefreshNotification()
    }

    const successNotification = {
      title: getText('billingSavedSuccess.hpAllInPlanTitle', {
        defaultValue: 'Your HP All-In Plan payment method has been saved.'
      }),
      notificationType: 'positive',
      notificationID: 'hpAipBillingSuccess'
    }

    const errorNotification = {
      title: getText('failedApi', {
        defaultValue: 'Something went wrong. Try again later.'
      }),
      notificationType: 'warning',
      notificationID: 'hpAipBillingError'
    }

    const notification = err ? errorNotification : successNotification

    setLocalNotificationList([notification])
    handleBillingModal()
  }

  return {
    handleOnSave
  }
}

export default useBillingFormActions
