import { useState } from 'react'
import notificationConfig from '../../configs/notificationConfig'
import GetAllInstantInkPaymentNotification from '../GetAllInstantInkPaymentNotification/GetAllInstantInkPaymentNotification'

import getAllNotificationsObjects from '../../helpers/getAllNotificationsObjects'
export const useIICommonNotification = (
  mfe: string,
  navigateToIIBillingHandler?: () => void
) => {
  const [configData, setConfigData] = useState(notificationConfig)
  console.log(setConfigData)

  const getConfigByKey = (key) => {
    return configData[key]
  }
  const mfeConfig = getConfigByKey(mfe)
  const allNotificationsObject = getAllNotificationsObjects()

  if (mfeConfig.instantInk) {
    const instantInkNotification = GetAllInstantInkPaymentNotification(
      navigateToIIBillingHandler,
      allNotificationsObject
    )
    if (instantInkNotification) {
      allNotificationsObject.notificationsList.push(instantInkNotification)
    }
  }
  return allNotificationsObject
}
