import { ShippingAddress } from './ShippingAddress'

export type SubscriptionEntity = {
  entityId: string
  userId: string
  shippingAddressId: string
  state: string
  entityType: string
  entityStartDate?: string
  entityEndDate?: string
  linkedEntityIds?: Array<string>
  product: EntityProduct
  source: EntitySource
  fulfillment?: Fulfillment
  entityDetails?: EntityDetails
  shippingAddress?: ShippingAddress
  cancellation?: SubscriptionCancellation
  commerce?: Commerce
  linkedEntities?: Array<LinkedEntity>
  createdAT?: string
  updatedAt?: string
}

export interface Fulfillment {
  type: string
  value?: {
    fulfillmentOrderId?: string
    obId?: string
    threePoController?: string
    fulfillmentType?: string
    orderNumber?: string
    shippedDate?: string
  }
}

export interface SubscriptionCancellation {
  enabled: boolean
  locked: boolean
  type?: string
  value?: {
    validUntil?: string
  }
}

type LinkedEntity = {
  type: string
  value: string
}
type EntityProduct = {
  type: string
  value?: Record<string, unknown>
}
type EntitySource = {
  type: string
  value?: Record<string, unknown>
}

type EntityDetails = {
  accessoryId?: string
  serialNumber?: string
  uniqueDeviceId?: string
  pendingAssociationId?: string
  pendingAssociationState?: string
}

type Commerce = {
  type?: string
  value?: CommerceValue
}

type CommerceValue = {
  subscriptionId?: string
  friendlySubscriptionId?: string
}

export enum mblEntityType {
  chromebook = 'chromebook',
  pc = 'iot-pc',
  printer = 'iot-printer',
  keyboard = 'keyboard',
  mouse = 'mouse',
  pen = 'pen',
  paper = 'paper',
  software = 'software',
  carepack = 'carepack'
}
