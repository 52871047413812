import getAuthProvider from '@/helpers/getAuthProvider'
import getLocalization from '@/helpers/getLocalization'
import useAccount from '@/hooks/useAccount'
import useBillingInfo from '@/hooks/useBillingInfo'
import useGetText from '@/hooks/useGetText'
import useShellContext from '@/hooks/useShellContext'
import useSettingsInfo from '@/hooks/useSettingsInfo/useSettingsInfo'
import {
  BillingModalWindowCancelBtnClicked,
  BillingModalWindowContinueBtnClicked,
  BillingModalWindowDisplayed,
  publishEvent
} from '@/utils/analytics'
import { BillingForm } from '@jarvis/react-billing'
import Card from '@veneer/core/dist/scripts/card'

import React, { useContext, useRef, useEffect, useState } from 'react'
import { ModalContentContainer, StyledAddButtonContainer } from './styles'
import Images from '@/assets/images'
import SubscriptionItem from '@/components/SubscriptionItem'
import { SubscriptionType } from '@/types/SubscriptionType'
import useInstantInkSubscriptionInfo from '@/hooks/useInstantInkSubscriptionInfo'
import useWarningType from '@/hooks/useWarningType'
import { IconChevronRight } from '@veneer/core/dist/scripts/icons'
import { WarningContext } from '@/context/WarningContext'
import getSubscriptionTitleTextId from '@/helpers/getSubscriptionTitleTextId'
import useCookie from '@/hooks/useCookie/useCookies'
import { CCImageContainer, CCInfoText } from '../styles'
import useCriticalScopes from '@/hooks/useCriticalScopes/useCriticalScopes'
import SecuritySessionExpiredModal from '@/components/SecuritySessionExpiredModal/SecuritySessionExpiredModal'
import { ErrorNotification } from '@/utils/ErrorNotification'
import { setInlineNotification } from '@/actions/Actions'
import { useAppContext } from '@/context/AppContext'
import { instantInkStates } from '@/utils/instantInkStates'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { StyledModal } from '../PaymentMethod/styles'

const AddPaymentMethod = () => {
  const isAgentSession = useCookie('is_agent_session')
  const billingBaseURLProvider = async () =>
    `${process.env.BILLING_BASE_URL_PROVIDER}/instantink/v1/commerce`
  const { warningType, setWarningType } = useContext(WarningContext)
  const [isOpen, setOpenModal] = useState(false)
  const [customErr, setCustomErr] = useState(false)
  const getText = useGetText('billing')
  const shell = useShellContext()
  const authProvider = getAuthProvider()
  const [currentBillingModalStep, setCurrentBillingModalStep] =
    useState<number>(1)
  const getTextSub = useGetText('subscriptions')
  const title = getTextSub(
    getSubscriptionTitleTextId(SubscriptionType.INSTANT_INK)
  )
  const { data: enablePaypalInWebview } = useSettingsInfo()
  const isNative = window?.JWeb?.isNative
  const { forceRefresh } = useBillingInfo()
  const account = useAccount().info
  const { language } = getLocalization()
  const country = account ? account.data?.regionId : process.env.DEFAULT_COUNTRY
  const local = language || process.env.DEFAULT_LANGUAGE + '_' + country
  const initialRender = useRef(true)
  const { dispatch } = useAppContext()
  const { enableDebug } = useFlags()
  useWarningType(warningType)

  const {
    error,
    isLoading,
    forceRefresh: forceCriticalScope
  } = useCriticalScopes()

  const {
    info: { data: instantInkSubscriptionInfo }
  } = useInstantInkSubscriptionInfo()
  const subscriptionIds = []
  instantInkSubscriptionInfo?.instantInkSubscriptions?.forEach((item) => {
    if (item.state !== instantInkStates.OBSOLETE) {
      subscriptionIds.push(item.accountIdentifier)
    }
  })
  const addPaymentMethodDisabled = subscriptionIds.length === 0
  const paymentMethodSubscriptionContent = instantInkSubscriptionInfo &&
    subscriptionIds && (
      <SubscriptionItem
        subscriptionType={SubscriptionType.INSTANT_INK}
        subscriptionIds={subscriptionIds}
        title={title}
      />
    )

  const addBillingClick = async () => {
    if (instantInkSubscriptionInfo?.instantInkSubscriptions?.length > 0) {
      forceCriticalScope()
    }
    setOpenModal(true)
    publishEvent({
      ...BillingModalWindowDisplayed,
      screenMode: 'InstantInk',
      screenName: `Billing1`
    })
  }
  const addPaymentMethodDetailsContent = (
    <>
      {paymentMethodSubscriptionContent}
      <div>
        <CCImageContainer>
          <img
            data-testid="emptyPaymentIcon"
            src={Images.billing}
            alt={getText('addPayment')}
            style={{
              width: '30px',
              height: '25px'
            }}
          />
        </CCImageContainer>
        <CCInfoText>{getText('noBilling')}</CCInfoText>
        <StyledAddButtonContainer isDisabled={addPaymentMethodDisabled}>
          <a
            role="button"
            data-testid="addBillingButton"
            onClick={addBillingClick}
          >
            {getText('addPayment')}
            <IconChevronRight
              size={20}
              customStyle={{
                marginLeft: '5px',
                color: '#0278ab',
                verticalAlign: 'middle'
              }}
            />
          </a>
        </StyledAddButtonContainer>
      </div>
    </>
  )

  const handleCloseModal = () => {
    setOpenModal(false)
    publishEvent({
      ...BillingModalWindowCancelBtnClicked,
      screenMode: 'InstantInk',
      screenName: `Billing${currentBillingModalStep}`
    })
    setCurrentBillingModalStep(1)
  }
  const onSave = async (err: string) => {
    if (err === 'expired_critical_scope') {
      setOpenModal(true)
      setCustomErr(true)
      return
    }
    setOpenModal(false)
    const notification = err
      ? ErrorNotification({ getText })
      : {
          display: true,
          refreshNotification: true,
          title: getText('billingSavedSuccess.instantInkTitle', {
            defaultValue: 'Your Instant Ink payment method has been saved.'
          }),
          status: 'positive' as const
        }

    if (!err) {
      sessionStorage.setItem('isIIBillingSaved', 'true')
    }

    dispatch(setInlineNotification(notification))
    publishEvent({
      ...BillingModalWindowContinueBtnClicked,
      screenMode: 'InstantInk',
      screenName: `Billing2`
    })
    forceRefresh()
    if (warningType === 'billingWarning') {
      setWarningType('noWarning')
    } else if (warningType === 'shippingAndBillingWarning') {
      setWarningType('shippingWarning')
    }
  }
  const handleUpdate = () => {
    publishEvent({
      ...BillingModalWindowContinueBtnClicked,
      screenMode: 'InstantInk',
      screenName: `Billing1`
    })
    publishEvent({
      ...BillingModalWindowDisplayed,
      screenMode: 'InstantInk',
      screenName: `Billing2`
    })
    setCurrentBillingModalStep(2)
  }

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false
      return
    }

    if (error && !isLoading) {
      setOpenModal(true)
    }
  }, [error, isLoading])

  const addPaymentMethodTileContent = (
    <>
      {enableDebug && (
        <span>
          <>IsJweb Native: {String(isNative)}</>
          <br></br>
          <>enablePaypalInWebview : {String(enablePaypalInWebview)}</>
          <br></br>
        </span>
      )}
      {addPaymentMethodDetailsContent}
      {(customErr || (error && !isLoading)) &&
      instantInkSubscriptionInfo?.instantInkSubscriptions?.length > 0 ? (
        <SecuritySessionExpiredModal show={isOpen} onClose={handleCloseModal} />
      ) : (
        <StyledModal
          data-testid="modal"
          closeButton
          show={isOpen}
          onClose={handleCloseModal}
          closeOnBlur={false}
        >
          {isOpen && (
            <ModalContentContainer>
              <BillingForm
                accountIdentifier={'testingId'}
                country={country}
                language={local}
                baseURLProvider={billingBaseURLProvider}
                authProvider={authProvider}
                mockStratus={false}
                onSave={onSave}
                onCancel={handleCloseModal}
                onUpdate={handleUpdate}
                stack={shell?.stack}
                virtualKeyboard={isAgentSession}
                nativeApp={isNative}
                enablePaypalInNativeApp={enablePaypalInWebview}
              />
            </ModalContentContainer>
          )}
        </StyledModal>
      )}
    </>
  )
  return <Card content={addPaymentMethodTileContent} className="card-box" />
}

export default AddPaymentMethod
