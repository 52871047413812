import React from 'react'
import { ManagePaymentText } from './styles'

const ManagePaymentButton = ({ onClick, isDisabled, getText }: any) => {
  return (
    <ManagePaymentText onClick={onClick} isDisabled={isDisabled}>
      {getText('editPayment', {
        defaultValue: 'Manage your payment method'
      })}
    </ManagePaymentText>
  )
}

export default ManagePaymentButton
