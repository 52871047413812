import { useCallback, useEffect, useState } from 'react'
import getAuthProvider from '../../helpers/getAuthProvider'
import InstantInkShippingAddress from '../../types/InstantInkShippingAddress'
import InstantInkShippingAddressClient from '../../services/IInkBillingAddressClient'

const useInstantInkBillingAddress = () => {
  const [data, setData] = useState<InstantInkShippingAddress | null>(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<Error | null>(null)

  const authProvider = getAuthProvider()

  const fetchBillingAddress = useCallback(async () => {
    const client = new InstantInkShippingAddressClient(authProvider)
    setLoading(true)
    try {
      const response = await client.getInstantInkShippingAddresssData()
      setData(response)
    } catch (err) {
      console.error('Error fetching billing address:', err)
      setError(err instanceof Error ? err : new Error('Unknown error'))
    } finally {
      setLoading(false)
    }
  }, [authProvider])
  useEffect(() => {
    fetchBillingAddress()
  }, [fetchBillingAddress])

  return { data, loading, error, forceRefresh: fetchBillingAddress }
}

export default useInstantInkBillingAddress
