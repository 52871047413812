import React from 'react'
import HPAIPShipping from './HPAIPShipping'
import InstantInkShipping from './InstantInkShipping'
import {
  ShippingAddressContainer,
  ShippingSectionContainer,
  StyledTextContainer,
  StyledTitle
} from './styles'
import useGetText from '../../hooks/useGetText'

interface ShippingingProps {
  commonProps: any
}
const Shipping = (props: ShippingingProps) => {
  const { commonProps } = props
  const getText = useGetText('shipping')
  const hasInstantInkSubscription =
    commonProps?.iInk?.iInkSubscriptionInfo?.instantInkSubscriptions?.length > 0
  const AddressHeader = () => (
    <>
      <StyledTitle role="heading" aria-level="2">
        {getText('yourAddress')}
      </StyledTitle>
      <StyledTextContainer>
        {getText('withShippingInfoMsg')}
      </StyledTextContainer>
    </>
  )

  return (
    <ShippingSectionContainer>
      {(commonProps?.isHPOneSubscriptionAvailable ||
        hasInstantInkSubscription) && <AddressHeader />}
      <ShippingAddressContainer>
        {commonProps?.isHPOneSubscriptionAvailable && (
          <HPAIPShipping commonProps={commonProps} />
        )}
        {hasInstantInkSubscription && (
          <InstantInkShipping commonProps={commonProps} />
        )}
      </ShippingAddressContainer>
    </ShippingSectionContainer>
  )
}

export default Shipping
