export default function getLocation(): { country: string; language: string } {
  const { locale = 'us/en' } =
    /\/(?<locale>[a-z]{2}\/[a-z]{2})/.exec(window.location.pathname)?.groups ||
    {}

  const [country, language] = locale.split('/')

  return {
    country,
    language
  }
}
