import AddressInfo from '@/components/Shipping/AddressInfo'
import { useAppContext } from '@/context/AppContext'
import getSubscriptionShippingAddressInfo from '@/helpers/getSubscriptionShippingAddressInfo'
import useGetText from '@/hooks/useGetText'
import useShippingInfo from '@/hooks/useShippingInfo'
import { HpOneSubsEntityState } from '@/types/HpOneSubsEntityState'
import { mblEntityType, SubscriptionEntity } from '@/types/SubscriptionEntity'
import { SubscriptionType } from '@/types/SubscriptionType'
import Card from '@veneer/core/dist/scripts/card'
import React, { useContext, useEffect } from 'react'
import Images from '../../assets/images'
import Skeleton from '../Skeleton'
import { useFlags } from 'launchdarkly-react-client-sdk'
import {
  ShippingAddressContainer,
  ShippingAddressItemContainer,
  ShippingSectionContainer,
  StyledIconContainer,
  StyledTextContainer,
  StyledTitle
} from './styles'
import ErrorMessage from '../ErrorMessage'
import { WarningContext } from '@/context/WarningContext'
import { SubscriptionInfo } from '@/types/SubscriptionInfo'
import { getMainEntity } from '@/helpers/getMainEntity'
import { instantInkStates } from '@/utils/instantInkStates'
import useInstantInkShippingAddress from '@/hooks/useInstantInkShippingAddress/useInstantInkShippingAddress'

const Shipping = () => {
  const { warningType, setWarningType } = useContext(WarningContext)
  const [hasShippingInfo, setHasShippingInfo] = React.useState(false)
  const [hasInstantInkInfo, setHasInstantInkInfo] = React.useState(false)
  const [isPaperEnrolled, setIsPaperEnrolled] = React.useState<boolean>(false)
  const [paperEligibility, setPaperEligibility] = React.useState<boolean>(false)
  const getText = useGetText('shipping')
  const { enableManagePaperPostEnrollment } = useFlags()
  const { instantInkSubscriptionInfo, subscriptionInfo } =
    useAppContext()?.state

  const {
    info: {
      data: ShippingInfo,
      isFetching: hpOneIsFetching,
      error: hpOneError
    },
    forceRefresh
  } = useShippingInfo()
  //Get HP_One subscriptions (MBLC)
  const mappedSubscriptionInfos =
    subscriptionInfo?.data &&
    getSubscriptionShippingAddressInfo(ShippingInfo, subscriptionInfo.data)

  //Get shippingaddresses of IntantInk subscriptions
  const hasInstantInkSubscription =
    instantInkSubscriptionInfo?.data?.instantInkSubscriptions?.length > 0

  const {
    data: instantInkShippingAddress,
    isfetching: instantInkShippingAddressIsFetching,
    forceRefresh: instantInkShippingAddressInfoRefresh
  } = useInstantInkShippingAddress(hasInstantInkSubscription)
  useEffect(() => {
    setHasShippingInfo(
      !!ShippingInfo &&
        ShippingInfo.length > 0 &&
        !!mappedSubscriptionInfos &&
        mappedSubscriptionInfos?.length > 0
    )
  }, [ShippingInfo, mappedSubscriptionInfos])

  useEffect(() => {
    const instantPaper = mappedSubscriptionInfos?.some((subitem) =>
      subitem?.entities?.some(
        (item) =>
          item.entityType === 'instant-paper' &&
          (item.state === HpOneSubsEntityState.ACTIVE ||
            item.state === HpOneSubsEntityState.PENDING ||
            item.state === HpOneSubsEntityState.DEACTIVATING)
      )
    )
    setIsPaperEnrolled(instantPaper)
  }, [mappedSubscriptionInfos])

  useEffect(() => {
    setPaperEligibility(enableManagePaperPostEnrollment && isPaperEnrolled)
  }, [isPaperEnrolled, enableManagePaperPostEnrollment])

  useEffect(() => {
    setHasInstantInkInfo(
      instantInkSubscriptionInfo?.data?.instantInkSubscriptions?.length > 0
    )
  }, [instantInkSubscriptionInfo])
  const instantInkSubData =
    instantInkSubscriptionInfo?.data?.instantInkSubscriptions?.filter(
      (subitem) => subitem.state !== instantInkStates.OBSOLETE
    )
  const subscriptionIdList = instantInkSubData
    ?.map((subitem) => subitem.accountIdentifier)
    ?.join(', ')
  const instantInkAddressDisbled = instantInkSubData?.length === 0
  const onAddressSaved = async () => {
    forceRefresh()
    instantInkShippingAddressInfoRefresh()

    if (warningType === 'shippingWarning') {
      setWarningType('noWarning')
    } else if (warningType === 'shippingAndBillingWarning') {
      setWarningType('billingWarning')
    }
  }

  const cancellingStates = [
    HpOneSubsEntityState.DEACTIVATING,
    HpOneSubsEntityState.INACTIVE
  ]

  const isSubscribed = (entry: SubscriptionInfo): boolean => {
    const entity = [getMainEntity(entry.entities)]
    return !entity.find(
      (item) =>
        isHpOneEntity(item) && item.state !== HpOneSubsEntityState.ACTIVE
    )
  }

  const isCancelling = (entry: SubscriptionInfo): boolean => {
    const entity = [getMainEntity(entry.entities)]
    return !entity.find(
      (item) => isHpOneEntity(item) && !cancellingStates.includes(item.state)
    )
  }

  const isHpOneEntity = (entry: SubscriptionEntity): boolean => {
    return (
      entry.entityType === mblEntityType.pc ||
      entry.entityType === mblEntityType.printer ||
      entry.entityType === mblEntityType.chromebook
    )
  }

  const withAddressHeader = (
    <>
      <StyledTitle role="heading" aria-level="2">
        {getText('yourAddress')}
      </StyledTitle>
      <StyledTextContainer>
        {getText('withShippingInfoMsg')}
      </StyledTextContainer>
    </>
  )

  const hpOneContent =
    hasShippingInfo &&
    mappedSubscriptionInfos?.map((subitem, index) => {
      return (
        <ShippingAddressItemContainer key={index}>
          <AddressInfo
            shippingAddress={subitem?.subscriptionEntity?.shippingAddress}
            friendlysubscriptionId={
              subscriptionInfo?.data[0]?.friendlySubscriptionId
            }
            subscriptionId={subitem?.subscriptionId}
            entityType={SubscriptionType.HP_ONE}
            enableEdit={isSubscribed(subitem)}
            onAddressSaved={onAddressSaved}
            isDisabled={isCancelling(subitem)}
            paperEligibility={paperEligibility}
          />
        </ShippingAddressItemContainer>
      )
    })

  const hpOneErrorContent = hpOneError &&
    mappedSubscriptionInfos?.length > 0 && (
      <ShippingAddressItemContainer>
        <ErrorMessage
          image={Images.hp_all_in_plan}
          text={`tryAgainLater`}
          data-testid="error-message"
        />
      </ShippingAddressItemContainer>
    )
  const instantInkContent = hasInstantInkInfo &&
    !instantInkShippingAddressIsFetching &&
    instantInkShippingAddress &&
    Array.isArray(instantInkSubData) && (
      <ShippingAddressItemContainer>
        <AddressInfo
          shippingAddress={instantInkShippingAddress}
          friendlysubscriptionId={subscriptionIdList}
          subscriptionId={instantInkSubData[0]?.accountIdentifier}
          entityType={SubscriptionType.INSTANT_INK}
          onAddressSaved={onAddressSaved}
          cloudId={instantInkSubData[0]?.printerCloudIdentifier}
          addressDisabled={instantInkAddressDisbled}
        />
      </ShippingAddressItemContainer>
    )

  const instantInkErrorContent = hasInstantInkSubscription &&
    instantInkSubscriptionInfo?.error && (
      <ShippingAddressItemContainer>
        <ErrorMessage image={Images.instant_ink} text={`tryAgainLater`} />
      </ShippingAddressItemContainer>
    )

  const skeletonLoading =
    hpOneIsFetching ||
    instantInkSubscriptionInfo?.isFetching ||
    (instantInkShippingAddressIsFetching && (
      <ShippingAddressItemContainer>
        <Skeleton width="60vw" height="5vh" />
      </ShippingAddressItemContainer>
    ))

  const addressContent = (hasShippingInfo ||
    hpOneError ||
    hasInstantInkInfo ||
    !instantInkErrorContent) &&
    !instantInkShippingAddressIsFetching && (
      <>
        <StyledTextContainer>{withAddressHeader}</StyledTextContainer>
        <ShippingAddressContainer>
          {hpOneContent}
          {hpOneErrorContent}
          {instantInkContent}
          {instantInkErrorContent}
        </ShippingAddressContainer>
      </>
    )

  const cardContent = !hasShippingInfo &&
    !hpOneError &&
    !hasInstantInkInfo &&
    !instantInkErrorContent && (
      <>
        <StyledIconContainer>
          <img src={Images.shipping} alt="title icon" />
        </StyledIconContainer>
        <StyledTitle>{getText('header')}</StyledTitle>
        <StyledTextContainer>
          {getText('noShippingInfoMsg')}
        </StyledTextContainer>
      </>
    )

  return (
    <ShippingSectionContainer>
      {skeletonLoading}
      {addressContent}
      {cardContent && <Card content={cardContent} className="card-box" />}
    </ShippingSectionContainer>
  )
}

export default Shipping
